import * as React from 'react'
import { Avatar, Box, CardActionArea, Divider, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  card: {
    borderRadius: 19,
    border: '0.25px solid #FFFFFF',
    boxShadow: 'none'
  },
  divider: {
    // Theme Color, or use css color in quote
    background: '#a6ce34'
  }
})

export default function SystemInfo(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Box className={classes.card}>
      <CardActionArea></CardActionArea>
      <Box mx="auto" mt="5%" height={200}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Avatar src={props.profile.icon} alt={props.profile.name}>
            {props.profile.name}
          </Avatar>
          <Box ml={2}>
            <Typography color="secondary" variant="h6">
              {props.profile.name}
            </Typography>
          </Box>
        </Box>
        <Box mt={3}>
          <Typography color="secondary">{t('vc.contact_person')}</Typography>
        </Box>
        <Box width={150}>
          <Divider classes={{ root: classes.divider }} />
        </Box>
        <Box mt={1}>
          <Typography color="secondary">{props.profile.contact_person}</Typography>
          <Typography color="secondary">{props.profile.address}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
