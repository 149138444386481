import * as React from 'react'
import { Box, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

export default function TotEnergy(props) {
  const dtime = new Date(props.dt).getTime()
  const { t } = useTranslation()
  const oldSystem = !!props.baseLineEtot
  const ecoByEpoch = oldSystem ? 0 : Number(props.rawEtot / (dtime - props.bypassTime))
  const enoEco = oldSystem ? Number(((props.rawEtot - props.baseLineEtot) / dtime) * props.bypassTime) : 0
  const thPercent = oldSystem
    ? Number((props.rawEecoTot - props.baselineSavedE + enoEco) / (props.rawEtot - props.baseLineEtot))
    : 0
  return (
    <Box my={7} mr={props.mobile && !props.admin ? '10%' : '0%'}>
      <Grid
        container
        direction="row"
        justifyContent={props.mobile ? 'flex-start' : 'center'}
        alignItems="center"
      >
        <Grid item xs={6}>
          <Typography color="primary" variant={'h6'} align="center">
            {props.etot} kWh
          </Typography>
          <Typography color="secondary" variant="body2" align="center">
            {t('vc.etot')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography color="primary" variant="h6" align="center">
            {props.eEco} kWh
          </Typography>
          <Typography color="secondary" variant="body2" align="center">
            {t('vc.eecotot')}
          </Typography>
        </Grid>
      </Grid>
      {props.admin && (
        <Box mt={2} mx={'5%'} mb={props.mobile ? -5 : -8}>
          <Typography color="secondary" variant="body2" align="left">
            Potentiel d'économies non réalisé :{' '}
            {oldSystem
              ? enoEco
                  .toFixed(1)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, "'")
              : Number(ecoByEpoch * props.bypassTime)
                  .toFixed(1)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, "'")}{' '}
            kWh
          </Typography>
          <Typography color="secondary" variant="body2" align="left">
            Pourcentage théorique selon mesures :{' '}
            {oldSystem
              ? Number(props.rawEecoTot - props.baselineSavedE) > 5
                ? Number(thPercent * 100).toFixed(3)
                : 'nc'
              : Number(((props.rawEecoTot + ecoByEpoch * props.bypassTime) / props.rawEtot) * 100).toFixed(
                  3
                )}{' '}
            %
          </Typography>
        </Box>
      )}
    </Box>
  )
}
