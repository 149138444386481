import * as React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useEffect } from 'react'
import api, { getProfile } from '../api/api'
import Graph from '../graphView/Graph'
import {
  Backdrop,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles
} from '@material-ui/core'
import TopBar from '../appBar/AppBar'
import { useHistory, useLocation } from 'react-router-dom'
import CustomChip from '../graphView/CustomChip'
import { useTranslation } from 'react-i18next'
import { makeIdArray, setAvg } from '../utilities/Utils'
import moment from 'moment'
import { Stack } from '@mui/material'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ReactGA from 'react-ga4'
import loadash from 'lodash'

const useStyles = makeStyles({
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  blurredCard: {
    boxShadow: 'none',
    filter: 'blur(3px)'
  },
  invisibleCard: {
    boxShadow: 'none'
  },
  divider: {
    background: '#a6ce34'
  }
})

export default function IotTDash() {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [isAdmin] = React.useState(false)
  const [measures, setMeasures] = React.useState([])
  const [mesChoices, setMesChoices] = React.useState([])
  const [graphMes, setGraphMes] = React.useState([])
  const [dmin, setdMin] = React.useState()
  const [dmax, setdMax] = React.useState()
  const [min, setMin] = React.useState(0)
  const [max, setMax] = React.useState(0)
  const [loading, setLoading] = React.useState(true)
  const [refreshing, setRefreshing] = React.useState(false)
  const [unit, setUnit] = React.useState('A')
  const [iot, setIot] = React.useState(location.state.iot ? location.state.iot : 0)
  const [label, setlabel] = React.useState('Tous les capteurs')
  const [pts, setPts] = React.useState(100)
  const [latestIoT, setLatestIot] = React.useState([])
  const [profile] = React.useState(location.state.user ? location.state.user : 123)
  const [dateinterval, setDateInterval] = React.useState({
    start: new Date(new Date().setDate(new Date().getDate() - 7)),
    end: new Date()
  })

  const setPointNumber = npts => {
    if (npts !== pts) {
      setPts(npts)
      setRefreshing(true)
    }
    ReactGA.event({
      category: 'selection',
      action: `changed_iot_points_${npts}`,
      label: profile.name,
      nonInteraction: true
    })
  }

  const setDates = (start, end) => {
    if (!(end > Date.now())) {
      setDateInterval({
        start: start,
        end: end
      })
      setRefreshing(true)
    }
    ReactGA.event({
      category: 'selection',
      action: `changed_iot_dates_${start}_${end}`,
      label: profile.name,
      nonInteraction: true
    })
  }

  const setId = sensor => {
    setlabel(sensor.label)
    const subval = sensor.value === '' ? makeIdArray(location.state.iot) : [sensor.value]
    if (subval.toString() !== iot.toString()) {
      setRefreshing(true)
    }
    ReactGA.event({
      category: 'selection',
      action: `changed_iot_id_${sensor.label}`,
      label: profile.name,
      nonInteraction: true
    })
  }

  const dataToCsv = dataset => {
    const firstLine = ['date']
    const data = []
    dataset.forEach(dataset => {
      firstLine.push(dataset.id)
    })
    data.push(firstLine)
    dataset[0].data.forEach((mes, index) => {
      const line = [moment(mes.x * 1000).format('DD/MM/YY HH:mm:ss')]
      dataset.forEach(set => {
        line.push(set.data[index].y)
      })
      data.push(line)
    })
    let csvContent = 'data:text/csv;charset=utf-8,' + data.map(e => e.join(',')).join('\n')
    return encodeURI(csvContent)
  }

  const handleClick = dataset => {
    const content = dataToCsv(dataset)
    var link = document.createElement('a')
    link.setAttribute('href', content)
    link.setAttribute(
      'download',
      `${moment(dateinterval.start).format('YYmmDD')}_${moment(dateinterval.end).format(
        'YYmmDD'
      )}_${label}.csv`
    )
    document.body.appendChild(link) // Required for FF
    link.click()
    ReactGA.event({
      category: 'selection',
      action: `exported_to_csv`,
      label: profile.name,
      nonInteraction: true
    })
  }

  const parseDataReq = sensors => {
    const req = []
    const createReq = s => {
      req.push({
        id: s.value,
        start: dateinterval.start,
        end: dateinterval.end,
        pts: pts
      })
    }

    if (sensors.length > 1) {
      iot.forEach(sensor => {
        createReq(sensor)
      })
    } else {
      createReq(sensors[0])
    }
    return req
  }

  const handleGraphChoice = () => {
    const choices = []
    const aggregateChoice = []
    iot.forEach((s, index) => {
      choices.push({ id: s.label, label: s.label, ...s, ...measures[index] })
      if (!aggregateChoice.find(c => c.label === s.type)) {
        aggregateChoice.push({ label: s.type })
      }
    })
    setMesChoices([...aggregateChoice, ...choices])
  }

  function IoTCard({ sensor, latest }) {
    return (
      <Card className={classes.card}>
        <Box mx={'5%'}>
          <Box ml={4} mt={4} mb={4}>
            <Typography variant={'h5'} color={'primary'} style={{ fontWeight: '350' }}>
              {sensor.label}
            </Typography>
          </Box>
          <Box height={300} mt={6}>
            <Graph
              measures={[{ id: sensor.id, data: sensor.data }]}
              format="%Hh"
              min={sensor.min}
              max={sensor.max}
              legendOffset={90}
              colors={['#238b57', '#d0f0c0', '#a6ce34', '#9a9a9a']}
              unit={sensor.unit}
            />
          </Box>
          <Box height={100} mx={2} mt={-2}>
            <Stack direction={'row'} spacing={10} alignItems={'center'} justifyContent="center">
              <Box width={150}>
                <Typography color="secondary" variant="h6" align="center" style={{ fontWeight: '350' }}>
                  {latest[0].mes} {sensor.unit}
                </Typography>
                <Box mb={0.5}>
                  <Divider className={classes.divider} />
                </Box>
                <Typography color="secondary" variant="body2" align="center">
                  Dernière mesure
                </Typography>
              </Box>
              <Box width={150}>
                <Typography color="secondary" variant="h6" align="center" style={{ fontWeight: '350' }}>
                  {sensor.avg} {sensor.unit}
                </Typography>
                <Box mb={0.5}>
                  <Divider className={classes.divider} />
                </Box>
                <Typography color="secondary" variant="body2" align="center">
                  Moyenne
                </Typography>
              </Box>
              <Box width={150}>
                <Typography color="secondary" variant="h6" align="center" style={{ fontWeight: '350' }}>
                  {sensor.min} {sensor.unit}
                </Typography>
                <Box mb={0.5}>
                  <Divider className={classes.divider} />
                </Box>
                <Typography color="secondary" variant="body2" align="center">
                  min
                </Typography>
              </Box>
              <Box width={150}>
                <Typography color="secondary" variant="h6" align="center" style={{ fontWeight: '350' }}>
                  {sensor.max} {sensor.unit}
                </Typography>
                <Box mb={0.5}>
                  <Divider className={classes.divider} />
                </Box>
                <Typography color="secondary" variant="body2" align="center">
                  max
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Card>
    )
  }

  function BlurredIoTCard({ sensor, latest }) {
    return (
      <Card className={classes.card}>
        <Box mx={'5%'}>
          <Box ml={4} mt={4} mb={4}>
            <Stack>
              <Typography variant={'h5'} color={'primary'} style={{ fontWeight: '350' }}>
                {sensor.label}
              </Typography>
              <Typography variant={'body2'} color={'secondary'}>
                Aucune donnée pour la période sélectionnée. Dernière mesure:{' '}
                {moment(latest[0].timestamp).format('DD/MM/YY HH:mm')}
              </Typography>
            </Stack>
          </Box>
          <Card className={classes.blurredCard}>
            <Box height={300} mt={6}>
              <Graph
                measures={[{ id: sensor.id, data: sensor.data }]}
                format="%Hh"
                min={sensor.min}
                max={sensor.max}
                legendOffset={90}
                colors={['#238b57', '#d0f0c0', '#a6ce34', '#9a9a9a']}
                unit={sensor.unit}
              />
            </Box>
            <Box height={100} mx={2} mt={-2}>
              <Stack direction={'row'} spacing={10} alignItems={'center'} justifyContent="center">
                <Box width={150}>
                  <Typography color="secondary" variant="h6" align="center" style={{ fontWeight: '350' }}>
                    {sensor.latest} {sensor.unit}
                  </Typography>
                  <Box mb={0.5}>
                    <Divider className={classes.divider} />
                  </Box>
                  <Typography color="secondary" variant="body2" align="center">
                    Dernière mesure
                  </Typography>
                </Box>
                <Box width={150}>
                  <Typography color="secondary" variant="h6" align="center" style={{ fontWeight: '350' }}>
                    {sensor.avg} {sensor.unit}
                  </Typography>
                  <Box mb={0.5}>
                    <Divider className={classes.divider} />
                  </Box>
                  <Typography color="secondary" variant="body2" align="center">
                    Moyenne
                  </Typography>
                </Box>
                <Box width={150}>
                  <Typography color="secondary" variant="h6" align="center" style={{ fontWeight: '350' }}>
                    {sensor.min} {sensor.unit}
                  </Typography>
                  <Box mb={0.5}>
                    <Divider className={classes.divider} />
                  </Box>
                  <Typography color="secondary" variant="body2" align="center">
                    min
                  </Typography>
                </Box>
                <Box width={150}>
                  <Typography color="secondary" variant="h6" align="center" style={{ fontWeight: '350' }}>
                    {sensor.max} {sensor.unit}
                  </Typography>
                  <Box mb={0.5}>
                    <Divider className={classes.divider} />
                  </Box>
                  <Typography color="secondary" variant="body2" align="center">
                    max
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Card>
        </Box>
      </Card>
    )
  }

  useEffect(
    () => {
      const refresh = () => {
        const allmes = []
        const mesData = []
        const min = []
        const max = []
        getProfile(profile.client_id).then(r => {
          setIot(r.data.iot)
          api.getLatestIoT(parseDataReq(r.data.iot)).then(latest => {
            setLatestIot(latest)
            api.getIot(parseDataReq(r.data.iot)).then(res => {
              setdMin(res[0].dmin)
              setdMax(res[0].dmax)
              r.data.iot.forEach((s, index) => {
                if (label === 'Tous les capteurs' || s.label === label) {
                  min.push(parseInt(res[index].min))
                  max.push(parseInt(res[index].max))
                  mesData.push({ id: s.label, latest: latest[index].value, ...s, ...res[index] })
                } else if (s.type === label) {
                  min.push(parseInt(res[index].min))
                  max.push(parseInt(res[index].max))
                  mesData.push({ id: s.label, ...s, ...res[index] })
                }
                allmes.push({
                  id: s.label,
                  ...s,
                  latest: latest[index].value,
                  avg: setAvg(res[index].data),
                  ...res[index]
                })
              })
              setUnit(mesData[0].unit)
              setMin(Math.min(...min))
              setMax(Math.max(...max))
              handleGraphChoice()
              setGraphMes(mesData)
              setMeasures(allmes)
              setLoading(false)
              setRefreshing(false)
            })
          })
        })
      }
      refresh()
      const interval = setInterval(() => {
        refresh()
      }, 60000)
      return () => clearInterval(interval)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pts, dateinterval, label]
  )

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
      <>
        <TopBar
          lang={location.state.lang}
          user={{ name: location.state.user.name, icon: location.state.user.icon }}
        />
        <Box mx={'10%'} mt={22}>
          <Stack direction="row" alignItems={'center'}>
            <IconButton onClick={() => history.push('/dash')}>
              <ArrowBackIosNewRoundedIcon style={{ color: '#9a9a9a' }} />
            </IconButton>
            <Typography color="primary" variant="h5">
              Valeurs Instantanées | Capteurs IoT
            </Typography>
          </Stack>
          <Box mt={2}>
            <Divider />
          </Box>
          <Box mt={6} mb={4}>
            <Typography variant="h5" color="primary">
              Aperçu des mesures
            </Typography>
          </Box>
          <Card className={classes.card}>
            <Box mx={'2%'} mt={3} mb={6}>
              {!refreshing && (
                <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                  <Grid item>
                    <CustomChip
                      admin={isAdmin}
                      choices={mesChoices}
                      setValue={setId}
                      label={label}
                      value={''}
                      mode={'iot'}
                    />
                  </Grid>
                  <Grid item>
                    <CustomChip
                      admin={isAdmin}
                      label={t('vc.days')}
                      choices={[1, 7, 15, 30]}
                      setValue={setDates}
                      value={dateinterval}
                      mode="date"
                    />
                  </Grid>
                  <Grid item>
                    <CustomChip
                      admin={isAdmin}
                      label={t('vc.points')}
                      choices={[100, 500, 1000, 3000, 10000]}
                      setValue={setPointNumber}
                      value={pts}
                      mode="default"
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      variant="outlined"
                      color="secondary"
                      label={t('vc.export')}
                      onClick={() => handleClick(graphMes)}
                    />
                  </Grid>
                </Grid>
              )}
              {refreshing && (
                <Box mt={1}>
                  <LinearProgress />
                </Box>
              )}
            </Box>
            {loadash.sum(graphMes.map(e => e.data.length)) === 0 ? (
              <Box mx={'3%'} height={600}>
                <Box mt={2}>
                  <Typography color={'secondary'}>Aucune donnée disponible pour la actuelle</Typography>
                </Box>
              </Box>
            ) : (
              <>
                <Box mx={'5%'} height={600}>
                  <Graph
                    measures={graphMes}
                    format="%Hh"
                    min={min}
                    //max={max}
                    legendOffset={90}
                    legendSpace={80}
                    colors={['#238b57', '#d0f0c0', '#a6ce34', '#9a9a9a']}
                    unit={unit}
                  />
                </Box>
                <Box mt={-3} mb={2}>
                  <Typography color="secondary" variant={'body2'} align={'center'}>
                    {t('vc.mes')} {t('vc.from')} {dmin} {t('vc.to')} {dmax} | Min {min} {unit} | Max {max}{' '}
                    {unit}
                  </Typography>
                </Box>
              </>
            )}
          </Card>
          <Box mt={8} mb={10}>
            <Stack spacing={5}>
              {measures.map(sensor => (
                <Box key={`iotsensor_${sensor.label}`}>
                  {sensor.data.length ? (
                    <IoTCard sensor={sensor} latest={latestIoT.filter(e => e.uid === sensor.value)}></IoTCard>
                  ) : (
                    <BlurredIoTCard
                      sensor={sensor}
                      latest={latestIoT.filter(e => e.uid === sensor.value)}
                    ></BlurredIoTCard>
                  )}
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
      </>
    )
  }
}
