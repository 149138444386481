import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import {
  Backdrop,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Divider,
  Grid,
  TextField
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import apis from '../../api/api'
import { CustomerModal } from './CustomerModal'
import { DevicesModal } from './DevicesModal'
import { UsersModal } from './UsersModal'
import Avatar from '@material-ui/core/Avatar'
import { IoTModal } from './IoTModal'
import { Autocomplete, createFilterOptions, Stack } from '@mui/material'
import { useStyles } from '../../graphic/MUIStylePresets'
import MenuItem from '@material-ui/core/MenuItem'
import { debounce } from 'lodash'

function AllClients() {
  const [loading, setLoading] = React.useState(true)
  const [clients, setClients] = React.useState([])
  const [openClientModal, setClientModal] = React.useState(false)
  const [openIoTModal, setIoTModal] = React.useState(false)
  const [openEditModal, setEditModal] = React.useState(false)
  const [openDeviceModal, setDeviceModal] = React.useState(false)
  const [openUserModal, setUserModal] = React.useState(false)
  const [openSuggest, setSuggest] = React.useState(false)
  const [client, setClient] = React.useState(false)
  const [displayAllClients, setAllClientsDisplay] = React.useState(false)

  const defaultFilterOptions = createFilterOptions()
  const history = useHistory()

  const classes = useStyles()

  const handleEditModal = client => {
    setClient(client)
    setEditModal(true)
  }

  const handleDevicesModal = client => {
    setClient(client)
    setDeviceModal(true)
  }

  const handleUsersModal = client => {
    setClient(client)
    setUserModal(true)
  }

  const handleIoTModal = client => {
    setClient(client)
    setIoTModal(true)
  }

  const handleClick = choice => {
    setClient(choice)
    setSuggest(false)
  }

  const authCheck = () => {
    const token = localStorage.getItem('token')
    if (token) {
      apis.tokenCheck().then(r => {
        if (!(r.data.isLoggedIn && r.data.user.isAdmin)) {
          history.push('/')
        }
      })
    } else {
      history.push('/')
    }
  }

  const ClientTile = clients => {
    const client = clients.client
    return (
      <Box mt={2} key={`client_${client.client_id}`}>
        <Card className={classes.card}>
          <Box mx={3} my={3}>
            <Grid container alignItems="center">
              <Grid item>
                <Box mr={2}>
                  <Avatar src={client.icon} alt={client.name} sx={{ width: 50, height: 50 }}>
                    {client.name}
                  </Avatar>
                </Box>
              </Grid>
              <Grid item xs={11}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography color="primary" variant="h6">
                    {client.name}
                  </Typography>
                  <Button color="secondary" onClick={() => handleEditModal(client)}>
                    <Box mx={2}>
                      <Typography color="secondary" variant="body2">
                        modifier
                      </Typography>
                    </Box>
                  </Button>
                </Stack>
                <Divider />
                <Typography color="secondary" variant={'body2'}>
                  {client.contact_person} | ID client: {client.client_id}
                </Typography>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Stack direction="row">
                <Box>
                  <Card className={classes.card}>
                    <CardActionArea onClick={() => handleDevicesModal(client)}>
                      <Box mx={2.5} mt={2} mb={1.5} minHeight={50}>
                        <Box mt={-1} mb={1}>
                          <Typography color="primary" variant={'body2'}>
                            Appareils:
                          </Typography>
                        </Box>
                        <Typography color="secondary" variant={'h5'}>
                          {client.vcs.length}
                        </Typography>
                      </Box>
                    </CardActionArea>
                  </Card>
                </Box>
                <Box ml={2}>
                  <Card className={classes.card} onClick={() => handleUsersModal(client)}>
                    <CardActionArea>
                      <Box mx={2.5} mt={2} mb={1.5} minHeight={50}>
                        <Box mt={-1} mb={1}>
                          <Typography color="primary" variant={'body2'}>
                            Utilisateurs:
                          </Typography>
                        </Box>
                        <Typography color="secondary" variant={'h5'}>
                          {client.users.length}
                        </Typography>
                      </Box>
                    </CardActionArea>
                  </Card>
                </Box>
                <Box ml={2}>
                  <Card className={classes.card}>
                    <CardActionArea onClick={() => handleIoTModal(client)}>
                      <Box mx={2.5} mt={2} mb={1.5} minHeight={55}>
                        <Box mt={-1} mb={1}>
                          <Typography color="primary" variant={'body2'}>
                            IoT:
                          </Typography>
                        </Box>
                        <Typography color="secondary" variant={'h5'}>
                          {client.iot.length}
                        </Typography>
                      </Box>
                    </CardActionArea>
                  </Card>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Card>
      </Box>
    )
  }

  useEffect(() => {
    authCheck()
    apis.getClients().then(r => {
      setClients(r.data)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearch = debounce(searchText => {
    return searchText
  }, 300) // 300 milliseconds debounce delay

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
      <Box mx="7%" minWidth={800}>
        <Box mt={15}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography color="secondary">Liste des Clients</Typography>
            <Stack direction={'row'} spacing={2}>
              <Button variant="outlined" color="secondary" onClick={() => setClientModal(true)}>
                <Typography color="secondary">Nouveau client</Typography>
              </Button>
              <Button variant="outlined" color="secondary" onClick={() => setAllClientsDisplay(true)}>
                <Typography color="secondary">Tous les Clients</Typography>
              </Button>
            </Stack>
          </Stack>
          <Box mt={1} mb={5}>
            <Divider />
            <Box mt={2}>
              <Autocomplete
                id="selectVC"
                aria-label="autoSelectSearch"
                options={clients}
                loading={!loading}
                autoHighlight
                limitTags={3}
                filterOptions={(options, state) => {
                  return defaultFilterOptions(options, state).slice(0, 10)
                }}
                onInputChange={(e, newInputValue) => handleSearch(newInputValue)}
                getOptionLabel={option => (option.name ? option.name : '')}
                open={openSuggest}
                onOpen={() => setSuggest(true)}
                onClose={() => setSuggest(false)}
                renderOption={(props, option) => (
                  <Box m={1} key={`seloption_${option.name}`}>
                    <MenuItem onClick={() => handleClick(option)}>
                      <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <Avatar src={option.icon} alt={option.name} sx={{ width: 50, height: 50 }} />
                        <Typography color={'secondary'}>{option.name}</Typography>
                      </Stack>
                    </MenuItem>
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    color="primary"
                    type="search"
                    variant="outlined"
                    {...params}
                    label={'Chercher Client'}
                    inputProps={{
                      ...params.inputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </Box>
            {client ? (
              <ClientTile client={client} />
            ) : (
              displayAllClients && clients.map(client => <ClientTile client={client} />)
            )}
          </Box>
        </Box>
        <CustomerModal
          open={openClientModal}
          handleClose={() => setClientModal(false)}
          reload={() => window.location.reload()}
        />
        <CustomerModal
          open={openEditModal}
          client={client}
          handleClose={() => setEditModal(false)}
          reload={() => window.location.reload()}
          delMode={false}
        />
        <DevicesModal
          open={openDeviceModal}
          client={client}
          handleClose={() => setDeviceModal(false)}
          reload={() => window.location.reload()}
        />
        <UsersModal
          open={openUserModal}
          client={client}
          handleClose={() => setUserModal(false)}
          reload={() => window.location.reload()}
        />
        <IoTModal
          open={openIoTModal}
          client={client}
          handleClose={() => setIoTModal(false)}
          reload={() => window.location.reload()}
        />
      </Box>
    )
  }
}

export default AllClients
