import { setAlertList } from '../store/userSlice'
import { store } from '../store/store'
import apis from '../api/api'
import { isVCSOnline } from '../utilities/Utils'
import { parseLogDate } from '../utilities/LogInterpreter'

export const parseAlertList = () => {
  const user = store.getState()
  const bypassFlag = !user.vc_list.every(e => !e.bypass)
  const offlineFlag = !user.vc_list.every(e => e.online)
  const bypassList = []
  const offlineList = []
  const messageList = []

  user.alertLists.token_hiddenList.forEach(e => {
    // eslint-disable-next-line default-case
    switch (e.mode) {
      case 'bypass':
        bypassList.push(e.vc_id)
        break
      case 'offline':
        offlineList.push(e.vc_id)
        break
      case 'message':
        messageList.push(e.vc_id)
        break
    }
  })
  store.dispatch(
    setAlertList({
      hiddenList: { bypass: bypassList, offline: offlineList, message: messageList },
      visibleList: { bypass: bypassFlag, offline: offlineFlag, message: messageList }
    })
  )
}

export const alarmListUpdate = (alarm, user, hide) => {
  const storeUser = store.getState()
  store.dispatch(
    setAlertList({
      token_hiddenList: hide
        ? [
            ...storeUser.alertLists.token_hiddenList,
            {
              vc_id: alarm.vc_id,
              mode: alarm.mode
            }
          ]
        : // eslint-disable-next-line array-callback-return
          storeUser.alertLists.token_hiddenList.filter(e => {
            if (!(e.vc_id === alarm.vc_id && e.mode === alarm.mode)) {
              return e
            }
          })
    })
  )
  parseAlertList()
  apis.HideAlarm(user.id, user.email, alarm.vc_id, alarm.mode)
}

export const parseBypassAlarm = (inputList, number) => {
  const offlineHiddenList = store.getState().alertLists.hiddenList.bypass
  const bypassList = []
  const rawBypassList = inputList.filter(e => e.bypass && !offlineHiddenList.includes(e.vc_id) && e.monitored)
  rawBypassList
    .sort((a, b) => new Date(b.bypass_date).getTime() - new Date(a.bypass_date).getTime())
    .forEach((vc, index) => {
      if (index <= number) {
        bypassList.push(vc)
      }
    })
  return { maxDisplay: rawBypassList.length, list: bypassList }
}

export const parseSyncAlarm = (inputList, number) => {
  const offlineList = []
  const offlineHiddenList = store.getState().alertLists.hiddenList.offline
  const rawOfflineList = inputList
    .filter(e => !!e.time && !offlineHiddenList.includes(e.vc_id) && e.monitored)
    .filter(e => !isVCSOnline(e.time))
  rawOfflineList
    .sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime())
    .forEach((vc, index) => {
      if (index <= number) {
        offlineList.push(vc)
      }
    })
  return { maxDisplay: rawOfflineList.length, list: offlineList }
}

export const parseLogAlarm = (inputList, number) => {
  const alarmList = []
  const messageHiddenList = store.getState().alertLists.hiddenList.message
  const rawAlarmList = inputList
    .filter(e => !messageHiddenList.includes(e.vc_id) && e.monitored)
    .map(e => (e.log ? { ...e, ...e.log } : null))
    .filter(e => e && e.message !== 'Server Time')
    .map(e => ({ ...e, isoTime: parseLogDate(e.date) }))
    .sort((a, b) => b.isoTime.getTime() - a.isoTime.getTime())
  rawAlarmList.forEach((vc, index) => {
    if (index <= number) {
      alarmList.push(vc)
    }
  })
  return { maxDisplay: rawAlarmList.length, list: alarmList }
}
