import Box from '@material-ui/core/Box'
import { Card } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Stack } from '@mui/material'

const useStyles = makeStyles({
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  avatar: {
    width: 12,
    height: 12
  }
})

export function BenchDisplay(props) {
  const classes = useStyles()

  return (
    <>
      <Card className={classes.card}>
        {props.data.map(b => (
          <Box mt={2} key={`sectItem_${b.sector}`}>
            <Box mx={3} my={3}>
              <Stack>
                <Typography color="secondary">
                  {b.sector} ({b.vc_number} VCS pris en compte) tous les pourcentages:{' '}
                  {b.full_list ? b.full_list.join(', ').toString() : 'nc'}
                </Typography>
                <Typography color="primary">{b.value} %</Typography>
              </Stack>
            </Box>
          </Box>
        ))}
      </Card>
    </>
  )
}
