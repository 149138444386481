import { Box, Button, ButtonGroup, Chip, Divider, IconButton } from '@material-ui/core'
import * as React from 'react'
import { useEffect, useState } from 'react'
import apis from '../api/api'
import api from '../api/api'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import VCSelector from '../utilities/VCSelector'
import ReactGA from 'react-ga4'
import { makeIdArray } from '../utilities/Utils'
import Avatar from '@material-ui/core/Avatar'
import loadash from 'lodash'
import EcoGauge from '../ecoGauge/EcoGauge'
import { co2rate, kwRate } from '../Dashboard/Dashboard'
import tree from '../assets/tree.png'
import plug from '../assets/plug.png'
import vcrtl from '../assets/vctrl.png'
import { Stack } from '@mui/material'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'

export function Widget() {
  const history = useHistory()
  const user = useSelector(state => state)
  const [displayWidget, setDisplayWidget] = useState(false)
  const [selectedVC, setSelectedVC] = useState(user.current.value)
  const [selectedVCLabel, setSelectedVCLabel] = useState(user.current.name)
  const [measures, setMeasures] = useState({})

  const { t } = useTranslation()

  const [widgetParams, setWidgetParams] = React.useState({
    percent_eco: true,
    etot: true,
    eecotot: true,
    co2_eco: true,
    men_eco: true
  })

  const handleSelectedParam = param => {
    const params = widgetParams
    const state = params[`${param}`]
    params[`${param}`] = !state
    setWidgetParams({ ...params })
  }

  const setVc = vc => {
    ReactGA.event({
      category: 'voltapp_selection',
      action: `changed_vcs_${vc.label}`,
      label: user.profile.name,
      nonInteraction: true
    })
    setSelectedVC(vc.value === '' ? makeIdArray(user.profile.vcs) : [vc.value])
    setSelectedVCLabel(vc.value === '' ? 'all' : vc.label)
  }

  const generateWidget = () => {
    setDisplayWidget(true)
    refreshWidget()
  }

  const refreshWidget = () => {
    const eEcoTots = []
    const eTots = []
    api.getCurrent(selectedVC).then(rs => {
      rs.forEach(r => {
        if (r.time) {
          eEcoTots.push(r.EecoTot)
          eTots.push(r.Etot)
        }
      })
      setMeasures({
        EecoTot: loadash.sum(eEcoTots),
        Etot: loadash.sum(eTots)
      })
    })
  }

  useEffect(() => {
    const authCheck = () => {
      const token = localStorage.getItem('token')
      if (token) {
        apis.tokenCheck(token).then(r => {
          if (!r.data.isLoggedIn) {
            history.push('/')
          }
        })
      } else {
        history.push('/')
      }
    }
    authCheck()
    const interval = setInterval(() => {
      refreshWidget()
    }, 60000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, user.view_params.lang.id])

  return !displayWidget ? (
    <Box mx={'5%'} mt={2}>
      <Stack direction={'row'}>
        <IconButton onClick={() => history.goBack()}>
          <ArrowBackIosNewRoundedIcon style={{ color: '#9a9a9a' }} fontSize={'medium'} />
        </IconButton>
        <Typography variant={'h4'} color={'primary'}>
          {t('div.VoltApp_options')}
        </Typography>
      </Stack>
      <Box my={1}>
        <Divider />
      </Box>
      <Stack direction={'row'} spacing={2} mt={2}>
        <VCSelector
          styled={true}
          setValue={setVc}
          prop_vc={[{ label: t('vc.appareil'), value: '' }, ...user.profile.vcs]}
          admin={false}
          prop_label={user.current.name}
          mobile={false}
        />
        <ButtonGroup variant="outlined">
          <Button onClick={() => handleSelectedParam('percent_eco')}>
            <Typography color={widgetParams['percent_eco'] ? 'primary' : 'secondary'} variant={'body2'}>
              {t('vc.percent_eco')}
            </Typography>
          </Button>
          <Button onClick={() => handleSelectedParam('eecotot')}>
            <Typography color={widgetParams['eecotot'] ? 'primary' : 'secondary'} variant={'body2'}>
              {t('vc.eecotot')}
            </Typography>
          </Button>
          <Button onClick={() => handleSelectedParam('etot')}>
            <Typography color={widgetParams['etot'] ? 'primary' : 'secondary'} variant={'body2'}>
              {t('vc.etot')}
            </Typography>
          </Button>
          <Button onClick={() => handleSelectedParam('co2_eco')}>
            <Typography color={widgetParams['co2_eco'] ? 'primary' : 'secondary'} variant={'body2'}>
              {t('vc.co2_eco')}
            </Typography>
          </Button>
          <Button onClick={() => handleSelectedParam('men_eco')}>
            <Typography color={widgetParams['men_eco'] ? 'primary' : 'secondary'} variant={'body2'}>
              {t('vc.men_eco')}
            </Typography>
          </Button>
        </ButtonGroup>
        <Chip
          color="primary"
          label={
            <Typography style={{ color: '#ffffff' }} variant={'body2'}>
              {t('div.VoltApp_create')}
            </Typography>
          }
          onClick={generateWidget}
        />
      </Stack>
    </Box>
  ) : (
    <Box mx={'5%'} mt={2}>
      <Stack>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={2}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
            <IconButton onClick={() => setDisplayWidget(false)}>
              <ArrowBackIosNewRoundedIcon style={{ color: '#9a9a9a' }} fontSize={'medium'} />
            </IconButton>
            <Typography color={'primary'} variant={'h5'}>
              {selectedVCLabel === 'all' ? t('vc.appareil') : selectedVCLabel}
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Avatar src={user.profile.icon} />
            <Typography color={'primary'} variant={'h5'}>
              {user.profile.name}
            </Typography>
          </Stack>
        </Stack>
        <Box mt={2}>
          <Divider color={'secondary'} />
        </Box>
        <Stack mt={4}>
          <Box height={widgetParams.percent_eco ? 300 : 150}>
            {widgetParams.percent_eco && (
              <EcoGauge
                value={Number(measures.EecoTot / measures.Etot).toFixed(3)}
                variant={'standard'}
                vc={selectedVCLabel === 'all' ? t('vc.appareil') : selectedVCLabel}
                mobile={false}
              />
            )}
          </Box>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-around'} mt={-5}>
          {widgetParams.eecotot && (
            <Stack>
              <Typography color="primary" variant="h4" align="center">
                {measures.EecoTot ? measures.EecoTot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") : ''}{' '}
                kWh
              </Typography>
              <Typography color="secondary" variant="body2" align="center">
                {t('vc.eecotot')}
              </Typography>
            </Stack>
          )}
          {widgetParams.etot && (
            <Stack>
              <Typography color="primary" variant={'h4'} align="center">
                {measures.Etot ? measures.Etot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") : ''} kWh
              </Typography>
              <Typography color="secondary" variant="body2" align="center">
                {t('vc.etot')}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack direction={'row'} justifyContent={'space-around'} mt={10}>
          {widgetParams.co2_eco && (
            <Stack>
              <Stack direction={'row'} justifyContent="center">
                <img src={tree} alt="tree equiv" height={'40px'} />
                <Box ml={1}>
                  <Typography color="primary" variant={'h4'}>
                    {Number((measures['EecoTot'] * co2rate) / 1000)
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
                  </Typography>
                </Box>
              </Stack>
              <Box mt={1}>
                <Typography color="secondary" variant={'body2'} align="center">
                  {t('vc.co2_eco')}
                </Typography>
              </Box>
            </Stack>
          )}
          {widgetParams.men_eco && (
            <Stack>
              <Box ml={0}>
                <Stack direction={'row'} justifyContent="center">
                  <img src={plug} alt="tree equiv" height={'40px'} />
                  <Box ml={1}>
                    <Typography color="primary" variant={'h4'} align="center">
                      {Number(measures['EecoTot'] / kwRate)
                        .toFixed(0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
                    </Typography>
                  </Box>
                </Stack>
                <Box mt={1}>
                  <Typography color="secondary" variant="body2" align="center">
                    {t('vc.men_eco')}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          )}
        </Stack>
        <Box mt={15}>
          <Divider color={'secondary'} />
        </Box>
        <Stack direction={'row'} justifyContent={'flex-end'} mr={3}>
          <Box width={150} mt={2}>
            <img src={vcrtl} alt="voltcontrol logo" height={'30px'} />
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}
