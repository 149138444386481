import React, { useRef } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { Box, Chip, TextField } from '@material-ui/core'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import { frCH } from 'date-fns/locale'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'

export default function CustomChip(props) {
  const mode = props.mode
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectionRange, setDateRange] = React.useState({
    startDate: mode === 'date' ? new Date(props.value.start) : new Date(),
    endDate: mode === 'date' ? new Date(props.value.end) : new Date(),
    key: 'selection'
  })

  const label = React.useState(
    mode === 'CustomLabel'
      ? `${props.value}`
      : mode === 'date'
      ? `${moment(selectionRange.startDate).format('DD/MM/YY')} - ${moment(selectionRange.endDate).format(
          'DD/MM/YY'
        )}`
      : `${props.value} ${props.label}`
  )
  const open = Boolean(anchorEl)
  const custValueRef = useRef('')

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = value => {
    mode === 'date'
      ? handleSelect({
          selection: {
            startDate: new Date(new Date().setDate(new Date().getDate() - value)),
            endDate: new Date()
          }
        })
      : props.setValue(value)
    handleClose()
  }
  const handleKey = key => {
    // eslint-disable-next-line
    if (key.keyCode === 13) {
      props.setValue(custValueRef.current.value)
      handleClose()
    }
  }

  const handleSelect = date => {
    setDateRange({
      startDate: date.selection.startDate,
      endDate: date.selection.endDate,
      key: 'selection'
    })
    props.setValue(date.selection.startDate, date.selection.endDate)
  }

  return (
    <>
      <Chip
        variant="outlined"
        label={label.length < 2 ? label : label[0]}
        color="secondary"
        onClick={handleMenu}
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
      >
        {props.choices.map((choice, index) => (
          <Box key={`choice_${index}_${choice.value}`}>
            <MenuItem onClick={() => handleClick(mode === 'CustomLabel' ? choice.value : choice)}>
              <Typography color="secondary">
                {mode === 'CustomLabel'
                  ? choice.value + ' ' + choice.label
                  : mode === 'iot'
                  ? choice.label
                  : choice + ' ' + props.label}
              </Typography>
            </MenuItem>
          </Box>
        ))}

        <Box px={2} mb={1} mt={1}>
          {props.admin === true && mode !== 'date' && (
            <TextField label="nombre" variant="standard" onKeyDown={handleKey} inputRef={custValueRef} />
          )}
          {mode === 'date' && (
            <Box ml={-30.5} mr={-1.9} mb={-3}>
              <DateRangePicker
                ranges={[selectionRange]}
                locale={frCH}
                maxDate={new Date()}
                color="#a6ce34"
                showDateDisplay={true}
                scroll={{ enabled: true }}
                retainEndDateOnFirstSelection={true}
                onChange={handleSelect}
              />
            </Box>
          )}
        </Box>
      </Menu>
    </>
  )
}
