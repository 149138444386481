import React, { useEffect, useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { Chip, TextField } from '@material-ui/core'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Autocomplete, CircularProgress, createFilterOptions, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { debounce } from 'lodash'

export default function VCSelector({ admin, prop_vc, prop_label, noid, mobile, setValue }) {
  const [vc, setVcs] = useState(prop_vc)
  const [anchorEl, setAnchorEl] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [label, setLabel] = useState(prop_label)
  const defaultFilterOptions = createFilterOptions()
  const vc_list = useSelector(state => state.vc_list)
  const { t } = useTranslation()
  const open = Boolean(anchorEl)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = choice => {
    if (choice.label.toString() !== label && choice.online !== 'nc') {
      setValue(choice)
      setLabel(`${choice.value} ${choice.label}`)
    }
    handleClose()
  }

  const handleSearch = debounce(searchText => {
    return searchText
  }, 300) // 300 milliseconds debounce delay

  useEffect(() => {
    if (admin) {
      setVcs(vc_list.map(({ vc_id, name, online }) => ({ vc_id, name, online, choice: vc_id + name })))
      setLoaded(true)
    } else if (vc) {
      setLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {admin ? (
        <Stack direction="column" alignItems="center" spacing={2}>
          {admin && (
            <Box mt={0.5} width={mobile ? 330 : 500}>
              <Autocomplete
                id="selectVC"
                aria-label="autoSelectSearch"
                options={vc}
                loading={!loaded}
                autoHighlight
                limitTags={3}
                filterOptions={(options, state) => {
                  return defaultFilterOptions(options, state).slice(0, 10)
                }}
                onInputChange={(e, newInputValue) => handleSearch(newInputValue)}
                getOptionLabel={option => (option.choice ? option.choice : '')}
                renderOption={(props, option) => (
                  <Box m={1} key={`seloption_${option.vc_id}`}>
                    <MenuItem
                      onClick={() =>
                        handleClick({ value: option.vc_id, label: option.name, online: option.online })
                      }
                    >
                      <Stack direction={'row'} spacing={2}>
                        {option.online === true ? (
                          <Typography color="primary">{props.noid ? '' : option.vc_id}</Typography>
                        ) : option.online === false ? (
                          <Typography style={{ color: '#ffa500' }}>
                            {props.noid ? '' : option.vc_id}
                          </Typography>
                        ) : (
                          <Typography style={{ color: '#FF5C5C' }}>
                            {props.noid ? '' : option.vc_id}
                          </Typography>
                        )}
                        <Box maxWidth={mobile ? 190 : 700}>
                          <Typography color="secondary" noWrap>
                            {' '}
                            {option.name}
                          </Typography>
                        </Box>
                      </Stack>
                    </MenuItem>
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    color="primary"
                    type="search"
                    variant="outlined"
                    {...params}
                    label={t('vc.search')}
                    inputProps={{
                      ...params.inputProps,
                      endAdornment: (
                        <React.Fragment>
                          {!loaded ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </Box>
          )}
        </Stack>
      ) : (
        <>
          <Chip
            variant="outlined"
            color="secondary"
            label={
              <Box maxWidth={mobile ? 120 : 500}>
                <Typography variant={'body2'} noWrap>
                  {label}
                </Typography>
              </Box>
            }
            onClick={handleMenu}
          />
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={open}
            onClose={handleClose}
          >
            <Box px={2} mx={'2%'}>
              {loaded &&
                !admin &&
                vc.map(option => (
                  <Box m={1} key={`vcoptions_${option.value}`}>
                    <MenuItem onClick={() => handleClick(option)}>
                      <Stack direction={'row'} spacing={2}>
                        <Typography color="secondary">{noid ? '' : option.value}</Typography>
                        <Typography color="secondary"> {option.label}</Typography>
                      </Stack>
                    </MenuItem>
                  </Box>
                ))}
            </Box>
          </Menu>
        </>
      )}
    </>
  )
}
