import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { AppBar, Box, Button, IconButton, Toolbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AlarmDisplay from './AlarmDisplay'
import { useSelector } from 'react-redux'
import { alarmListUpdate } from './alarmUtils'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  avatar: {
    width: 14,
    height: 14
  }
}))

export default function MutedDialog({ open, handleClose, admin, openAssist, user }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const storeUser = useSelector(state => state)
  const mode = storeUser.alertLists.selectedType

  const handleAlarmHide = (vc, mode) => {
    alarmListUpdate({ vc_id: vc.vc_id, mode: mode }, user, false)
  }

  const returnMuted = () => {
    if (storeUser.alertLists.token_hiddenList.length > 0 && storeUser.vc_list) {
      const hiddenAlarms = []
      storeUser.alertLists.token_hiddenList.forEach(a => {
        storeUser.vc_list.forEach(vc => {
          if (a.vc_id === vc.vc_id && mode === a.mode) {
            hiddenAlarms.push({ vc: vc, mode: a.mode })
          }
        })
      })
      return hiddenAlarms.length ? hiddenAlarms : []
    } else {
      return []
    }
  }

  const checkEmptyList = () => {
    // eslint-disable-next-line default-case
    switch (mode) {
      case 'bypass':
        return storeUser.alertLists.hiddenList.bypass.length > 0
      case 'offline':
        return storeUser.alertLists.hiddenList.offline.length > 0
      case 'message':
        return storeUser.alertLists.hiddenList.message.length > 0
    }
  }

  return (
    <Dialog fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <Typography variant="h6" className={classes.title} color="secondary">
            {t('vc.muted_alarm')}
          </Typography>
          <IconButton color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon color="secondary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box mt={5} mb={5} mx={'5%'}>
        <Box mt={1} mb={3}>
          <AlarmDisplay alarm={returnMuted()} admin={admin} hideCallback={handleAlarmHide} />
          {!checkEmptyList() && (
            <Typography color="secondary" variant={'body2'} align={'center'}>
              {t('vc.no_alarm')}
            </Typography>
          )}
        </Box>
        <Stack direction="row" justifyContent="center">
          <Box mt={3} mb={-2}>
            <Button variant="outlined" onClick={() => openAssist()}>
              <Typography color="secondary">{t('vc.assist')}</Typography>
            </Button>
          </Box>
        </Stack>
      </Box>
    </Dialog>
  )
}
