import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'

import { Backdrop, CardActionArea, CircularProgress, Divider, TextField } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useHistory, useLocation } from 'react-router-dom'
import apis from '../../api/api'
import { VCdisplay } from './vcDisplay'
import { LatestAdminMes } from './LatestAdminMes'
import { LatestLogs } from './LatestLogs'
import { Autocomplete, createFilterOptions, Stack } from '@mui/material'
import { NewVCSModal } from './NewVCSModal'
import { isVCSOnline } from '../../utilities/Utils'
import { debounce } from 'lodash'

function VcsDetails() {
  const [loading, setLoading] = React.useState(true)
  const [currentMes, setCurrentMes] = React.useState([])
  const [vc, setVc] = React.useState({})
  const [openNewVCModal, setNewVcModal] = React.useState(false)
  const history = useHistory()
  const location = useLocation()
  const defaultFilterOptions = createFilterOptions()

  const authCheck = () => {
    const token = localStorage.getItem('token')
    if (token) {
      apis.tokenCheck().then(r => {
        if (!(r.data.isLoggedIn && r.data.user.isAdmin)) {
          history.push('/')
        }
      })
    } else {
      history.push('/')
    }
  }

  const handleClick = vc => {
    setLoading(true)
    vc.client_id
      ? apis.getProfile(vc.client_id).then(r => {
          r.data.name ? setVc({ ...vc, client: r.data.name }) : setVc(vc)
        })
      : setVc(vc)
  }

  useEffect(() => {
    authCheck()
    apis.getAllCurrent().then(r => {
      const choices = []
      r.data.forEach(vc_el => {
        if (location.state && Object.keys(vc).length < 1 && vc_el.vc_id === location.state.vc.vc_id) {
          setVc({ ...vc_el, online: vc_el.time ? isVCSOnline(vc_el.time) : 'nc' })
        }
        choices.push({
          ...vc_el,
          online: vc_el.time ? isVCSOnline(vc_el.time) : 'nc',
          choice: vc_el.vc_id + vc_el.name
        })
      })
      setCurrentMes(choices)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vc])

  const handleSearch = debounce(searchText => {
    return searchText
  }, 300) // 300 milliseconds debounce delay

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
      <Box mx="5%">
        <Stack>
          <Box mt={15} minWidth={900}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography color="primary" variant="h5">
                Aperçu technique VCS
              </Typography>
              {/*<Button variant="outlined" color="secondary" onClick={() => setNewVcModal(true)}>
                <Typography color="secondary">Nouveau VCS</Typography>
              </Button>*/}
            </Stack>
            <Box mt={1}>
              <Divider />
            </Box>
          </Box>
          <Box mt={3}>
            <Autocomplete
              id="selectVC"
              fullWidth={true}
              options={currentMes}
              autoHighlight
              limitTags={3}
              filterOptions={(options, state) => {
                return defaultFilterOptions(options, state).slice(0, 10)
              }}
              onInputChange={(e, newInputValue) => handleSearch(newInputValue)}
              getOptionLabel={option => (option.choice ? option.choice : '')}
              renderOption={(props, option) => (
                <CardActionArea key={`seloption_${option.vc_id}`} onClick={() => handleClick(option)}>
                  <Box m={1}>
                    <Stack direction={'row'} spacing={2}>
                      {option.online === true ? (
                        <Typography color="primary">{option.vc_id}</Typography>
                      ) : option.online === false ? (
                        <Typography style={{ color: '#ffa500' }}>{option.vc_id}</Typography>
                      ) : (
                        <Typography style={{ color: '#FF5C5C' }}>{option.vc_id}</Typography>
                      )}
                      <Typography color="secondary"> {option.name}</Typography>
                    </Stack>
                  </Box>
                </CardActionArea>
              )}
              renderInput={params => (
                <TextField
                  color="primary"
                  type="search"
                  variant="outlined"
                  {...params}
                  label="Chercher un VCS"
                  inputProps={{
                    ...params.inputProps
                  }}
                />
              )}
            />
          </Box>

          {vc.vc_id && (
            <>
              <VCdisplay initial_vc={vc} />
              <LatestAdminMes vc={vc} />
              <LatestLogs
                vc_id={vc.vc_id}
                vc_model={vc.model}
                latest={vc.time ? vc.time : new Date()}
                setLoading={setLoading}
              />
            </>
          )}
          <NewVCSModal
            open={openNewVCModal}
            handleClose={() => setNewVcModal(false)}
            reload={() => window.location.reload()}
          />
        </Stack>
      </Box>
    )
  }
}

export default VcsDetails
