import * as React from 'react'
import { ResponsiveLine } from '@nivo/line'
import moment from 'moment'
import { Card } from '@mui/material'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { formStyles } from '../styles/makeStyles'

export default function Graph({
  measures,
  min,
  max,
  format,
  curve,
  yScale,
  yscaleLabel,
  tickValue,
  colors,
  unit,
  legendOffset,
  legendSpace,
  mobile
}) {
  const classes = formStyles()
  return (
    <ResponsiveLine
      data={measures}
      margin={{ top: 5, right: 60, bottom: 150, left: 60 }}
      xScale={{
        type: 'time',
        format: '%s',
        useUTC: false,
        precision: 'minute'
      }}
      yScale={{ type: yScale ? yScale : 'linear', min: min, max: max, reverse: false }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 14,
        tickRotation: 65,
        legendOffset: 36,
        legendPosition: 'middle',
        format: format
        //tickValues: 20
      }}
      axisLeft={
        tickValue
          ? {
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              tickValues: tickValue,
              legendOffset: -44,
              legendPosition: 'middle',
              legend: yscaleLabel
            }
          : {
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: -44,
              legendPosition: 'middle',
              legend: yscaleLabel
            }
      }
      colors={colors}
      lineWidth={2}
      enableGridY={false}
      enableGridX={false}
      enablePoints={false}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      areaBlendMode="multiply"
      useMesh={true}
      curve={curve ? curve : 'linear'}
      tooltip={({ point }) => {
        return (
          <Box mr={4}>
            <Card className={classes.card}>
              <Box mx={2} my={1}>
                <Typography color="primary" style={{ fontWeight: 350 }}>
                  {moment(point.data.x).format('DD/MM/YY HH:mm')}
                </Typography>
                <Typography>
                  {point.data.y}
                  {` ${unit ? unit : ''}`}
                </Typography>
              </Box>
            </Card>
          </Box>
        )
      }}
      legends={[
        {
          anchor: 'bottom',
          direction: mobile ? 'column' : 'row',
          justify: false,
          translateX: 0,
          translateY: legendOffset,
          itemsSpacing: mobile ? 1 : legendSpace ? legendOffset : 50,
          itemDirection: 'left-to-right',
          itemWidth: 91,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 10,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)'
        }
      ]}
    />
  )
}
