import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'

import { Backdrop, CircularProgress, Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import apis from '../../api/api'
import loadash from 'lodash'
import { BenchDisplay } from './benchDisplay'
import EcoGauge from '../../ecoGauge/EcoGauge'
import InstantValueGrid from '../../instantValues/InstantValueGrid'
import EquivIcon from '../../equivIcon/EquivIcon'
import { kStr } from '../../utilities/Utils'
import { makeStyles } from '@material-ui/core/styles'
import { Card } from '@material-ui/core'
import { Stack } from '@mui/material'

let co2rate = 0.128
let kwRate = 2190

const useStyles = makeStyles({
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  }
})

export default function AdminStatsDashboard() {
  const [loading, setLoading] = React.useState(true)
  const [currentMes, setCurrentMes] = React.useState([])
  const [Etot, setEtot] = React.useState(0)
  const [Ecotot, setEcoTot] = React.useState(0)
  const [EtotRP, setEtotRP] = React.useState(0)
  const [EcototRP, setEcoTotRP] = React.useState(0)
  const [enoEco, setEnoEco] = React.useState(0)
  const [benchmarks, setBenchmarks] = React.useState([])
  const [onlineVC, setOnlineVCS] = React.useState(0)
  const [onlineVCnb, setOnlineVCSnb] = React.useState(0)
  const [days, setDays] = React.useState(0)
  const classes = useStyles()

  const addAll = sets => {
    const EtotArray = []
    const EecoArray = []
    const enoEcoArray = []
    const thpercentArray = []
    const EtotArrayRP = []
    const EecoArrayRP = []
    let online = 0
    let onlineNb = 0
    sets.forEach(set => {
      if (set.Etot) {
        if (Number((Date.now() - new Date(set.time).getTime()) / 1000 / 60).toFixed(0) < 20) {
          set.bypass ? online++ : onlineNb++
        }
        const div = Number(set.EecoTot / set.Etot)
        if (div < 0.3 && div > 0.001) {
          EtotArray.push(set.Etot)
          EecoArray.push(set.EecoTot)
          if (set.baselineSavedE && set.baselineEeco && !set.bypass) {
            EtotArrayRP.push(Number(set.Etot - set.baselineEeco))
            EecoArrayRP.push(Number(set.EecoTot - set.baselineSavedE))
          }

          if (set.bypass && set.Etot && set.baselineSavedE) {
            enoEcoArray.push(
              parseInt(
                Number(
                  ((set.Etot - set.baselineSavedE) / new Date(set.installed).getTime()) * set.bypass_time
                )
              )
            )
          }
          if (set.baselineSavedE && set.baselineEeco) {
            thpercentArray.push(Number((set.EecoTot - set.baselineSavedE) / (set.Etot - set.baseLineEeco)))
          }
        }
      }
    })

    setEcoTot(loadash.sum(EecoArray))
    setEtot(loadash.sum(EtotArray))
    setEcoTotRP(loadash.sum(EecoArrayRP))
    setEtotRP(loadash.sum(EtotArrayRP))
    setEnoEco(loadash.sum(enoEcoArray))
    //setThpercent()
    setDays(Number((new Date() - new Date(sets[0].installed)) / 86400000).toFixed(0))
    setOnlineVCS(online)
    setOnlineVCSnb(onlineNb)
  }

  const history = useHistory()

  const authCheck = () => {
    const token = localStorage.getItem('token')
    if (token) {
      apis.tokenCheck().then(r => {
        if (!(r.data.isLoggedIn && r.data.user.isAdmin)) {
          history.push('/')
        }
      })
    } else {
      history.push('/')
    }
  }

  useEffect(() => {
    const refresh = () => {
      authCheck()
      apis.getAllCurrent().then(r => {
        setCurrentMes(r.data)
        addAll(r.data)
        apis.getAllBenchmarks().then(r => {
          setBenchmarks(r.data)
          setLoading(false)
        })
      })
    }
    refresh()
    const interval = setInterval(() => {
      refresh()
    }, 60000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
      <Box mt={17} mx="7%">
        <EcoGauge
          value={onlineVC + onlineVCnb}
          total={currentMes.length}
          variant={'standard'}
          label={'vc'}
          vc={`VCS en bypass durant la période : ${onlineVC}/${currentMes.length}`}
        ></EcoGauge>
        <Box mt={-5} mb={1} mx={'7%'}>
          <EquivIcon
            trees={kStr(Number((Ecotot * co2rate) / 1000).toFixed(0))}
            kws={kStr(Number(Ecotot / kwRate).toFixed(0))}
            variant={'admin'}
          />
        </Box>
        <InstantValueGrid
          content={[
            {
              label: 'Puissance totale',
              value: `${kStr(Etot)} kWh`
            },
            {
              label: 'Economie totale',
              value: `${kStr(Ecotot)} kWh`
            },
            { label: 'Economie moyenne', value: `${Number(Ecotot / Etot).toFixed(2) * 100} %` }
          ]}
        />
        <Box mt={7} mb={5}>
          <Divider />
        </Box>
        <Box mt={2}>
          <Stack direction={'row'} justifyContent="space-between">
            <Box width={400}>
              <Box mb={2}>
                <Typography color={'primary'}>Mesures sur la période {days} jours</Typography>
              </Box>
              <Card className={classes.card}>
                <Box m={2} mt={2}>
                  <Stack>
                    <Typography color="secondary">Energie Totale réelle : {kStr(EtotRP)} kWh</Typography>
                    <Typography color="secondary">Economie Totale réelle : {kStr(EcototRP)} kWh</Typography>
                    <Typography color="secondary">
                      pourcentage réel moyen : {Number((EcototRP / EtotRP) * 100).toFixed(2)} %
                    </Typography>
                  </Stack>
                </Box>
              </Card>
            </Box>
            <Box width={400}>
              <Box mb={2}>
                <Typography color={'primary'}>Projection sur 365 jours</Typography>
              </Box>
              <Card className={classes.card}>
                <Box m={2} mt={2}>
                  <Stack>
                    <Typography color="secondary">
                      Energie Totale réelle : {kStr(Number((EtotRP / days) * 365).toFixed(0))} kWh
                    </Typography>
                    <Typography color="secondary">
                      Economie Totale réelle : {kStr(Number((EcototRP / days).toFixed(0) * 365))} kWh
                    </Typography>
                    <Typography color="secondary">
                      pourcentage réel moyen : {Number((EcototRP / EtotRP) * 100).toFixed(2)} %
                    </Typography>
                  </Stack>
                </Box>
              </Card>
            </Box>
          </Stack>
          <Box mt={5}>
            <Typography color="secondary">
              Economies potentielles non réalisées: {enoEco} kWh ({Number((enoEco / EtotRP) * 100).toFixed(3)}
              %)
            </Typography>
          </Box>
        </Box>
        <Box mt={3} mb={5}>
          <Divider />
        </Box>
        <Box mt={7} mb={3}>
          <Box mb={2}>
            <Typography color="primary">Benchmarks</Typography>
          </Box>
          <BenchDisplay data={benchmarks} />
        </Box>
      </Box>
    )
  }
}
