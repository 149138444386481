import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { AppBar, Avatar, Box, Button, Card, IconButton, Toolbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Divider, Stack } from '@mui/material'
import greenShield from '../assets/shield.png'
import orangeShield from '../assets/orangeShield.png'
import vcIcon from '../assets/vc.png'
import green from '../assets/gSquare.png'
import red from '../assets/rSquare.png'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { formStyles } from '../styles/makeStyles'
import { returnAltLabel } from '../utilities/Utils'
import apis from '../api/api'

export default function VcDialog({ handleClose, open, admin, email }) {
  const classes = formStyles()
  const vcs = useSelector(state => state.profile.vcs)
  const vc_list = useSelector(state => state.vc_list)
  const mobile = useSelector(state => state.mobile)
  const alarms = useSelector(state => state.alertLists)
  const bypassFlag = alarms.visibleList.bypass
  const offlineFlag = alarms.visibleList.offline
  const problemFLag = bypassFlag || offlineFlag
  const { t } = useTranslation()

  const handleVCSHide = vc => {
    console.log(email)
    apis.hideVCS(email, vc.vc_id)
  }

  const theoricpercentage = vc => {
    const bypasssTime = vc.bypass_time ? vc.bypass_time : 0
    const dtime = new Date(vc.installed).getTime()
    const oldSystem = !!vc.baseLineEtot
    const ecoByEpoch = oldSystem ? 0 : Number(vc.Etot / (dtime - bypasssTime))
    const enoEco = oldSystem ? Number(((vc.Etot - bypasssTime) / dtime) * bypasssTime) : 0
    const thPercent = oldSystem
      ? Number((vc.EecoTot - vc.baselineSavedE + enoEco) / (vc.Etot - vc.baselineEeco))
      : 0

    return {
      ecoNR: oldSystem
        ? enoEco
            .toFixed(1)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, "'")
        : Number(ecoByEpoch * bypasssTime)
            .toFixed(1)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, "'"),
      pth: oldSystem
        ? Number(vc.EecoTot - vc.baselineSavedE) > 5
          ? Number(thPercent * 100).toFixed(3)
          : 'nc'
        : Number(((vc.EecoTot + ecoByEpoch * bypasssTime) / vc.Etot) * 100).toFixed(3)
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <Typography variant="h6" className={classes.title} color="secondary">
            {t('vc.ap_appareil')}
          </Typography>
          <IconButton color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon color="secondary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box mt={7} mb={3} mx={'5%'}>
        <Stack
          container
          direction={mobile ? 'column' : 'row'}
          justifyContent="center"
          alignItems="center"
          spacing={mobile ? 3 : 10}
        >
          <img src={problemFLag ? orangeShield : greenShield} height="60px" alt="shield" />
          <Box ml={-25}>
            <Typography color="secondary" variant={'body2'} alignItems={mobile ? 'center' : 'left'}>
              {problemFLag ? t('vc.func_unoptimal') : t('vc.func_optimal')}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box mt={5} mb={5} mx={'5%'}>
        <Box mb={0.5}>
          <Typography color="secondary" variant={'body2'}>
            {t('vc.appareil')}
          </Typography>
        </Box>
        <Divider />
        {vc_list.map((vc, index) => (
          <Box mt={2} key={`dialog_${vc_list[index].vc_id}_${index}`}>
            <Card className={classes.card}>
              <Box mx={3} my={3}>
                <Stack
                  direction={mobile ? 'column' : 'row'}
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box maxWidth={mobile ? 'auto' : 450} mb={mobile ? 1 : 0}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <img src={vcIcon} height="60px" alt="warning" />
                      <Stack>
                        <Typography color="primary" style={{ fontWeight: 600 }}>
                          {admin ? vc_list[index].name : returnAltLabel(vcs, vc)}
                        </Typography>
                        <Typography color="secondary" variant={'body2'}>
                          ID: {admin ? vc_list[index].vc_id : vc.vc_id}
                        </Typography>
                        <Typography color="secondary" variant="body2" align="left">
                          {t('div.est_tEcoPercent')} : {theoricpercentage(vc).pth} %
                        </Typography>
                        <Typography color="secondary" variant="body2" align="left">
                          {t('div.est_noecoE')} : {theoricpercentage(vc).ecoNR} kWh
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Stack
                    direction={mobile ? 'column' : 'row'}
                    spacing={mobile ? 2 : 7}
                    alignItems={mobile ? 'flex-start' : 'center'}
                    justifyContent={mobile ? 'space-between' : 'space-between'}
                  >
                    <Divider orientation={mobile ? 'horizontal' : 'vertical'} flexItem />
                    <Stack justifyContent="space-around" alignItems={'flex-start'}>
                      <Stack direction="row" spacing={2} alignItems="center" justifyContent={'flex-start'}>
                        <Avatar src={vc_list[index].bypass ? red : green} className={classes.dot_avatar} />
                        <Typography color="secondary" variant={'body2'}>
                          Mode écomomie
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar src={vc_list[index].online ? green : red} className={classes.dot_avatar} />
                        <Typography color="secondary" variant={'body2'}>
                          Volt+Sync
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar src={green} className={classes.dot_avatar} />
                        <Typography color="secondary" variant={'body2'}>
                          Alarmes
                        </Typography>
                      </Stack>
                      {admin && (
                        <Box mt={2}>
                          <Button variant={'outlined'} color={'secondary'} onClick={vc => handleVCSHide(vc)}>
                            <Box m={-0.5}>
                              <Typography variant="caption">Masquer le VCS</Typography>
                            </Box>
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Card>
          </Box>
        ))}
      </Box>
    </Dialog>
  )
}
