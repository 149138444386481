import axios from 'axios'
import { baseUrl } from './api_config'

const api = axios.create({
  baseURL: `${baseUrl}/api`
})

const basesrv = axios.create({
  baseURL: `${baseUrl}`
})
const token = localStorage.getItem('token')

const createJWTRequest = (req, apimode) => {
  if (apimode) {
    return api.post(`${req}`, { token })
  } else {
    return basesrv.post(`${req}`, { token })
  }
}

//RegisterUser

export const upsertUser = user => {
  return createJWTRequest(
    `/register?email=${user.email}&password=${user.password}&client_id=${user.clientId}&adm_pass=${user.adm_pass}&language=${user.language}&_id=${user._id}&old_email=${user.old_email}&mail_alarms=${user.mail_alarm}&mail_logs=${user.mail_logs}`,
    false
  )
}

export const unlinkUser = user => {
  return createJWTRequest(
    `/register/unlink?email=${user.email}&client_id=${user.client_id}&adm_pass=${user.adm_pass}`,
    false
  )
}

export const getAllUsers = () => {
  return createJWTRequest('/register/allcst', false)
}

export const getUser = email => {
  return createJWTRequest(`/register/getuser?email=${email}`, false)
}

//RegisterCustomer

export const getProfile = id => createJWTRequest(`/register_customer?client_id=${id}`, false)

export const registerClient = client => {
  return basesrv.post(
    `/register_customer/new/?name=${client.name}&client_id=${client.id}&address=${client.address}&contact=${client.contact}&adm_pass=${client.adm_pass}`,
    { icon: client.icon, token: token }
  )
}

export const updateClient = client => {
  return basesrv.post(
    `/register_customer/update?name=${client.name}&client_id=${client.id}&address=${client.address}&contact=${client.contact}&adm_pass=${client.adm_pass}&_id=${client._id}`,
    { icon: client.icon, token: token }
  )
}

export const updateClientAlarmPref = (client_id, state) => {
  return createJWTRequest(`/register_customer/updateAlarmPref?client_id=${client_id}&state=${state}`, false)
}

export const updateClientLogPref = (client_id, state) => {
  return createJWTRequest(`/register_customer/updateLogPref?client_id=${client_id}&state=${state}`, false)
}

export const deleteClient = client => {
  return createJWTRequest(`/register_customer/rmv?_id=${client._id}&adm_pass=${client.adm_pass}`, false)
}

export const registerIoT = iot => {
  return createJWTRequest(
    `/register_customer/iot?label=${iot.label}&value=${iot.value}&client_id=${iot.client_id}&type=${iot.type}&unit=${iot.unit}&newSens=${iot.newSens}`,
    false
  )
}

export const unlinkIoT = iot => {
  return createJWTRequest(
    `/register_customer/uniot?label=${iot.label}&value=${iot.value}&client_id=${iot.client_id}&type=${iot.type}&unit=${iot.unit}`,
    false
  )
}

export const getClients = () => {
  return createJWTRequest(`/register_customer/all`, false)
}
//RegisterVC

export const linkVC = vc => {
  return createJWTRequest(
    `/register_vc/link?name=${vc.name}&vc_id=${vc.vc_id}&client_id=${vc.client_id}&adm_pass=${vc.adm_pass}`,
    false
  )
}

export const registerVC = vc => {
  return createJWTRequest(
    `/register_vc/update?name=${vc.name}&vc_id=${vc.vc_id}&client_id=${vc.client_id}&model=${vc.model}&adm_pass=${vc.adm_pass}&setup_date=${vc.setup_date}&sector=${vc.sector}`,
    false
  )
}

export const unLinkVC = vc => {
  return createJWTRequest(
    `/register_vc/unlink?name=${vc.name}&vc_id=${vc.vc_id}&client_id=${vc.client_id}`,
    false
  )
}

export const updateVC = vc => {
  return createJWTRequest(
    `/register_vc/update?name=${vc.name}&vc_id=${vc.vc_id}&client_id=${vc.client_id}&model=${vc.empl_vc}&adm_pass=${vc.adm_pass}&setup_date=${vc.setup_date}&sector=${vc.sector}&_id=${vc._id}`,
    false
  )
}

export const toggleVCMigration = (vc_id, state) => {
  return createJWTRequest(`/register_vc/migrate?vc_id=${vc_id}&state=${state}`, false)
}

export const toggleVCMonitoring = (vc_id, state) => {
  return createJWTRequest(`/register_vc/monitoring?vc_id=${vc_id}&state=${state}`, false)
}

export const toggleVCSwitchPref = (vc_id, state, key) => {
  // eslint-disable-next-line default-case
  switch (key) {
    case 'monitored':
      return createJWTRequest(`/register_vc/monitoring?vc_id=${vc_id}&state=${state}`, false)
    case 'migrated':
      return createJWTRequest(`/register_vc/migrate?vc_id=${vc_id}&state=${state}`, false)
  }
}

//Login

export const login = user => {
  return basesrv.post(`/login?email=${user.email}&password=${user.password}`)
}

export const testLogin = () => {
  return basesrv.post(`/login/test`)
}

export const hideAlarm = (id, mail, vc_id, mode) => {
  return createJWTRequest(`/login/hid?id=${id}&email=${mail}&vc_id=${vc_id}&mode=${mode}`, false)
}

export const hideVCS = (mail, vc_id) => {
  return createJWTRequest(`/login/hidvc?&email=${mail}&vc_id=${vc_id}`, false)
}

//Auth
export const tokenCheck = () => {
  return createJWTRequest(`/vrfy`, false)
}

//API

export const getAllCurrent = () => {
  return createJWTRequest('/allcurrent', true)
}

export const getlogs = (vc_id, dateinterval) => {
  return createJWTRequest(
    `/log?vc_id=${vc_id}&start=${new Date(dateinterval.start)}&end=${new Date(dateinterval.end)}`,
    true
  )
}

export const getBypass = (vc_id, dateinterval) => {
  return createJWTRequest(
    `/bypass?vc_id=${vc_id}&start=${new Date(dateinterval.start)}&end=${new Date(dateinterval.end)}`,
    true
  )
}

export const getData = (id, period, pts, params) => {
  const urlParam = []
  params.forEach(param => {
    urlParam.push('mes[]=' + param)
  })
  return createJWTRequest(`/${id}/req?date=${period}&agr=${pts}&${urlParam.join('&')}`, true)
}

export const getDatas = async (...reqs) => {
  const promises = []
  const mes = []
  reqs.forEach(measure => {
    const urlParam = []
    measure.params.length >= 2
      ? measure.params.forEach(param => {
          urlParam.push('mes[]=' + param)
        })
      : urlParam.push('mes[]=' + measure.params[0])
    promises.push(
      createJWTRequest(
        `/${measure.id}/req?start=${new Date(measure.start).getTime()}&end=${new Date(
          measure.end
        ).getTime()}&agr=${measure.pts}&${urlParam.join('&')}`,
        true
      )
    )
  })
  const results = await Promise.all(promises)
  results.forEach(r => {
    mes.push(r.data)
  })
  return mes
}

export const getCurrent = async ids => {
  const promises = []
  const mes = []
  ids.length >= 2
    ? ids.forEach(id => {
        promises.push(createJWTRequest(`/${id}/current`, true))
      })
    : promises.push(api.post(`/${ids[0]}/current`, { token: token }))
  const results = await Promise.all(promises)
  results.forEach(r => {
    mes.push(r.data)
  })
  return mes
}

export const getAllBenchmarks = () => {
  return createJWTRequest('/allbenchmark', true)
}

export const getIot = async (...reqs) => {
  const promises = []
  const mes = []
  reqs[0].forEach(iot => {
    promises.push(
      createJWTRequest(
        `/iot/${iot.id}/?start=${new Date(iot.start).getTime()}&end=${new Date(iot.end).getTime()}&agr=${
          iot.pts
        }`,
        true
      )
    )
  })
  const results = await Promise.all(promises)
  results.forEach(r => {
    mes.push(r.data)
  })
  return mes
}

export const getLatestIoT = async (...reqs) => {
  const promises = []
  const mes = []
  reqs[0].forEach(iot => {
    promises.push(createJWTRequest(`/iot/latest/${iot.id}`, true))
  })
  const results = await Promise.all(promises)
  results.forEach(r => {
    mes.push(r.data)
  })
  return mes
}

//cmdPrams

export const addPreq = preq => {
  const urlParam = []
  preq.cmds.length >= 2
    ? preq.cmds.forEach(cmd => {
        urlParam.push('cmds[]=' + cmd)
      })
    : urlParam.push('cmds[]=' + preq.cmds[0])
  return createJWTRequest(`/preq/add?vc_id=${preq.vc_id}&${urlParam.join('&')}`, false)
}

export const getPendingParams = vc_id => {
  return createJWTRequest(`/preq?vc_id=${vc_id}`, false)
}

export const rmvPendingParam = preq => {
  return createJWTRequest(`/preq/rmv?vc_id=${preq.vc_id}&cmd=${preq.cmd}`, false)
}

//Params
export const getVCParams = vc_id => {
  return createJWTRequest(`/param/get?vc_id=${vc_id}`, false)
}

//MaintenanceJournal
export const addMaintenanceEvent = event => {
  return createJWTRequest(
    `/maintlog/add?vc_id=${event.vc_id}&timestamp=${event.timestamp}&msg=${event.msg}`,
    false
  )
}

export const updateMaintenanceEvent = event => {
  return createJWTRequest(
    `/maintlog/update?vc_id=${event.vc_id}&timestamp=${event.timestamp}&msg=${event.msg}&_id=${event._id}`,
    false
  )
}

export const deleteMaintenanceEvent = event => {
  return createJWTRequest(`/maintlog/rmv?_id=${event._id}&vc_id=${event.vc_id}`, false)
}

export const findMaintenanceEvent = (vc_id, dateinterval) => {
  return createJWTRequest(
    `/maintlog/get?vc_id=${vc_id}&start=${new Date(dateinterval.start)}&end=${new Date(dateinterval.end)}`,
    false
  )
}

/*
export const getBenchmarkLog = (mode, target, start, end, pts) => {
  return api.get(`/benchmark/${mode}/${target}/?start=${new Date(start).getTime()}&end=${new Date(end).getTime()}&agr=${pts}`)
}
*/

const apis = {
  getCurrent,
  getProfile,
  getData,
  getDatas,
  getUser,
  getAllUsers,
  upsertUser,
  updateClient,
  registerClient,
  deleteClient,
  login,
  testLogin,
  tokenCheck,
  getAllCurrent,
  getAllBenchmarks,
  getIot,
  getLatestIoT,
  hideAlarm,
  hideVCS,
  getClients,
  registerVC,
  linkVC,
  unLinkVC,
  unlinkUser,
  getlogs,
  getBypass,
  updateVC,
  toggleVCMigration,
  toggleVCMonitoring,
  toggleVCSwitchPref,
  registerIoT,
  unlinkIoT,
  addPreq,
  getPendingParams,
  rmvPendingParam,
  getVCParams,
  addMaintenanceEvent,
  updateMaintenanceEvent,
  deleteMaintenanceEvent,
  findMaintenanceEvent,
  updateClientAlarmPref,
  updateClientLogPref
}

export default apis
