import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { Button, Card, TextField, Typography } from '@material-ui/core'
import { Stack } from '@mui/material'
import service_apis from '../../api/service_api'

const useStyles = makeStyles({
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  }
})

const months = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
]

const years = [
  21 //,22, 23
]
export default function DataForm() {
  const [myResponse, setMyResponse] = useState({})

  const handleSubmit = formData => {
    formData.preventDefault()
    const data = []
    for (const e in formData.target) {
      if (formData.target[e]?.id) data.push({ id: formData.target[e].id, value: formData.target[e].value })
    }
    service_apis.makeCalc(data).then(r => {
      setMyResponse({
        Imax1: r.headers['maxval1'],
        Imax2: r.headers['maxval2']
      })
      const blob = new Blob([r.data], { type: 'application/octet-stream' })
      // Create a temporary link to trigger the download
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${r.headers['content-disposition'].split('=')[1]}.xlsx`
      document.body.appendChild(a)
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    })
  }
  var offset = 5
  const classes = useStyles()
  const generateYear = (yr, index) => {
    offset = index * 14 + 5
    return (
      <Box mt={2}>
        <Card className={classes.card}>
          <Box m={2}>
            {months.map((m, index) => (
              <Stack direction={'row'} spacing={2} alignItems={'center'} mt={2}>
                <Box width={150}>
                  <Typography color={'primary'}>
                    {m} {yr}
                  </Typography>
                </Box>
                <TextField
                  variant="outlined"
                  id={`B${index + offset}`}
                  label={<Typography variant={'caption'}>Energie active HP (kWh)</Typography>}
                />
                <TextField
                  variant="outlined"
                  id={`C${index + offset}`}
                  label={<Typography variant={'caption'}>Energie active Hc (kWh)</Typography>}
                />
                <TextField
                  variant="outlined"
                  id={`E${Number(index + offset)}`}
                  label={<Typography variant={'caption'}>Puissance (kW)</Typography>}
                />
                <TextField
                  variant="outlined"
                  id={`F${index + offset}`}
                  label={<Typography variant={'caption'}>Pointe Annuelle (kW max)</Typography>}
                />
                <TextField
                  variant="outlined"
                  id={`G${index + offset}`}
                  label={<Typography variant={'caption'}>Réactif (kVarh)</Typography>}
                />
                <TextField
                  variant="outlined"
                  id={`O${index + offset}`}
                  label={<Typography variant={'caption'}>Montant Facture (CHF)</Typography>}
                />
              </Stack>
            ))}
          </Box>
        </Card>
      </Box>
    )
  }

  return (
    <Box mx={'5%'} my={'5%'}>
      <Typography color={'primary'} variant={'h3'}>
        Ecocalc
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        {years.map((y, index) => (
          <Box mt={5}>{generateYear(y, index)}</Box>
        ))}
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary">
            {'Calculer'}
          </Button>
          <Box mt={2}>
            <Typography color="secondary" variant={'h6'}>
              Courant maximum 1: {myResponse.Imax1} A{' '}
            </Typography>
            <Typography color="secondary" variant={'h6'}>
              Courant maximum 2: {myResponse.Imax2} A{' '}
            </Typography>
          </Box>
        </Box>
      </form>
    </Box>
  )
}
