import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import {
  AppBar,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import apis from '../../api/api'
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  avatar: {
    width: 14,
    height: 14
  }
}))

export function EditVCSModal({ handleClose, open, vc, reload }) {
  const classes = useStyles()
  const [date, setDate] = React.useState(new Date(vc.installed))

  const handleClientSubmit = (signUp, id) => {
    signUp.preventDefault()
    const vc = {
      name: signUp.target[0].value,
      vc_id: signUp.target[2].value,
      client_id: signUp.target[4].value,
      empl_vc: signUp.target[6].value,
      adm_pass: signUp.target[8].value,
      setup_date: date.getTime(),
      sector: signUp.target[12].value,
      _id: id
    }
    apis.updateVC(vc).then(r => {
      console.log(r)
      if (r.status === 201) {
        handleClose()
        reload()
      }
    })
  }

  return (
    <Dialog fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <Typography variant="h6" className={classes.title} color="secondary">
            Editer VCS
          </Typography>
          <IconButton color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon color="secondary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box height={600} mt={5} mx={'5%'} mb={3}>
        <Box mx={4} mt={2} mb={3}>
          <form className={classes.form} onSubmit={e => handleClientSubmit(e, vc.vc_id)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="vcLabel"
                  label="Désignation du VC"
                  name="vcLabel"
                  defaultValue={vc.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="vcid"
                  label="ID du Voltcontrol"
                  defaultValue={vc.vc_id}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="clientId"
                  label="Numéro de Client"
                  name="cId"
                  defaultValue={vc.client_id}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="empl"
                  label="Emplacement VC"
                  name="emplVC"
                  defaultValue={vc.model}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password admin"
                  type="password"
                  id="password"
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                  <DesktopDateTimePicker
                    label="Date de mise en service"
                    inputFormat="DD/MM/YYYY HH:mm"
                    value={date}
                    onChange={d => setDate(d.$d)}
                    renderInput={params => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Secteur Benchmark</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={vc.sector}
                    label="Secteur Benchmark"
                  >
                    <MenuItem value={'Bureaux'}>Bureaux</MenuItem>
                    <MenuItem value={'Commerces'}>Commerces</MenuItem>
                    <MenuItem value={'Eclairage public'}>Eclairage public</MenuItem>
                    <MenuItem value={'Ecoles'}>Ecoles</MenuItem>
                    <MenuItem value={'EMS'}>EMS</MenuItem>
                    <MenuItem value={'Expositions'}>Expositions</MenuItem>
                    <MenuItem value={'Fitness-Wellness'}>Fitness-Wellness</MenuItem>
                    <MenuItem value={'Habitation'}>Habitation</MenuItem>
                    <MenuItem value={'Hôtels'}>Hôtels</MenuItem>
                    <MenuItem value={'Industrie'}>Industrie</MenuItem>
                    <MenuItem value={'Laboratoires'}>Laboratoires</MenuItem>
                    <MenuItem value={'Logistique'}>Logistique</MenuItem>
                    <MenuItem value={'Piscines'}>Piscines</MenuItem>
                    <MenuItem value={'Prisons'}>Prisons</MenuItem>
                    <MenuItem value={'Parkings'}>Parkings</MenuItem>
                    <MenuItem value={'Divers'}>Divers</MenuItem>
                    <MenuItem value={'Autre'}>Autre</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box mt={5} mb={2}>
              <Button type="submit" fullWidth variant="contained" color="primary">
                <Typography>{'Mettre à jour'}</Typography>
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Dialog>
  )
}
