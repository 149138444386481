import * as React from 'react'
import { Box, Card, Grid, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import tree from '../assets/tree.png'
import plug from '../assets/plug.png'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  card: {
    borderRadius: 19,
    border: '0.25px solid #FFFFFF',
    boxShadow: 'none'
  },
  cardT: {
    borderRadius: 19,
    border: '0.25px solid #FFFFFF',
    boxShadow: 'none'
  },
  divider: {
    // Theme Color, or use css color in quote
    background: '#a6ce34'
  }
})

export default function EquivIcon(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const xs = props.mobile
  return (
    <Box mt={-2}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={3}>
          <Box>
            <Stack direction={'row'} justifyContent="center">
              <img src={tree} alt="tree equiv" height={xs ? '30px' : '40px'} />
              <Box ml={1}>
                <Typography color="primary" variant={xs ? 'h5' : 'h4'}>
                  {props.trees} {props.mobile ? '' : 't'}
                </Typography>
              </Box>
            </Stack>
            <Box mt={1}>
              <Typography color="secondary" variant={'body2'} align="center">
                {t('vc.co2_eco')} {props.mobile ? '[t]' : ''}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          {props.variant !== 'admin' && (
            <Card className={classes.cardT}>
              <Typography color="secondary" variant={xs ? 'h5' : 'h4'} align="center">
                {/* fix whitespace bug*/}
                {props.sumState === t('vc.appareil') || props.sumState === t('vc.appareil')
                  ? props.benchmark
                  : props.clientBench}{' '}
                %
              </Typography>
              <Box mt={1}>
                <Typography color="secondary" variant="body2" align="center">
                  {props.sumState === t('vc.appareil') || props.sumState === t('vc.appareil')
                    ? t('vc.bench_sect')
                    : t('vc.bench_app')}
                </Typography>
              </Box>
            </Card>
          )}
        </Grid>
        <Grid item xs={3}>
          <Box ml={0}>
            <Stack direction={'row'} justifyContent="center">
              <img src={plug} alt="tree equiv" height={xs ? '30px' : '40px'} />
              <Box ml={1}>
                <Typography color="primary" variant={xs ? 'h5' : 'h4'} align="center">
                  {props.kws}
                </Typography>
              </Box>
            </Stack>
            <Box mt={1}>
              <Typography color="secondary" variant="body2" align="center">
                {t('vc.men_eco')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
