import { Route, Switch } from 'react-router-dom'
import './App.css'
import Dashboard from './Dashboard/Dashboard'
import GraphPage from './graphView/GraphPage'
import AuthPage from './auth/AuthPage'
import IotTDash from './IoT/IoTDash'
import ResponsiveDrawer from './admin/ResponsiveDrawer'
import TestMiddleComp from './auth/TestMiddleComp'
import { persistor, store } from './store/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Widget } from './widget/widget'
import DataForm from './services/VCS_calc/DataForm'

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Switch>
          <Route exact path="/graph" render={() => <GraphPage />} />
          <Route path="/dashadmin" render={() => <ResponsiveDrawer />} />
          <Route exact path="/dash" render={() => <Dashboard />} />
          <Route exact path="/iotdash" render={() => <IotTDash />} />
          <Route exact path="/test" render={() => <TestMiddleComp />} />
          <Route exact path="/widget" render={() => <Widget />} />
          <Route exact path="/vcscalc" render={() => <DataForm />} />
          <Route path="/" render={() => <AuthPage />} />
        </Switch>
      </PersistGate>
    </Provider>
  )
}

export default App
