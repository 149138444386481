import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import apis from '../../api/api'
import { Autocomplete, Divider, Stack } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr'
import { useDispatch } from 'react-redux'
import { setCurrentVC } from '../../store/userSlice'
import { isVCSOnline } from '../../utilities/Utils'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  avatar: {
    width: 14,
    height: 14
  }
}))

export function DevicesModal({ handleClose, open, client, reload }) {
  const classes = useStyles()

  const [currentMes, setCurrentMes] = React.useState([])
  const [newVcMode, setNewVCMode] = useState(false)
  const [linkMode, setLinkMode] = useState(false)
  const [openChoice, setOpenChoice] = React.useState(false)
  const [loading] = React.useState(false)
  const [selectedLinkedVCS, setSelectedVCS] = useState({ vc_id: '', name: 'Chercher un VCS' })

  const [date, setDate] = useState(new Date())

  const [nameErr, setNameErr] = useState({ state: false, msg: '' })
  const [vc_idErr, setVc_idErr] = useState({ state: false, msg: '' })
  const [client_idErr, setClientErr] = useState({ state: false, msg: '' })
  const [modelErr, setModelErr] = useState({ state: false, msg: '' })
  const [pswdErr, setpswdErr] = React.useState({ state: false, msg: '' })

  const history = useHistory()
  const dispatch = useDispatch()

  const VCSInputValidation = errorList => {
    setNameErr({ state: false, msg: '' })
    setVc_idErr({ state: false, msg: '' })
    setClientErr({ state: false, msg: '' })
    setModelErr({ state: false, msg: '' })
    setpswdErr({ state: false, msg: '' })

    errorList.forEach(e => {
      // eslint-disable-next-line default-case
      switch (e.concern) {
        case 'name':
          setNameErr({ state: true, msg: e.message })
          break
        case 'vc_id':
          setVc_idErr({ state: true, msg: e.message })
          break
        case 'client_id':
          setClientErr({ state: true, msg: e.message })
          break
        case 'model':
          setModelErr({ state: true, msg: e.message })
          break
        case 'pswd':
          setpswdErr({ state: true, msg: e.message })
          break
      }
    })
  }

  const handleLinkSelect = option => {
    setSelectedVCS({ vc_id: option.vc_id, name: option.name })
    setOpenChoice(false)
  }

  const handleVCSubmit = signUp => {
    signUp.preventDefault()
    const vc = {
      name: signUp.target[0].value,
      vc_id: signUp.target[2].value,
      client_id: signUp.target[4].value,
      model: signUp.target[6].value,
      adm_pass: signUp.target[8].value,
      setup_date: date.getTime(),
      sector: signUp.target[12].value,
      client: client.name
    }
    apis.registerVC(vc).then(r => {
      if (r.status === 201) {
        handleClose()
        reload()
      } else if (r.data.error) {
        VCSInputValidation(r.data.error)
      }
    })
  }

  const handlVCLink = signUp => {
    signUp.preventDefault()
    console.log(signUp.target[0].value)
    const vc = {
      name: signUp.target[0].value !== '' ? signUp.target[0].value : selectedLinkedVCS.name,
      vc_id: selectedLinkedVCS.vc_id,
      adm_pass: signUp.target[5].value,
      client_id: client.client_id,
      client: client.name
    }
    apis.linkVC(vc).then(r => {
      console.log(r)
      if (r.status === 201) {
        handleClose()
        reload()
      } else if (r.data.error) {
        VCSInputValidation(r.data.error)
      }
    })
  }

  const handlUnLink = vc => {
    const req = {
      name: vc.label,
      vc_id: vc.value,
      client_id: client.client_id
    }
    apis.unLinkVC(req).then(r => {
      if (r.status === 200) {
        handleClose()
        reload()
      } else if (r.data.error) {
        VCSInputValidation(r.data.error)
      }
    })
  }

  const handleVCSClick = vc => {
    dispatch(setCurrentVC({ name: vc.label, value: [vc.value] }))
    history.push('/')
  }

  useEffect(() => {
    apis.getAllCurrent().then(r => {
      const choices = []
      r.data.forEach(vc => {
        choices.push({
          vc_id: vc.vc_id,
          name: vc.name,
          online: vc.time ? isVCSOnline(vc.time) : 'nc'
        })
      })
      setCurrentMes(choices)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
      <Dialog fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <AppBar className={classes.appBar} color="transparent">
          <Toolbar>
            <Typography variant="h6" className={classes.title} color="secondary">
              {newVcMode ? 'Nouveau VCS' : linkMode ? 'Lier un VCS' : 'Editer Appareils'}
            </Typography>
            <IconButton color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon color="secondary" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box mt={3} mx={'2%'} mb={3}>
          {newVcMode ? (
            <Box mx={4} mt={2} mb={3}>
              {/* ---------------------NewVcMode -------------------------*/}
              <form className={classes.form} onSubmit={e => handleVCSubmit(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="vcLabel"
                      label="Désignation du VC (ex. VCS Voltcontrol Bureau)"
                      name="vcLabel"
                      error={nameErr.state}
                      helperText={nameErr.msg}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="vcId"
                      label="ID du VC (ex. 21045632)"
                      name="clientId"
                      error={vc_idErr.state}
                      helperText={vc_idErr.msg}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="clientId"
                      label="Numéro de Client (ex. 111)"
                      name="clientId"
                      autoComplete="clientId"
                      error={client_idErr.state}
                      helperText={client_idErr.msg}
                      defaultValue={client.client_id}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="empl"
                      label="Modèle du VC (ex. 250 H)"
                      name="emplVC"
                      error={modelErr.state}
                      helperText={modelErr.msg}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Password admin"
                      type="password"
                      error={pswdErr.state}
                      helperText={pswdErr.msg}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                      <DesktopDateTimePicker
                        label="Date de mise en service"
                        inputFormat="DD/MM/YYYY HH:mm"
                        value={date}
                        onChange={d => setDate(d.$d)}
                        renderInput={params => <TextField {...params} fullWidth />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Secteur Benchmark</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Secteur Benchmark"
                      >
                        <MenuItem value={'Bureaux'}>Bureaux</MenuItem>
                        <MenuItem value={'Commerces'}>Commerces</MenuItem>
                        <MenuItem value={'Eclairage public'}>Eclairage public</MenuItem>
                        <MenuItem value={'Ecoles'}>Ecoles</MenuItem>
                        <MenuItem value={'EMS'}>EMS</MenuItem>
                        <MenuItem value={'Expositions'}>Expositions</MenuItem>
                        <MenuItem value={'Fitness-Wellness'}>Fitness-Wellness</MenuItem>
                        <MenuItem value={'Habitation'}>Habitation</MenuItem>
                        <MenuItem value={'Hôtels'}>Hôtels</MenuItem>
                        <MenuItem value={'Industrie'}>Industrie</MenuItem>
                        <MenuItem value={'Laboratoires'}>Laboratoires</MenuItem>
                        <MenuItem value={'Logistique'}>Logistique</MenuItem>
                        <MenuItem value={'Piscines'}>Piscines</MenuItem>
                        <MenuItem value={'Prisons'}>Prisons</MenuItem>
                        <MenuItem value={'Parkings'}>Parkings</MenuItem>
                        <MenuItem value={'Divers'}>Divers</MenuItem>
                        <MenuItem value={'Autre'}>Autre</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Box mt={5} mb={2}>
                  <Button type="submit" fullWidth variant="contained" color="primary">
                    {'Enregistrer'}
                  </Button>
                </Box>
              </form>
              <Box mt={2}>
                <Button variant="outlined" color="secondary" fullWidth onClick={() => setNewVCMode(false)}>
                  <Typography>Annuler</Typography>
                </Button>
              </Box>
            </Box>
          ) : linkMode ? (
            <Box mx={'2%'} mt={1}>
              {/* ---------------------Linkmode -------------------------*/}
              <Box mx={4} mt={2} mb={3}>
                <form className={classes.form} onSubmit={e => handlVCLink(e)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="vcLabel"
                        label={
                          selectedLinkedVCS.name !== 'Chercher un VCS'
                            ? selectedLinkedVCS.name
                            : 'Nom du VCS (ex. VCS bureau)'
                        }
                        name="vcLabel"
                        error={nameErr.state}
                        helperText={nameErr.msg}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        open={openChoice}
                        onOpen={() => setOpenChoice(true)}
                        id="selectVC"
                        fullWidth={true}
                        options={currentMes}
                        autoHighlight
                        getOptionLabel={option => (option.name ? option.name : '')}
                        renderOption={(props, option) => (
                          <CardActionArea
                            key={`seloption_${option.vc_id}`}
                            onClick={() => handleLinkSelect(option)}
                          >
                            <Box m={1}>
                              <Stack direction={'row'} spacing={2}>
                                {option.online === true ? (
                                  <Typography color="primary">{option.vc_id}</Typography>
                                ) : option.online === false ? (
                                  <Typography style={{ color: '#ffa500' }}>{option.vc_id}</Typography>
                                ) : (
                                  <Typography style={{ color: '#FF5C5C' }}>{option.vc_id}</Typography>
                                )}
                                <Typography color="secondary"> {option.name}</Typography>
                              </Stack>
                            </Box>
                          </CardActionArea>
                        )}
                        renderInput={params => (
                          <TextField
                            color="primary"
                            type="search"
                            variant="outlined"
                            {...params}
                            label={`${selectedLinkedVCS.vc_id.toString()} ${selectedLinkedVCS.name}`}
                            inputProps={{
                              ...params.inputProps
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="pswd"
                        variant="outlined"
                        required
                        fullWidth
                        label="Password admin"
                        type="password"
                        error={pswdErr.state}
                        helperText={pswdErr.msg}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={5} mb={2}>
                    <Button type="submit" fullWidth variant="contained" color="primary">
                      {'Enregistrer'}
                    </Button>
                  </Box>
                </form>
                <Box mt={2}>
                  <Button variant="outlined" color="secondary" fullWidth onClick={() => setLinkMode(false)}>
                    <Typography>Annuler</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box mx={'5%'} mt={1}>
              <Typography color="secondary">Appareils liés au client</Typography>
              <Divider />
              {client.vcs &&
                client.vcs.map(vc => (
                  <Box mt={3} mb={2} key={`device_${vc.value}`}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Card className={classes.card}>
                        <CardActionArea onClick={() => handleVCSClick(vc)}>
                          <Box m={2} minWidth={300}>
                            <Typography color="primary">
                              {vc.value} | {vc.label}
                            </Typography>
                          </Box>
                        </CardActionArea>
                      </Card>
                      <Button color="secondary" onClick={() => handlUnLink(vc)}>
                        <Box mx={2}>
                          <Typography color="secondary" variant="body2">
                            délier
                          </Typography>
                        </Box>
                      </Button>
                    </Stack>
                  </Box>
                ))}
              <Box mt={6}>
                <Button variant="contained" color="primary" fullWidth onClick={() => setNewVCMode(true)}>
                  <Typography style={{ color: '#ffffff' }}>
                    Ajouter un appareil au client (nouveau VCS)
                  </Typography>
                </Button>
              </Box>
              <Box mt={2}>
                <Button variant="outlined" color="secondary" fullWidth onClick={() => setLinkMode(true)}>
                  <Typography color="primary">Lier un VCS existant</Typography>
                </Button>
              </Box>
              <Box mt={2}>
                <Button variant="outlined" color="secondary" fullWidth onClick={() => handleClose()}>
                  <Typography>Fermer</Typography>
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Dialog>
    )
  }
}
