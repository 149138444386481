import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  view_params: {
    params: [['Pa-L1'], ['Pa-L2'], ['Pa-L3']],
    mode: 'Puissance',
    unit: 'kW'
  },
  alertLists: {
    hiddenList: { bypass: [], offline: [], message: [] },
    visibleList: { bypass: 0, offline: 0, message: [] }
  },
  mobile: false
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile(state, action) {
      return {
        ...state,
        profile: action.payload
      }
    },
    setCurrentVC(state, action) {
      return {
        ...state,
        current: action.payload
      }
    },
    setOverviewList(state, action) {
      return {
        ...state,
        vc_list: action.payload
      }
    },
    setCurrentParams(state, action) {
      return {
        ...state,
        view_params: { ...state.view_params, ...action.payload }
      }
    },
    setAlertList(state, action) {
      return {
        ...state,
        alertLists: { ...state.alertLists, ...action.payload }
      }
    },
    setMobile(state, action) {
      return {
        ...state,
        mobile: action.payload
      }
    }
  }
})

export const {
  setUserProfile,
  setCurrentVC,
  setOverviewList,
  setCurrentParams,
  setAlertList,
  setMobile
} = userSlice.actions

export default userSlice.reducer
