import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { AppBar, Box, IconButton, Toolbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Stack } from '@mui/material'
import phone from '../assets/phone.png'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}))

export default function AssistDialog({ handleClose, open }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useSelector(state => state.profile)

  const handleCloseAssist = () => {
    handleClose()
    ReactGA.event({
      category: 'selection',
      action: `affichage du panneau d'assistance`,
      label: user.name,
      nonInteraction: true
    })
  }

  return (
    <Dialog open={open} onClose={handleCloseAssist} aria-labelledby="form-dialog-title">
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <Typography variant="h6" className={classes.title} color="secondary">
            {t('vc.need_assist')}
          </Typography>
          <IconButton color="inherit" onClick={handleCloseAssist} aria-label="close">
            <CloseIcon color="secondary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box my={10} mx={5}>
        <Stack direction="row" spacing={5} alignItems="center">
          <img src={phone} height="60px" alt="phone assist" />
          <Typography variant="body1" color="secondary">
            {t('vc.assist_msg')}
          </Typography>
        </Stack>
      </Box>
    </Dialog>
  )
}
