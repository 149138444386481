import { setCurrentParams } from './userSlice'
import { store } from './store'
import i18next from 'i18next'
import ReactGA from 'react-ga4'

export const setNewMode = (p, setLoading) => {
  setLoading(true)
  ReactGA.event({
    category: 'selection',
    action: `meschange_${p}`,
    label: store.getState().profile.name,
    nonInteraction: true
  })
  // eslint-disable-next-line default-case
  switch (p) {
    case i18next.t('vc.pwr'):
      store.dispatch(
        setCurrentParams({
          params: [['Pa-L1'], ['Pa-L2'], ['Pa-L3']],
          unit: 'kW',
          mode: i18next.t('vc.pwr')
        })
      )
      break
    case i18next.t('vc.cur'):
      store.dispatch(
        setCurrentParams({
          params: [['I1'], ['I2'], ['I3']],
          unit: 'A',
          mode: i18next.t('vc.cur')
        })
      )
      break
    case i18next.t('vc.ten'):
      store.dispatch(
        setCurrentParams({
          params: [
            ['Uin-L1', 'Uin-L2', 'Uin-L3'],
            ['Uout-L1', 'Uout-L2', 'Uout-L3']
          ],
          unit: 'V',
          mode: i18next.t('vc.ten')
        })
      )
      break
    case 'Puissance React':
      store.dispatch(
        setCurrentParams({
          params: [['Pr-L1'], ['Pr-L2'], ['Pr-L3']],
          unit: 'kW',
          mode: p
        })
      )
      break
    case 'Energie':
      store.dispatch(
        setCurrentParams({
          params: [['E-L1'], ['E-L2'], ['E-L3']],
          unit: 'kWh',
          mode: p
        })
      )
      break
    case 'Coefficient Eco':
      store.dispatch(
        setCurrentParams({
          params: [['Ceco1'], ['Ceco2'], ['Ceco3']],
          unit: '',
          mode: p
        })
      )
      break
    case 'Energie Eco':
      store.dispatch(
        setCurrentParams({
          params: [['Eeco-L1'], ['Eeco-L2'], ['Eeco-L3']],
          unit: 'kWh',
          mode: p
        })
      )
      break
  }
  if (/(Uin-L1|Uin-L2|Uin-L3|Uout-L1|Uout-L2|Uout-L3)$/i.test(p)) {
    store.dispatch(
      setCurrentParams({
        params: [[p]],
        unit: 'V',
        mode: p
      })
    )
  }
  if (/(Pa-L1|Pa-L2|Pa-L3|Pr-L1|Pr-L2|Pr-L3)$/i.test(p)) {
    store.dispatch(
      setCurrentParams({
        params: [[p]],
        unit: 'kW',
        mode: p
      })
    )
  }
  if (/(Etot|E-L1|E-L2|E-L3|Eeco-L1|Eeco-L2|Eeco-L3|EecoTot)$/i.test(p)) {
    store.dispatch(
      setCurrentParams({
        params: [[p]],
        unit: 'kW',
        mode: p
      })
    )
  }
  if (/(I1|I2|I3)$/i.test(p)) {
    store.dispatch(
      setCurrentParams({
        params: [[p]],
        unit: 'A',
        mode: p
      })
    )
  }
  if (/(Ceco1|Ceco2|Ceco3)$/i.test(p)) {
    store.dispatch(
      setCurrentParams({
        params: [[p]],
        unit: '',
        mode: p
      })
    )
  }
}

export const setNewLocalMode = p => {
  ReactGA.event({
    category: 'selection',
    action: `meschange_${p}`,
    label: store.getState().profile.name,
    nonInteraction: true
  })
  // eslint-disable-next-line default-case
  switch (p) {
    case i18next.t('vc.pwr'):
      return {
        params: [['Pa-L1'], ['Pa-L2'], ['Pa-L3']],
        unit: 'kW',
        mode: 'vc.pwr'
      }
    case i18next.t('vc.cur'):
      return {
        params: [['I1'], ['I2'], ['I3']],
        unit: 'A',
        mode: i18next.t('vc.cur')
      }
    case i18next.t('vc.ten'):
      return {
        params: [
          ['Uin-L1', 'Uin-L2', 'Uin-L3'],
          ['Uout-L1', 'Uout-L2', 'Uout-L3']
        ],
        unit: 'V',
        mode: i18next.t('vc.ten')
      }
    case 'Puissance React':
      return {
        params: [['Pr-L1'], ['Pr-L2'], ['Pr-L3']],
        unit: 'kW',
        mode: p
      }
    case 'Energie':
      return {
        params: [['E-L1'], ['E-L2'], ['E-L3']],
        unit: 'kWh',
        mode: p
      }

    case 'Coefficient Eco':
      return {
        params: [['Ceco1'], ['Ceco2'], ['Ceco3']],
        unit: '',
        mode: p
      }
    case 'Energie Eco':
      return { params: [['Eeco-L1'], ['Eeco-L2'], ['Eeco-L3']], unit: 'kWh', mode: p }
  }
  if (/(Uin-L1|Uin-L2|Uin-L3|Uout-L1|Uout-L2|Uout-L3)$/i.test(p)) {
    return {
      params: [[p]],
      unit: 'V',
      mode: p
    }
  }
  if (/(Pa-L1|Pa-L2|Pa-L3|Pr-L1|Pr-L2|Pr-L3)$/i.test(p)) {
    return {
      params: [[p]],
      unit: 'kW',
      mode: p
    }
  }
  if (/(Etot|E-L1|E-L2|E-L3|Eeco-L1|Eeco-L2|Eeco-L3|EecoTot)$/i.test(p)) {
    return {
      params: [[p]],
      unit: 'kW',
      mode: p
    }
  }
  if (/(I1|I2|I3)$/i.test(p)) {
    return {
      params: [[p]],
      unit: 'A',
      mode: p
    }
  }
  if (/(Ceco1|Ceco2|Ceco3)$/i.test(p)) {
    return {
      params: [[p]],
      unit: '',
      mode: p
    }
  }
}
