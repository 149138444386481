import * as React from 'react'
import { useEffect } from 'react'
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  useMediaQuery
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import api from '../api/api'
import apis from '../api/api'
import TopBar from '../appBar/AppBar'
import CustomChip from './CustomChip'
import Graph from './Graph'
import { makeIdArray, sumDataset } from '../utilities/Utils'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import VCSelector from '../utilities/VCSelector'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import { setCurrentVC } from '../store/userSlice'
import { setNewLocalMode, setNewMode } from '../store/stateChangers'
import { theme } from '../index'

export default function GraphView(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const [isAdmin, setAdmin] = React.useState(false)
  const [measures, setMeasures] = React.useState([])
  const [refreshing, setRefreshing] = React.useState(false)
  const user = useSelector(state => state)
  const [loading, setLoading] = React.useState(true)
  const [pts, setPts] = React.useState(100)
  const [dateinterval, setDateInterval] = React.useState({
    start: new Date(new Date(user.view_params.time).setDate(new Date(user.view_params.time).getDate() - 7)),
    end: new Date()
  })
  const exportlabelW = 130
  const dispatch = useDispatch()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    const authCheck = () => {
      const token = localStorage.getItem('token')
      if (token) {
        apis.tokenCheck(token).then(r => {
          if (!r.data.isLoggedIn) {
            history.push('/')
          }
          if (r.data.user.isAdmin) {
            setAdmin(true)
          }
        })
      } else {
        history.push('/')
      }
    }
    authCheck()
  }, [history, user.view_params.lang.id])

  const [exportParams, setExportParams] = React.useState({
    'Uin-L1': false,
    'Uin-L2': false,
    'Uin-L3': false,
    'Uin-Moy': false,
    'Uout-L1': false,
    'Uout-L2': false,
    'Uout-L3': false,
    'Uout-Moy': false,
    I1: false,
    I2: false,
    I3: false,
    'Pa-L1': false,
    'Pa-L2': false,
    'Pa-L3': false,
    PaTot: false,
    'Pr-L1': false,
    'Pr-L2': false,
    'Pr-L3': false,
    PrTot: false,
    Etot: false,
    EecoTot: false
  })

  const handleSelectedParam = param => {
    const params = exportParams
    const state = params[`${param}`]
    params[`${param}`] = !state
    setExportParams({ ...params })
  }

  const submitExport = () => {
    const data = []
    const params = []
    const calcParams = []
    Object.entries(exportParams).forEach(key => {
      if (key[1] && /(Uin-Moy|Uout-Moy|PaTot|PrTot)$/i.test(key[0])) {
        calcParams.push(key[0])
      } else if (key[1]) {
        params.push([key[0]])
      }
    })
    if (params.length >= 1 || calcParams.length >= 1) {
      api.getDatas(...parseReq(params)).then(async r => {
        data.push(...r)
        if (calcParams.includes('Uin-Moy')) {
          const inMoyParams = [['Uin-L1', 'Uin-L2', 'Uin-L3']]
          await api.getDatas(...parseReq(inMoyParams)).then(r => {
            r[0].id = 'Uin-Moy'
            data.push(...r)
          })
        }
        if (calcParams.includes('Uout-Moy')) {
          const outMoyParams = [['Uout-L1', 'Uout-L2', 'Uout-L3']]
          await api.getDatas(...parseReq(outMoyParams)).then(r => {
            r[0].id = 'Uout-Moy'
            data.push(...r)
          })
        }
        if (calcParams.includes('PaTot')) {
          const iparams = [['Pa-L1'], ['Pa-L2'], ['Pa-L3']]
          await api.getDatas(...parseReq(iparams)).then(r => {
            const summedData = sumDataset(r, 'Puissance', iparams)
            summedData.data[3].id = 'PaTot'
            data.push(summedData.data[3])
          })
        }
        if (calcParams.includes('PrTot')) {
          const iparams = [['Pr-L1'], ['Pr-L2'], ['Pr-L3']]
          await api.getDatas(...parseReq(iparams)).then(r => {
            const summedData = sumDataset(r, 'Puissance', iparams)
            summedData.data[3].id = 'PrTot'
            data.push(summedData.data[3])
          })
        }
        handleClick([{ data: data }])
      })
    }
  }

  const paramChoice = [t('vc.pwr'), t('vc.cur'), t('vc.ten')]
  const adminParamChoice = [
    t('vc.pwr'),
    t('vc.cur'),
    t('vc.ten'),
    'Energie',
    'Puissance React',
    'Coefficient Eco',
    'Energie Eco',
    'Ceco1',
    'Ceco2',
    'Ceco3',
    'Uin-L1',
    'Uin-L2',
    'Uin-L3',
    'Uout-L1',
    'Uout-L2',
    'Uout-L3',
    'Pa-L1',
    'Pa-L2',
    'Pa-L3',
    'I1',
    'I2',
    'I3',
    'Pr-L1',
    'Pr-L2',
    'Pr-L3',
    'Etot',
    'E-L1',
    'E-L2',
    'E-L3',
    'Eeco-L1',
    'Eeco-L2',
    'Eeco-L3',
    'EecoTot'
  ]

  const handleAddMesure = v => {
    const newMode = setNewLocalMode(v)
    api.getDatas(...parseReq(newMode.params)).then(r => {
      setMeasures([
        ...measures,
        {
          mode: newMode.mode,
          unit: newMode.unit,
          value: user.current.name,
          label: newMode.mode,
          ...sumDataset(r, newMode.mode, newMode.params)
        }
      ])
    })
  }

  const setPointNumber = npts => {
    ReactGA.event({
      category: 'selection',
      action: `changed_points_${pts}`,
      label: user.profile.name,
      nonInteraction: true
    })
    if (npts !== pts) {
      setPts(npts)
      setRefreshing(true)
    }
  }
  const setDates = (start, end) => {
    ReactGA.event({
      category: 'selection',
      action: `changed_date_${start}_${end}`,
      label: user.profile.name,
      nonInteraction: true
    })
    if (!(end > Date.now())) {
      setDateInterval({
        start: start,
        end: end
      })
      setRefreshing(true)
    }
  }
  const setVc = vc => {
    ReactGA.event({
      category: 'selection',
      action: `changed_vcs_${vc.label}`,
      label: user.profile.name,
      nonInteraction: true
    })
    const subval = vc.value === '' ? makeIdArray(user.profile.vcs) : [vc.value]
    if (subval.toString() !== user.current.value.toString()) {
      dispatch(setCurrentVC({ name: vc.label, value: subval }))
      setRefreshing(true)
    }
  }

  const parseReq = params => {
    const request = []
    if (user.current.value.length > 1) {
      user.current.value.forEach(vc => {
        params.forEach(param => {
          request.push({
            id: vc,
            start: dateinterval.start,
            end: dateinterval.end,
            pts: pts,
            params: param
          })
        })
      })
    } else {
      params.forEach(param => {
        request.push({
          id: user.current.value[0],
          start: dateinterval.start,
          end: dateinterval.end,
          pts: pts,
          params: param
        })
      })
    }
    return request
  }

  const parseResults = res => {
    const mode = user.view_params.mode
    const unit = user.view_params.unit
    const params = user.view_params.params
    const singleSets = []
    const defaultOutput = [sumDataset(res, mode, params)]
    if (user.current.value.length > 1) {
      user.profile.vcs.forEach((vc, index) => {
        const singleDataSet = res
          .slice(index * params.length, params.length * (index + 1))
          .filter(set => Object.keys(set).length > 0)
        if (singleDataSet.length > 0) {
          singleSets.push({
            mode: mode,
            unit: unit,
            value: vc.value,
            label: vc.label,
            ...sumDataset(singleDataSet, mode, params)
          })
        }
      })
      return [...defaultOutput, ...singleSets]
    } else {
      return defaultOutput
    }
  }

  const dataToCsv = dataset => {
    const data = []
    dataset.forEach((set, index) => {
      const firstLine = [
        index === 0
          ? `${user.current.name}_${user.view_params.mode}`
          : `${user.profile.vcs[index - 1].label}_${user.view_params.mode}`
      ]
      set.data.forEach(dataset => {
        firstLine.push(dataset.id)
      })
      data.push(firstLine)
      set.data[0].data.forEach((mes, index) => {
        const line = [moment(mes.x * 1000).format('DD/MM/YY HH:mm:ss')]
        set.data.forEach(set => {
          line.push(set.data[index].y)
        })
        data.push(line)
      })
      data.push([' '])
    })
    let csvContent = 'data:text/csv;charset=utf-8,' + data.map(e => e.join(',')).join('\n')
    return encodeURI(csvContent)
  }

  const handleClick = dataset => {
    ReactGA.event({
      category: 'selection',
      action: `exported`,
      label: user.profile.name,
      nonInteraction: true
    })
    const content = dataToCsv(dataset)
    var link = document.createElement('a')
    link.setAttribute('href', content)
    link.setAttribute(
      'download',
      `${moment(dateinterval.start).format('YYmmDD')}_${moment(dateinterval.end).format(
        'YYmmDD'
      )}_mesures_export.csv`
    )
    document.body.appendChild(link) // Required for FF

    link.click() // This will download the data file named "my_data.csv".
  }
  useEffect(
    () => {
      api.getDatas(...parseReq(user.view_params.params)).then(r => {
        setMeasures(parseResults(r))
        setLoading(false)
        setRefreshing(false)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pts, dateinterval, user.current.value, user.view_params.mode]
  )
  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
      <>
        <TopBar lang={user.view_params.lang} user={user.view_params.lang} variant={'graph'} />
        <Box mx={mobile ? '2%' : '5%'} mb={5}>
          <Box mt={mobile ? -2 : 18} mx={props.mx}>
            <Box mb={1}>
              <Stack direction="row" justifyContent="flex-start" alignItems="center">
                {!mobile && (
                  <IconButton onClick={() => history.goBack()}>
                    <ArrowBackIosNewRoundedIcon
                      style={{ color: '#9a9a9a' }}
                      fontSize={mobile ? 'small' : 'medium'}
                    />
                  </IconButton>
                )}
                <Typography color="primary" variant={mobile ? 'h6' : 'h4'}>
                  {t('vc.detail')} {user.view_params.mode} | {user.current.name}
                </Typography>
              </Stack>
            </Box>
            <Divider />
            <Box mt={2} mb={5}>
              {!refreshing && (
                <Grid container direction="row" justifyContent="flex-start" spacing={mobile ? 1 : 2}>
                  <Grid item>
                    <VCSelector
                      styled={true}
                      setValue={setVc}
                      prop_vc={[{ label: t('vc.appareil'), value: '' }, ...user.profile.vcs]}
                      admin={isAdmin}
                      prop_label={user.current.name}
                      mobile={mobile}
                    />
                  </Grid>
                  <Grid item>
                    <CustomChip
                      admin={false}
                      label={''}
                      choices={isAdmin ? adminParamChoice : paramChoice}
                      setValue={v => setNewMode(v, setRefreshing)}
                      value={user.view_params.mode}
                      mode="readonly"
                    />
                  </Grid>
                  <Grid item>
                    <CustomChip
                      admin={isAdmin}
                      label={t('vc.days')}
                      choices={[1, 7, 15, 30]}
                      setValue={setDates}
                      value={dateinterval}
                      mode="date"
                    />
                  </Grid>
                  <Grid item>
                    <CustomChip
                      admin={isAdmin}
                      label={t('vc.points')}
                      choices={[100, 500, 1000, 3000, 10000]}
                      setValue={setPointNumber}
                      value={pts}
                      mode="default"
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      variant="outlined"
                      color="secondary"
                      label={t('vc.export')}
                      onClick={() => handleClick(measures)}
                    />
                  </Grid>
                  <Grid item>
                    <CustomChip
                      admin={false}
                      choices={isAdmin ? adminParamChoice : paramChoice}
                      setValue={v => handleAddMesure(v)}
                      mode="readonly"
                      label={' '}
                      value={t('vc.add_mes')}
                    />
                  </Grid>
                  {measures.length > 1 && (
                    <Grid item>
                      <Chip
                        variant="outlined"
                        color="secondary"
                        label={t('vc.reint')}
                        onClick={() => setMeasures([measures[0]])}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
              {refreshing && (
                <Box mt={1}>
                  <LinearProgress />
                </Box>
              )}
            </Box>
          </Box>
          <Box mt={mobile ? 6 : 8}>
            {measures.map((m, index) => (
              <Box
                mx={props.mx}
                height={mobile ? 380 : props.height}
                my={3}
                mb={mobile ? 25 : 20}
                key={`graph_${user.view_params.mode}_${m.value}`}
              >
                {measures.length > 1 && (
                  <Box mb={5}>
                    <Typography color="primary" variant={mobile ? 'h6' : 'h5'}>
                      {index === 0
                        ? `${user.current.name} | ${user.view_params.mode}`
                        : `${m.value} | ${m.label}`}
                    </Typography>
                  </Box>
                )}
                <Graph
                  mobile={mobile}
                  measures={m.data}
                  min={(index > 0 ? m.mode : user.view_params.mode) === t('vc.ten') ? m.min : m.min * 0.9}
                  max={(index > 0 ? m.mode : user.view_params.mode) === t('vc.ten') ? m.max : m.max * 1.1}
                  format={props.format}
                  legendOffset={mobile ? 85 + m.data.length * 17 : 110}
                  colors={
                    (index > 0 ? m.mode : user.view_params.mode) === 'Tension'
                      ? ['#9a9a9a', '#a6ce34']
                      : ['#238b57', '#d0f0c0', '#a6ce34', '#9a9a9a']
                  }
                  unit={index > 0 ? m.unit : user.view_params.unit}
                />
                <Box mt={mobile ? 2 : -3} mb={2}>
                  <Typography color="secondary" variant={'body2'} align={'center'}>
                    {t('vc.mes')} {t('vc.from')} {m.data[0].dmin} {t('vc.to')} {m.data[0].dmax} | Min {m.min}{' '}
                    {index > 0 ? m.unit : user.view_params.unit} | Max {m.max}{' '}
                    {index > 0 ? m.unit : user.view_params.unit}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          {isAdmin && !mobile && (
            <Box mx="10%" color="secondary" mt={20}>
              <Typography color="secondary" variant={mobile ? 'h6' : 'h4'}>
                Rapport détaillé
              </Typography>
              <Box mt={2}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                  <Box width={exportlabelW}>
                    <Typography color="primary" variant={'body2'}>
                      Tensions
                    </Typography>
                  </Box>
                  <ButtonGroup>
                    <Button onClick={() => handleSelectedParam('Uin-L1')}>
                      <Typography color={exportParams['Uin-L1'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Uin-L1
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('Uin-L2')}>
                      <Typography color={exportParams['Uin-L2'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Uin-L2
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('Uin-L3')}>
                      <Typography color={exportParams['Uin-L3'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Uin-L3
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('Uout-L1')}>
                      <Typography color={exportParams['Uout-L1'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Uout-L1
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('Uout-L2')}>
                      <Typography color={exportParams['Uout-L2'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Uout-L2
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('Uout-L3')}>
                      <Typography color={exportParams['Uout-L3'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Uout-L3
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('Uin-Moy')}>
                      <Typography color={exportParams['Uin-Moy'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Uin-Moy
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('Uout-Moy')}>
                      <Typography
                        color={exportParams['Uout-Moy'] ? 'primary' : 'secondary'}
                        variant={'body2'}
                      >
                        Uout-Moy
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </Stack>
              </Box>
              <Box mt={2}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                  <Box width={exportlabelW}>
                    <Typography color="primary" variant={'body2'}>
                      Courants
                    </Typography>
                  </Box>
                  <ButtonGroup variant="outlined">
                    <Button onClick={() => handleSelectedParam('I1')}>
                      <Typography color={exportParams['I1'] ? 'primary' : 'secondary'} variant={'body2'}>
                        I1
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('I2')}>
                      <Typography color={exportParams['I2'] ? 'primary' : 'secondary'} variant={'body2'}>
                        I2
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('I3')}>
                      <Typography color={exportParams['I3'] ? 'primary' : 'secondary'} variant={'body2'}>
                        I3
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </Stack>
              </Box>
              <Box mt={2}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                  <Box width={exportlabelW}>
                    <Typography color="primary" variant={'body2'}>
                      Puissances
                    </Typography>
                  </Box>
                  <ButtonGroup variant="outlined">
                    <Button onClick={() => handleSelectedParam('Pa-L1')}>
                      <Typography color={exportParams['Pa-L1'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Pa-L1
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('Pa-L2')}>
                      <Typography color={exportParams['Pa-L2'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Pa-L2
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('Pa-L3')}>
                      <Typography color={exportParams['Pa-L3'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Pa-L3
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('Pr-L1')}>
                      <Typography color={exportParams['Pr-L1'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Pr-L1
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('Pr-L2')}>
                      <Typography color={exportParams['Pr-L2'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Pr-L2
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('Pr-L3')}>
                      <Typography color={exportParams['Pr-L3'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Pr-L3
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('PrTot')}>
                      <Typography color={exportParams['PrTot'] ? 'primary' : 'secondary'} variant={'body2'}>
                        PrTot
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('PaTot')}>
                      <Typography color={exportParams['PaTot'] ? 'primary' : 'secondary'} variant={'body2'}>
                        PaTot
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </Stack>
              </Box>
              <Box mt={2}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                  <Box width={exportlabelW}>
                    <Typography color="primary" variant={'body2'}>
                      Energie
                    </Typography>
                  </Box>
                  <ButtonGroup variant="outlined">
                    <Button onClick={() => handleSelectedParam('Etot')}>
                      <Typography color={exportParams['Etot'] ? 'primary' : 'secondary'} variant={'body2'}>
                        Etot
                      </Typography>
                    </Button>
                    <Button onClick={() => handleSelectedParam('EecoTot')}>
                      <Typography color={exportParams['EecoTot'] ? 'primary' : 'secondary'} variant={'body2'}>
                        EecoTot
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </Stack>
              </Box>
              <Box mt={3}>
                <Button variant="outlined" color="secondary" onClick={() => submitExport()}>
                  <Typography color="secondary" variant={'body2'}>
                    Générer rapport
                  </Typography>
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </>
    )
  }
}
