import React, { useEffect } from 'react'
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Grid,
  makeStyles,
  useMediaQuery
} from '@material-ui/core'
import EcoGauge from '../ecoGauge/EcoGauge'
import TopBar from '../appBar/AppBar'
import api from '../api/api'
import Overview from '../overview/Overview'
import SmartGuard from '../smartGuard/SmartGuard'
import CompanyInfo from '../CompanyInfo/CompanyInfo'
import TotEnergy from '../totEnergy/TotEnergy'
import InstantValueGrid from '../instantValues/InstantValueGrid'
import EquivIcon from '../equivIcon/EquivIcon'
import { useHistory } from 'react-router-dom'
import MiniGraph from '../graphView/MiniGraph'
import { makeIdArray, returnTranslatedMode, sumCurrent, sumDataset } from '../utilities/Utils'
import AssistDialog from '../dialogs/AssistDialog'
import VcDialog from '../dialogs/VcDialog'
import AlarmDialog from '../alarms/AlarmDialog'
import apis from '../api/api'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import i18next from '../utilities/i18n'
import CustomChip from '../graphView/CustomChip'
import Typography from '@material-ui/core/Typography'
import { Stack } from '@mui/material'
import MutedDialog from '../alarms/MutedDialog'
import AdminOverview from '../overview/AdminOverview'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAlertList,
  setCurrentParams,
  setMobile,
  setOverviewList,
  setUserProfile
} from '../store/userSlice'
import { setCurrentVC } from '../store/userSlice'
import { setNewMode } from '../store/stateChangers'
import { parseAlertList } from '../alarms/alarmUtils'
import { theme } from '../index'
import AdminSmartGuard from '../smartGuard/AdminSmartGuard'

const useStyles = makeStyles({
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  }
})

//1kWh = 128g CO2
export const co2rate = 0.128
//2190kW/an
export const kwRate = 2190

export default function Dashboard() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const storeUser = useSelector(state => state)

  const [profile, setProfile] = React.useState({})
  const [measures, setMeasures] = React.useState([])
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [openAssist, setAssistDlg] = React.useState(false)
  const [openMuted, setOpenMuted] = React.useState(false)
  const [openVc, setVcDlg] = React.useState(false)
  const [openAlarm, setAlarmDlg] = React.useState(false)
  const [session, setSession] = React.useState()
  const [refreshed, setRefreshed] = React.useState()
  const [vcDetails, setVCDetails] = React.useState()
  const [vcLabel, setLabel] = React.useState()
  const [clientBench, setClientBench] = React.useState()
  const [alarm, setAlarm] = React.useState()
  const [hiddenVcs, setHiddenVcS] = React.useState([])

  const history = useHistory()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleLngChg = lang => {
    setLoading(true)
    i18next.changeLanguage(lang.id).then(() => {
      dispatch(setCurrentParams({ mode: t(returnTranslatedMode(storeUser.view_params.unit)), lang: lang }))
      if (/(Alle Geräte|All devices|Tous les appareils)$/i.test(storeUser.current.name)) {
        setLabel(t('vc.appareil'))
        dispatch(setCurrentVC({ name: t('vc.appareil'), value: storeUser.current.value }))
      }
    })
    ReactGA.event({
      category: 'selection',
      action: `changed_language_to_${lang.id}`,
      label: profile.name,
      nonInteraction: true
    })
  }

  const handleGraphClick = () => {
    ReactGA.event({
      category: 'usage',
      action: 'Displayed graph',
      label: profile.name,
      nonInteraction: true
    })
    ReactGA.send({ hitType: 'pageview', page: '/graph' })
    history.push('/graph')
  }

  const handleIotClick = () => {
    ReactGA.event({
      category: 'usage',
      action: 'Displayed iot',
      label: profile.name,
      nonInteraction: true
    })
    ReactGA.send({ hitType: 'pageview', page: '/iot' })

    history.push('/iotdash', {
      vc: refreshed ? profile.current : makeIdArray(profile.vcs),
      iot: profile.iot,
      label: vcLabel,
      user: profile
    })
  }

  const handleVCchange = change => {
    if (storeUser.vc_list.filter(vc => vc.vc_id === parseInt(change.value)).length || change.value === '') {
      const subval = change.value === '' ? makeIdArray(profile.vcs) : [change.value]
      if (!refreshed || subval.toString() !== profile.current.toString()) {
        const currentProfile = { ...profile, current: subval }
        dispatch(setCurrentVC({ name: change.label, value: subval }))
        setProfile(currentProfile)
        setLabel(`${change.label}`)
        setRefreshed(change)
        setLoading(true)
      }
      ReactGA.event({
        category: 'selection',
        action: `selected_${change.label}`,
        label: profile.name,
        nonInteraction: true
      })
    }
  }

  const parseDataReq = (vcs, latest) => {
    const req = []
    const createReq = vc => {
      storeUser.view_params.params.forEach(p => {
        req.push({
          id: vc,
          start: new Date(latest).setDate(new Date(latest).getDate() - 7),
          end: new Date(),
          pts: 100,
          params: p
        })
      })
    }
    if (vcs.length > 1) {
      vcs.forEach(vc => {
        createReq(vc)
      })
    } else {
      createReq(vcs[0])
    }
    return req
  }

  useEffect(() => {
    const refresh = () => {
      dispatch(setMobile(mobile))
      const token = localStorage.getItem('token')
      if (token) {
        apis.tokenCheck().then(r => {
          const user = r.data.user
          dispatch(setAlertList({ token_hiddenList: user.hidden_ids ? user.hidden_ids : [] }))
          setHiddenVcS(user.hidden_ids ? user.hidden_ids : [])
          if (!storeUser.view_params.lang) {
            handleLngChg(
              r.data.user.language
                ? {
                    name: r.data.user.language,
                    id: r.data.user.language.toLowerCase()
                  }
                : { name: 'FR', id: 'fr' }
            )
          }
          setSession(r.data)
          if (!r.data.isLoggedIn) {
            history.push('/')
          }
          const viewList = []
          api.getProfile(r.data.user.id).then(r => {
            if (Object.keys(profile).length === 0) {
              dispatch(setUserProfile(r.data))
              setProfile(r.data)
              if (!refreshed) {
                dispatch(
                  setCurrentVC({
                    name: t('vc.appareil'),
                    value: r.data.client_id === 111 ? [makeIdArray(user.vcs)[0]] : makeIdArray(user.vcs)
                  })
                )
              }
              setLabel(storeUser.current ? storeUser.current.name : t('vc.appareil'))
            }
            const allcurrent = []
            const ids = storeUser.current ? storeUser.current.value : makeIdArray(user.vcs)
            api.getCurrent(r.data.client_id === 111 ? [ids[0]] : ids).then(rs => {
              rs.forEach(r => {
                if (r.time) {
                  const allMes = r
                  allMes.ecoPercent = Number(r.EecoTot / r.Etot).toFixed(3)
                  allMes.pATot = Number(r['Pa-L1'] + r['Pa-L2'] + r['Pa-L3']).toFixed(0)
                  allMes.uInTot = Number((r['Uin-L1'] + r['Uin-L2'] + r['Uin-L3']) / 3).toFixed(1)
                  allMes.uOutTot = Number((r['Uout-L1'] + r['Uout-L2'] + r['Uout-L3']) / 3).toFixed(1)
                  allMes.online =
                    Number((Date.now() - new Date(r.time).getTime()) / 1000 / 60).toFixed(0) < 20
                  allcurrent.push(allMes)
                }
              })
              if (allcurrent.length >= 2) {
                const sumMes = sumCurrent(allcurrent)
                setMeasures(sumMes)
                setClientBench(Number(sumMes.ecoPercent * 100).toFixed(1))
                if (!user.isAdmin && ids.toString() === makeIdArray(user.vcs).toString()) {
                  dispatch(setOverviewList(allcurrent))
                } else {
                  setVCDetails(allcurrent[0])
                }
              } else {
                setMeasures(allcurrent[0])
                setVCDetails(allcurrent[0])
                if (!user.isAdmin && ids.toString() === makeIdArray(user.vcs).toString())
                  dispatch(setOverviewList([allcurrent[0]]))
              }
              dispatch(setCurrentParams({ time: allcurrent[0].time }))
              api.getDatas(...parseDataReq(ids, allcurrent[0].time)).then(r => {
                setData(sumDataset(r, storeUser.view_params.mode, storeUser.view_params.params))
                if (user.isAdmin) {
                  apis.getAllCurrent().then(r => {
                    r.data.forEach(vc => {
                      vc.ecopercent = vc.EecoTot ? Number((vc.EecoTot / vc.Etot) * 100).toFixed(2) : 'nc'
                      viewList.push({
                        ...vc,
                        online: Number((Date.now() - new Date(vc.time).getTime()) / 1000 / 60).toFixed(0) < 20
                      })
                    })
                    dispatch(setOverviewList(viewList))
                    parseAlertList()
                    setLoading(false)
                  })
                } else {
                  parseAlertList()
                  setLoading(false)
                }
              })
            })
          })
        })
      } else {
        history.push('/')
      }
    }
    ReactGA.event({
      category: 'usage',
      action: 'Displayed Dashboard',
      label: profile.name,
      nonInteraction: true
    })
    refresh()
    const interval = setInterval(() => {
      refresh()
    }, 60000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshed, storeUser.view_params.mode, mobile, storeUser.view_params.lang])

  function LeftPart() {
    return (
      <Grid item xs={mobile ? 12 : 3}>
        <Grid container spacing={1} direction="column">
          {session.user.isAdmin ? (
            <>
              <TotEnergy
                etot={measures.Etot ? measures.Etot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") : 0}
                eEco={
                  measures.EecoTot ? measures.EecoTot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") : 0
                }
                rawEtot={measures.Etot}
                rawEecoTot={measures.EecoTot}
                bypassTime={measures.bypass_time ? measures.bypass_time : 0}
                baseLineEtot={measures.baselineEeco ? measures.baselineEeco : 0}
                baselineSavedE={measures.baselineSavedE ? measures.baselineSavedE : 0}
                dt={measures.installed}
                admin={session.user.isAdmin}
                mobile={mobile}
              />
              <AdminOverview vc={vcDetails} vcs={profile.vcs} handlechange={handleVCchange} />
            </>
          ) : (
            <>
              <Grid item xs>
                <Box mt={mobile ? 6 : 0}>
                  <CompanyInfo profile={profile} />
                </Box>
              </Grid>
              <Grid item xs>
                <Overview vcs={profile.vcs} handlechange={handleVCchange} />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    )
  }

  function CenterPart() {
    return (
      <Grid item xs={session.user.isAdmin ? (mobile ? 12 : 7) : mobile ? 12 : 5}>
        <Box mr={session.user.isAdmin ? '10%' : mobile ? '10%' : '0%'}>
          <Grid container spacing={1} direction="column">
            <Grid item xs>
              <Box mx="2%" mt="2%" mb={3}>
                <EcoGauge
                  value={measures.ecoPercent}
                  variant={mobile ? 'small' : 'standard'}
                  vc={vcLabel}
                  mobile={mobile}
                />
              </Box>
              <EquivIcon
                trees={Number((measures['EecoTot'] * co2rate) / 1000)
                  .toFixed(0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
                kws={Number(measures['EecoTot'] / kwRate)
                  .toFixed(0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
                benchmark={Number(measures.benchmark).toFixed(0)}
                clientBench={clientBench}
                sumState={vcLabel}
                sector={measures.sector}
                client={session.user.id}
                mobile={mobile}
              />
            </Grid>
            <Grid item xs>
              <InstantValueGrid
                content={[
                  {
                    label: mobile ? `${t('vc.uin')} [V]` : t('vc.uin'),
                    value: `${Number(measures.uInTot).toFixed(mobile ? 0 : 1)} ${mobile ? '' : ' V'}`
                  },
                  {
                    label: mobile ? `${t('vc.uout')} [V]` : t('vc.uout'),
                    value: `${Number(measures.uOutTot).toFixed(mobile ? 0 : 1)} ${mobile ? '' : ' V'}`
                  },
                  {
                    label: mobile ? `${t('vc.utot')} [kWh]` : t('vc.utot'),
                    value: `${
                      Number(measures.pATot).toFixed(0) <= 0
                        ? Number(measures.pATot).toFixed(1)
                        : Number(measures.pATot).toFixed(0)
                    } ${mobile ? '' : '  kWh'}`
                  }
                ]}
                mobile={mobile}
              />
            </Grid>
            <Grid item xs>
              <Box mt={-7}>
                <Card className={classes.card}>
                  <Box mt={1} ml={1}>
                    <CustomChip
                      admin={false}
                      label={''}
                      choices={[t('vc.pwr'), t('vc.cur'), t('vc.ten')]}
                      setValue={v => setNewMode(v, setLoading)}
                      value={storeUser.view_params.mode}
                      mode="readonly"
                    />
                  </Box>
                  <CardActionArea onClick={handleGraphClick}>
                    <Box ml={1} mt={2}>
                      <MiniGraph dataset={data} unit={storeUser.view_params.unit} mobile={mobile} />
                    </Box>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    )
  }

  function RightPart() {
    return (
      <Grid item xs={session.user.isAdmin ? (mobile ? 12 : 7) : mobile ? 12 : 3}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <TotEnergy
              etot={measures.Etot ? measures.Etot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") : 0}
              eEco={measures.EecoTot ? measures.EecoTot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") : 0}
              rawEtot={measures.Etot}
              rawEecoTot={measures.EecoTot}
              bypassTime={measures.bypass_time ? measures.bypass_time : 1.02}
              baseLineEtot={measures.baselineEeco ? measures.baselineEeco : 0}
              baselineSavedE={measures.baselineSavedE ? measures.baselineSavedE : 0}
              dt={measures.installed}
              admin={session.user.isAdmin}
              mobile={mobile}
            />
          </Grid>
          <Grid item xs>
            <Box mr={mobile ? '10%' : 0}>
              <SmartGuard
                openAssist={setAssistDlg}
                openVc={setVcDlg}
                openAlarm={setAlarmDlg}
                vcs={profile.vcs}
                admin={session.user.isAdmin}
                setAlarm={setAlarm}
                openMuted={setOpenMuted}
                hiddenVcs={hiddenVcs}
              />
            </Box>
          </Grid>
          {session.user.isAdmin && (
            <Stack direction="row" justifyContent="center">
              <Button variant="outlined" color="secondary" onClick={handleIotClick}>
                <Typography variant="body2">Dashboard IoT</Typography>
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
    )
  }

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else if (mobile) {
    return (
      <>
        <TopBar
          admin={session.user.isAdmin}
          user={{ name: profile.name, icon: profile.icon }}
          handlechange={handleVCchange}
          vc={[{ label: t('vc.appareil'), value: '' }, ...profile.vcs]}
          label={vcLabel}
          variant={'choice'}
          handleLngChg={handleLngChg}
        />
        <Box ml={'10%'} mb={5}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            <CenterPart />
            <Box mr={'11%'}>
              <LeftPart />
            </Box>
            {!session.user.isAdmin && <RightPart />}
            {session.user.isAdmin && (
              <Grid item xs={12}>
                <Box mt={5} mr={'10%'}>
                  <AdminSmartGuard
                    openAssist={setAssistDlg}
                    openVc={setVcDlg}
                    openAlarm={setAlarmDlg}
                    vcs={profile.vcs}
                    setAlarm={setAlarm}
                    openMuted={setOpenMuted}
                    hiddenVcs={hiddenVcs}
                    mobile={mobile}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <AssistDialog open={openAssist} handleClose={() => setAssistDlg(false)} />
        <MutedDialog
          open={openMuted}
          handleClose={() => setOpenMuted(false)}
          admin={session.user.isAdmin}
          user={session.user}
          openAssist={() => setAssistDlg(true)}
        />
        <VcDialog
          open={openVc}
          handleClose={() => setVcDlg(false)}
          vc_list={storeUser.vc_list}
          admin={session.user.isAdmin}
        />
        <AlarmDialog
          open={openAlarm}
          handleClose={() => setAlarmDlg(false)}
          openAssist={() => setAssistDlg(true)}
          alarm={alarm}
          setHidden={setHiddenVcS}
          hiddenVcs={hiddenVcs}
          user={session.user}
          admin={session.user.isAdmin}
        />
      </>
    )
  } else {
    return (
      <>
        <TopBar
          admin={session.user.isAdmin}
          user={{ name: profile.name, icon: profile.icon }}
          handlechange={handleVCchange}
          vc={[{ label: t('vc.appareil'), value: '' }, ...profile.vcs]}
          label={vcLabel}
          variant={'choice'}
          handleLngChg={handleLngChg}
        />
        <Box>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignevents="flex-start"
            minwidth={500}
            spacing={session.user.isAdmin ? 1 : 5}
          >
            <LeftPart />
            <CenterPart />
            {!session.user.isAdmin && <RightPart />}
          </Grid>
          {session.user.isAdmin && (
            <Box mt={5} mr={'10%'} ml={'4.5%'}>
              <AdminSmartGuard
                openAssist={setAssistDlg}
                openVc={setVcDlg}
                openAlarm={setAlarmDlg}
                vcs={profile.vcs}
                admin={session.user.isAdmin}
                setAlarm={setAlarm}
                openMuted={setOpenMuted}
                hiddenVcs={hiddenVcs}
              />
            </Box>
          )}
        </Box>
        <AssistDialog open={openAssist} handleClose={() => setAssistDlg(false)} />
        <MutedDialog
          open={openMuted}
          handleClose={() => setOpenMuted(false)}
          admin={session.user.isAdmin}
          user={session.user}
          openAssist={() => setAssistDlg(true)}
        />
        <VcDialog
          open={openVc}
          handleClose={() => setVcDlg(false)}
          vc_list={storeUser.vc_list}
          admin={session.user.isAdmin}
          email={session.user.email}
        />
        <AlarmDialog
          open={openAlarm}
          handleClose={() => setAlarmDlg(false)}
          openAssist={() => setAssistDlg(true)}
          alarm={alarm}
          setHidden={setHiddenVcS}
          hiddenVcs={hiddenVcs}
          user={session.user}
          admin={session.user.isAdmin}
        />
      </>
    )
  }
}
