import * as React from 'react'
import apis from '../api/api'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card } from '@mui/material'
import { Box, Typography } from '@material-ui/core'
import voltplus from '../assets/vpluslogo.png'

const useStyles = makeStyles({
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  card: {
    borderRadius: 40,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  }
})

export default function TestMiddleComp() {
  const classes = useStyles()
  const history = useHistory()
  useEffect(() => {
    if (localStorage.getItem('token')) {
      history.push('/dash')
    }
    apis.testLogin().then(async r => {
      if (r.data.token) {
        localStorage.setItem('token', r.data.token)
        window.location.reload()
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Box m={'10%'}>
      <Card className={classes.card}>
        <Box m={5}>
          <img src={voltplus} alt="voltpluslog" height="60px" />
          <Box mt={2}>
            <Typography color="secondary" variant={'h6'}>
              Chargement du dashboard...
            </Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}
