import axios from 'axios'
import { baseUrl } from './api_config'

const calc_api = axios.create({
  baseURL: baseUrl,
  responseType: 'blob'
})

const makeCalc = data => {
  return calc_api.post(`/calc`, { data: data })
}

const service_apis = { makeCalc }

export default service_apis
