import Box from '@material-ui/core/Box'
import { Avatar, Button, Card, Divider, Switch } from '@material-ui/core'
import { Stack } from '@mui/material'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import green from '../../assets/gSquare.png'
import red from '../../assets/rSquare.png'
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded'
import BuildRoundedIcon from '@mui/icons-material/BuildRounded'
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded'
import moment from 'moment'
import { EditVCSModal } from './EditVCSModal'
import { isVCSOnline } from '../../utilities/Utils'
import { useHistory } from 'react-router-dom'
import apis from '../../api/api'

const useStyles = makeStyles({
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  avatar: {
    width: 12,
    height: 12
  }
})

export function VCdisplay({ initial_vc }) {
  const classes = useStyles()
  const [vc, setVc] = useState(initial_vc)
  const [openEditModal, setEditModal] = useState(false)
  const history = useHistory()

  const handleToggleSwitchPref = (vc, key) => {
    const state = !vc[key]
    apis.toggleVCSwitchPref(vc.vc_id, state, key).then(r => {
      if (r.status === 202) {
        const vcUpdate = { ...vc }
        vcUpdate[key] = state
        setVc(vcUpdate)
      }
    })
  }

  return (
    <>
      <Box mt={2}>
        <Card className={classes.card}>
          <Box mx={3} my={3}>
            <Stack spacing={1} justifyContent="space-between" direction="row" alignItems="center">
              <Box mb={1}>
                <Typography color="primary" variant={'h6'}>
                  {vc.name} | {vc.client}
                </Typography>
                <Box mb={0.75}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={isVCSOnline(vc.time) ? green : red} className={classes.avatar} />
                    <Typography color="secondary">
                      {isVCSOnline(vc.time) ? 'En ligne' : 'Hors ligne'}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
              <Box mb={0.75}>
                <Stack direction="row" spacing={2}>
                  <Button
                    color="secondary"
                    variant={'outlined'}
                    onClick={() =>
                      history.push('/dashadmin/maintenance', { vc: vc.vc_id, name: vc.name, latest: vc.time })
                    }
                  >
                    <EngineeringRoundedIcon style={{ color: 'secondary' }} />
                    <Box ml={1.5}>
                      <Typography color="secondary">Maintenance</Typography>
                    </Box>
                  </Button>
                  <Button
                    color="secondary"
                    variant={'outlined'}
                    onClick={() => history.push('/dashadmin/vcparams', { vc: vc.vc_id, name: vc.name })}
                  >
                    <BuildRoundedIcon style={{ color: 'secondary' }} />
                    <Box ml={1.5}>
                      <Typography color="secondary">Paramètres</Typography>
                    </Box>
                  </Button>
                  <Button color="secondary" variant={'outlined'} onClick={() => setEditModal(true)}>
                    <ModeEditOutlineRoundedIcon style={{ color: 'secondary' }} />
                    <Box ml={1.5}>
                      <Typography color="secondary">Modifier</Typography>
                    </Box>
                  </Button>
                </Stack>
              </Box>
            </Stack>
            <Divider />
            <Stack>
              <Box mt={2}>
                <Stack direction="row" spacing={4}>
                  <Stack direction="row" spacing={1}>
                    <Typography color="secondary" style={{ fontWeight: 600 }}>
                      Modèle :
                    </Typography>
                    <Typography color="secondary">{vc.model}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="primary" noWrap style={{ fontWeight: 600 }}>
                      ID :
                    </Typography>
                    <Typography color="primary" noWrap>
                      {vc.vc_id}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="secondary" noWrap style={{ fontWeight: 600 }}>
                      Dernière mesure:
                    </Typography>
                    <Typography color="secondary" noWrap>
                      {vc.time ? moment(vc.time).format('DD/MM/YY HH:mm') : 'nc'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="secondary" style={{ fontWeight: 600 }}>
                      Eco sect:
                    </Typography>
                    <Typography color="secondary">{vc.benchmark ? vc.benchmark : 'nc'}%</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="secondary" style={{ fontWeight: 600 }}>
                      Perf. Vc
                    </Typography>
                    <Typography color="secondary">
                      {vc.EecoTot ? Number((vc.EecoTot / vc.Etot) * 100 - vc.benchmark).toFixed(2) : 'nc'}%
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Box mt={2}>
                <Typography color="primary" style={{ fontWeight: 600 }}>
                  Informations VC
                </Typography>
                <Box mt={1}>
                  <Stack direction="row" spacing={4} alignItems="left" justifyContent="space-between">
                    <Typography color="secondary" align={'left'}>
                      Secteur: {vc.sector ? vc.sector : 'na'}
                    </Typography>
                    <Typography color="secondary" align={'left'}>
                      Bypass: {vc.bypass ? vc.bypass : 'non'}
                    </Typography>
                    <Typography color="secondary" align={'left'}>
                      Jours de bypass: {vc.bypass_time ? Number(vc.bypass_time / 86400000).toFixed(1) : '\n0'}
                    </Typography>
                    <Typography color="secondary" align={'left'}>
                      Uptime:{' '}
                      {vc.bypass_time
                        ? Number((1 - vc.bypass_time / (new Date() - new Date(vc.installed))) * 100).toFixed(
                            1
                          )
                        : '100'}
                      %
                    </Typography>
                    <Typography color="secondary" align={'left'}>
                      Date d'installation : {vc.installed ? moment(vc.installed).format('DD/MM/YY') : 'nc'}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Stack>
            <Box mt={3} ml={-1}>
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Stack direction={'row'} alignItems={'center'}>
                  <Switch
                    checked={vc.migrated ? vc.migrated : false}
                    onChange={() => {
                      handleToggleSwitchPref(vc, 'migrated')
                    }}
                    color="primary"
                    inputProps={{ 'aria-label': 'Logs machine' }}
                  />
                  <Typography variant={'body2'} color={'secondary'}>
                    Connecté au VoltCloud
                  </Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'}>
                  <Switch
                    checked={vc.monitored ? vc.monitored : false}
                    onChange={() => {
                      handleToggleSwitchPref(vc, 'monitored')
                    }}
                    color="primary"
                    inputProps={{ 'aria-label': 'Monitoring' }}
                  />
                  <Typography variant={'body2'} color={'secondary'}>
                    Monitoring des alarmes & logs
                  </Typography>
                </Stack>
              </Stack>{' '}
            </Box>
          </Box>
        </Card>
      </Box>
      <EditVCSModal
        open={openEditModal}
        vc={vc}
        handleClose={() => setEditModal(false)}
        reload={() => window.location.reload()}
      />
    </>
  )
}
