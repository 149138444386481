import * as React from 'react'
import { Box, Card, CardActionArea, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import EcoGauge from '../ecoGauge/EcoGauge'
import { Divider, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { returnAltLabel } from '../utilities/Utils'

const useStyles = makeStyles({
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  cardT: {
    borderRadius: 19,
    border: '0.25px solid #FFFFFF',
    boxShadow: 'none'
  }
})

export default function Overview(props) {
  const userVCList = useSelector(state => state.vc_list)
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Card className={classes.card}>
      <Box mx="5%" mt="3%">
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Typography color="secondary">{t('vc.ap_appareil')}</Typography>

          <Typography color="primary">{userVCList.length} VoltControl</Typography>
        </Stack>
      </Box>
      <Box mt={2} minHeight={744}>
        {userVCList.map((vc, index) => (
          <Card className={classes.cardT} key={`overview_${vc}_${index}`}>
            <CardActionArea
              onClick={() =>
                props.handlechange({
                  value: vc.vc_id,
                  label: returnAltLabel(props.vcs, vc)
                })
              }
            >
              <Box mx="5%" mt={5} height={200} mb={5}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Typography color="secondary">{returnAltLabel(props.vcs, vc)}</Typography>
                  <Typography color="secondary">ID: {vc.vc_id}</Typography>
                </Stack>
                <Divider />
                <Box mt={2}>
                  <EcoGauge value={vc.ecoPercent} variant={'small'} />
                </Box>
              </Box>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Card>
  )
}
