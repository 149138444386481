export const readModel = model => {
  if (model) {
    return model.substring(4, 7)
  }
}

export const readHwError = (code, model) => {
  switch (code) {
    case 1:
      switch (model) {
        case 80:
        case 125:
        case 250:
        case 400:
        case 630:
        case 830:
          return 'RTC NF'
        default:
          return 'RTC ne fonctionne pas'
      }
    case 2:
      switch (model) {
        case 80:
        case 125:
        case 250:
        case 400:
        case 630:
        case 830:
          return 'Température HW'
        default:
          return 'Température ne fonctionne pas (pas exploité)'
      }
    case 4:
      switch (model) {
        case 80:
        case 125:
        case 250:
        case 400:
        case 630:
        case 830:
          return 'Manque Tension HW'
        default:
          return 'Alimentation de la carte en dessous de 10V'
      }
    case 8:
      switch (model) {
        case 80:
        case 125:
        case 250:
        case 400:
        case 630:
        case 830:
          return 'Heure HW'
        default:
          return "Pas d'heure"
      }
    case 10:
      switch (model) {
        case 80:
        case 125:
        case 250:
        case 400:
        case 630:
        case 830:
          return 'Mém FLASH SPI NR'
        default:
          return 'Mém FLASH SPI non reconnue'
      }
    default:
      return 'no alarm'
  }
}

export const readAlarm = (code, model) => {
  switch (code) {
    case 1:
      switch (model) {
        case 80:
        case 125:
        case 250:
          return 'Q1 déclenché'
        case 400:
        case 630:
        case 830:
          return 'Q1 ou Q2 déclenché'
        default:
          return 'Q1'
      }
    case 2:
      switch (model) {
        case 80:
        case 125:
        case 250:
          return 'Q2 déclenché'
        case 400:
        case 630:
        case 830:
          return 'F2 déclenché'
        default:
          return 'Q2'
      }
    case 4:
      switch (model) {
        case 80:
        case 125:
        case 250:
        case 400:
        case 630:
        case 830:
          return 'Température armoire 50°'
        default:
          return 'B1'
      }
    case 8:
      switch (model) {
        case 80:
        case 125:
        case 250:
        case 400:
        case 630:
        case 830:
          return 'Température transfo 130°'
        default:
          return 'S130'
      }
    case 10:
      return 'OverCurrent'
    case 16:
      return 'Surcharge courant'

    case 20:
      return 'Ecart Tension entrée-sortie > Uout max delta'
    case 32:
      return 'Manque Uout'
    case 40:
    case 64:
      return 'Erreur hardware'
    case 128:
      return 'ACP Module DVL dépassement'
    case 216:
      return 'ACE Module DVL dépassement'
    default:
      return 'no alarm'
  }
}

export const readBypass = (code, model) => {
  switch (code) {
    case 1:
      switch (model) {
        case 80:
        case 125:
        case 250:
        case 400:
        case 630:
        case 830:
          return 'Température transfo 60°'
        default:
          return 'S60 ou S2'
      }
    case 2:
      switch (model) {
        case 80:
        case 125:
        case 250:
        case 400:
        case 630:
        case 830:
          return 'UIn trop bas'
        default:
          return 'Uin trop bas'
      }
    case 4:
      switch (model) {
        case 80:
        case 125:
        case 250:
        case 400:
        case 630:
        case 830:
          return 'Uin < consigne'
        default:
          return 'Uin en dessous de la référence'
      }
    case 8:
      switch (model) {
        case 80:
        case 125:
        case 250:
        case 400:
        case 630:
        case 830:
          return 'Surcourant'
        default:
          return 'Surcourant'
      }
    case 10:
      switch (model) {
        case 80:
        case 125:
        case 250:
        case 400:
        case 630:
        case 830:
          return '630'
        default:
          return 'Delta courant détecté'
      }
    default:
      return 'no alarm'
  }
}
