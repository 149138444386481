import Box from "@material-ui/core/Box";
import {Card, Divider} from "@material-ui/core";
import {CardActionArea, Stack} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import {LatestmesDisplay} from "../../utilities/LatestMesDisplay";


const useStyles = makeStyles({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none',
  },
  avatar: {
    width: 12,
    height: 12
  },
});


export function LatestAdminMes({vc}) {
  const classes = useStyles();

  return (
    <>
      <Box mt={2}>
        <Card className={classes.card}>
          <CardActionArea >
            <Box mx={3} my={3}>
              <Stack spacing={1} alignItems="right" justifyContent="space-between">
                <Box mb={1}>
                  <Typography color="primary" variant={"h6"}>
                  Dernières mesures
                  </Typography>
                  <Box mb={0.75}>
                    <Typography color="secondary" variant='body2'>
                      {vc.time?moment(vc.time).format("DD/MM/YY HH:mm"):"nc"}
                    </Typography>
                  </Box>
                  <Divider/>
                  <LatestmesDisplay vc={vc}/>
                </Box>
              </Stack>
            </Box>
          </CardActionArea>
        </Card>
      </Box>
    </>
  )
}
