import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Avatar from '@material-ui/core/Avatar'
import { Chip, useMediaQuery, useTheme } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(4),
      height: theme.spacing(4)
    }
  }
}))

function UserMenu({ user, variant }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()
  const onMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    handleClose()
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
    localStorage.removeItem('persist:root')
    sessionStorage.removeItem('persist:root')
    history.push('/')
  }

  return (
    <span style={{ paddingRight: '25px' }}>
      {(!onMobile || variant === 'graph') && (
        <Chip
          variant="outlined"
          color="primary"
          avatar={<Avatar src={user.icon} className={classes.avatar} />}
          label={user.name}
          onClick={handleMenu}
        />
      )}
      {onMobile && variant !== 'graph' && (
        <Avatar src={user.icon} className={classes.avatar} onClick={handleMenu} />
      )}

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => history.push('/')}>
          <Typography color="primary">{user.name}</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Typography color={'secondary'}>Logout</Typography>
        </MenuItem>
      </Menu>
    </span>
  )
}

export default UserMenu
