import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 260

export const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    color: '#fffffff'
  },
  card: {
    borderRadius: 28,
    border: '0.25px solid #44fb8c',
    boxShadow: 'none'
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      zIndex: theme.zIndex.drawer + 1,
      position: 'top'
    },
    backgroundColor: '#ffffff'
    //borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    color: '#ffffff'
  },
  content: {
    flexGrow: 1,
    background: '#ffffff',
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingRight: '284px' // center content by compensating for drawer menu
    }
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  drawerHeader: {
    width: drawerWidth,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'center'
  },
  userName: {
    padding: theme.spacing(0, 1)
  },
  auth: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  location: {
    alignItems: 'left'
  },
  locationPaper: {
    padding: '2px 4px',
    display: 'flex',
    background: '#ffffff'
  },
  locationInput: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  drawerFooter: {
    position: 'fixed',
    bottom: 0,
    textAlign: 'center',
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(7)
  }
}))

export const formStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  cardT: {
    borderRadius: 19,
    border: '0.25px solid #FFFFFF',
    boxShadow: 'none'
  },
  blurredCard: {
    boxShadow: 'none',
    filter: 'blur(3px)'
  },
  invisibleCard: {
    boxShadow: 'none',
    zIndex: -5
  },
  avatar: {
    width: 100,
    height: 100
  },
  dot_avatar: {
    width: 12,
    height: 12
  }
}))
