export const logInterpretor = str => {
  if (typeof str === 'string') {
    const [hdr, body] = str.split('$', 2)
    const splittedHdr = hdr.split(' ')
    const date = splittedHdr[0]
    const msg = splittedHdr.slice(1).join(' ')
    const hexbody = body.substring(1)
    const mes14b = hexbody.substring(0, 49).match(/.{4}/g)
    const mes2b = hexbody.substring(49, 50)
    const mes24b = hexbody.substring(50, 71).match(/.{4}/g)
    const mes4b = [...mes14b, ...mes24b]
    const decmes = []
    mes4b.forEach(mes => {
      const dec = parseInt(mes, 16)
      decmes.push(dec)
    })
    return {
      date: date,
      message: msg,
      'Uin-L1': decmes[0] / 10,
      'Uin-L2': decmes[1] / 10,
      'Uin-L3': decmes[2] / 10,
      I1: decmes[3] / 10,
      I2: decmes[4] / 10,
      I3: decmes[5] / 10,
      'Pa-L1': decmes[6] / 10,
      'Pa-L2': decmes[7] / 10,
      'Pa-L3': decmes[8] / 10,
      'Uout-L1': decmes[9] / 10,
      'Uout-L2': decmes[10] / 10,
      'Uout-L3': decmes[11] / 10,
      VC_state: parseInt(mes2b, 16),
      hwInfoState: decmes[12],
      hwRelayState: decmes[13],
      vcAlarmCode: decmes[14],
      vcBypassCoee: decmes[15],
      hwFailurecode: decmes[16]
    }
  }
}

export const parseLogDate = logDate => {
  const [date, time] = logDate.split('-')
  const [y, m, d] = date.split('.')
  const [hh, mm, ss] = time.split(':')
  return new Date(`20${y}`, (parseInt(m) - 1).toString(), d, hh, mm, ss)
}
