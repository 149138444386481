import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import { encryptTransform } from 'redux-persist-transform-encrypt'

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    transforms: [
      encryptTransform({
        secretKey: 'oRwLU4f@iwX4NuGCLZT-s',
        onError: function(error) {
          // Handle the error.
        }
      })
    ]
  },
  userReducer
)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})

export const persistor = persistStore(store)
