import Box from '@material-ui/core/Box'
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Divider } from '@material-ui/core'
import { Stack } from '@mui/material'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import apis from '../../api/api'
import { logInterpretor } from '../../utilities/LogInterpreter'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { readAlarm, readBypass, readHwError, readModel } from '../../alarms/VcsAlarms'
import { LatestmesDisplay } from '../../utilities/LatestMesDisplay'
import CustomChip from '../../graphView/CustomChip'
import { formatDataSet } from '../../utilities/Utils'
import { formStyles } from '../../styles/makeStyles'
import { BypassGraph } from '../../graphView/GraphGenerator'

export function LatestLogs({ vc_id, model, latest }) {
  const classes = formStyles()
  const [logs, setLogs] = React.useState([])
  const [bypassData, setBypassData] = React.useState([])
  const [displayNumber, setDisplayNumber] = useState(6)
  const [alarmCount, setAlarmCount] = useState(0)
  const [allDisplayed, setAllDisplayed] = useState(false)

  const [bypassTime, setBypassTime] = React.useState([])

  const initialDate = {
    start: new Date(latest).setDate(new Date(latest).getDate() - 7),
    end: new Date().getTime()
  }

  const [dateinterval, setDateInterval] = React.useState(initialDate)
  const [bypassInterval, setBypassInterval] = React.useState(initialDate)

  const setDates = (start, end) => {
    if (!(end > Date.now())) {
      return {
        start: start.getTime(),
        end: end.getTime()
      }
    }
  }

  const handleBypassDates = (start, end) => {
    setBypassInterval(setDates(start, end))
  }

  const handleLogDates = (start, end) => {
    setDateInterval(setDates(start, end))
  }

  const handleShowMore = () => {
    if (displayNumber + 6 < alarmCount) {
      setDisplayNumber(displayNumber + 6)
    } else {
      setDisplayNumber(alarmCount)
      setAllDisplayed(true)
    }
  }

  useEffect(() => {
    apis.getlogs(vc_id, dateinterval).then(r => {
      const logs = []
      if (r.data.length > 1) {
        setAlarmCount(r.data.length)
        if (r.data.length <= displayNumber) {
          setAllDisplayed(true)
        }
        r.data.forEach((log, index) => {
          if (++index <= displayNumber)
            logs.push({ timestamp: log.timestamp, logtrad: logInterpretor(log.message) })
        })
        setLogs(logs)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateinterval, displayNumber])

  useEffect(() => {
    apis.getBypass(vc_id, bypassInterval).then(r => {
      const formatedData = formatDataSet(r.data, bypassInterval)
      setBypassData(formatedData.data)
      setBypassTime(formatedData.time)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bypassInterval])

  return (
    <>
      <Box mt={2} mb={3}>
        <Box mb={2}>
          <Card className={classes.card}>
            <Box mx={3} my={3} height={bypassData.length > 0 ? 300 : 100}>
              <Box mt={3} mb={5}>
                <Stack direction={'row'} spacing={1} alignItems="center" justifyContent="space-between">
                  <Box>
                    <Stack>
                      <Typography color="primary" variant={'h6'}>
                        Bypass sur la période
                      </Typography>
                      <Typography color={'secondary'} variant={'body'}>
                        Période {`du `}
                        {moment(bypassInterval.start).format('DD/MM/YY HH:mm')} {`au `}
                        {moment(bypassInterval.end).format('DD/MM/YY HH:mm')}
                      </Typography>
                      {bypassData.length ? (
                        <Typography color={'secondary'} variant={'body'}>
                          Bypass sur la période : {bypassTime} h
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Stack>
                  </Box>
                  <CustomChip
                    label={'jours'}
                    choices={[7, 15, 30]}
                    setValue={handleBypassDates}
                    value={bypassInterval}
                    mode="date"
                  />
                </Stack>
                <Box mt={1}>
                  <Divider />
                </Box>
              </Box>
              <BypassGraph data={bypassData} />
            </Box>
          </Card>
        </Box>
        <Card className={classes.card}>
          <Box mx={3} my={3}>
            <Stack spacing={1} alignItems="right" justifyContent="space-between">
              <Box mb={1}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography color="primary" variant={'h6'}>
                    Derniers Logs
                  </Typography>
                  <CustomChip
                    label={'jours'}
                    choices={[7, 15, 30]}
                    setValue={handleLogDates}
                    value={dateinterval}
                    mode="date"
                  />
                </Stack>

                <Box mt={2}>
                  <Divider />
                </Box>
                {logs.length > 0 &&
                  logs.map(log => (
                    <Box mt={2} key={`${log.timestamp}_${log.message}`}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Stack direction="row" spacing={2}>
                            <Typography color="primary" variant="body2" style={{ fontWeight: 600 }}>
                              {moment(log.timestamp).format('DD/MM/YY HH:mm')}
                            </Typography>
                            <Typography color="secondary" variant="body2" style={{ fontWeight: 400 }}>
                              Message : {log.logtrad ? log.logtrad.message : ''}
                            </Typography>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box mt={-2}>
                            <Stack spacing={1} alignItems="left">
                              <Box mt={1} mr={-1}>
                                <Box mt={1}>
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="left"
                                    justifyContent="space-between"
                                  >
                                    <Typography color="secondary" variant={'body2'} align={'left'}>
                                      VC_state: {log.logtrad ? log.logtrad['VC_state'] : 'na'}
                                    </Typography>
                                    <Typography color="secondary" variant={'body2'} align={'left'}>
                                      hwInfoState: {log.logtrad ? log.logtrad['hwInfoState'] : 'na'}
                                    </Typography>
                                    <Typography color="secondary" variant={'body2'} align={'left'}>
                                      hwRelayState: {log.logtrad ? log.logtrad['hwRelayState'] : 'na'}
                                    </Typography>
                                    <Typography color="secondary" variant={'body2'} align={'left'}>
                                      hwFailurecode:{' '}
                                      {log.logtrad
                                        ? `${readHwError(
                                            log.logtrad['hwFailurecode'],
                                            readModel(model)
                                          )} (code: ${log.logtrad['hwFailurecode']})`
                                        : 'na'}
                                    </Typography>
                                    <Typography color="secondary" variant={'body2'} align={'left'}>
                                      vcAlarmCode:{' '}
                                      {log.logtrad
                                        ? `${readAlarm(
                                            log.logtrad['vcAlarmCode'],
                                            readModel(model)
                                          )} (code: ${log.logtrad['vcAlarmCode']})`
                                        : 'na'}
                                    </Typography>
                                    <Typography color="secondary" variant={'body2'} align={'left'}>
                                      vcBypassCode:{' '}
                                      {log.logtrad
                                        ? `${readBypass(
                                            log.logtrad['vcBypassCoee'],
                                            readModel(model)
                                          )} (code: ${log.logtrad['vcBypassCoee']})`
                                        : 'na'}
                                    </Typography>
                                  </Stack>
                                </Box>
                              </Box>
                              <Box>
                                <LatestmesDisplay vc={log.logtrad ? log.logtrad : ''} />
                              </Box>
                            </Stack>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  ))}
              </Box>
            </Stack>
            {!allDisplayed && (
              <Box my={2}>
                <Stack direction="row" justifyContent="center">
                  <Button onClick={handleShowMore} variant="outlined">
                    <Typography variant={'body2'} color="secondary">
                      Afficher plus de logs ({alarmCount - displayNumber})
                    </Typography>
                  </Button>
                </Stack>
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </>
  )
}
