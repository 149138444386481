import * as React from 'react'
import { Avatar, Box, Button, Card, CardActionArea, Grid, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import greenShield from '../assets/shield.png'
import { Divider, Stack } from '@mui/material'
import green from '../assets/gSquare.png'
import red from '../assets/rSquare.png'
import warning from '../assets/warning.png'
import { useTranslation } from 'react-i18next'
import { isVCSOnline, returnAltLabel } from '../utilities/Utils'
import orangeShield from '../assets/orangeShield.png'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'
import moment from 'moment'

const useStyles = makeStyles({
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  cardT: {
    borderRadius: 19,
    border: '0.25px solid #FFFFFF',
    boxShadow: 'none'
  },
  avatar: {
    width: 12,
    height: 12
  }
})

export default function SmartGuard({ openAssist, openVc, openAlarm, vcs, admin, setAlarm, openMuted }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const userVCList = useSelector(state => state.vc_list)
  const user = useSelector(state => state.profile)
  const alarms = useSelector(state => state.alertLists)
  const bypassFlag = alarms.visibleList.bypass
  const offlineFlag = alarms.visibleList.offline
  const bypassList = alarms.hiddenList.bypass
  const offlineList = alarms.hiddenList.offline

  const handleOpenVc = () => {
    ReactGA.event({
      category: 'selection',
      action: `centre de controle ouvert`,
      label: user.name,
      nonInteraction: true
    })
    openVc(true)
  }

  const handleAlarmClk = (vc, mode) => {
    ReactGA.event({
      category: 'selection',
      action: `alarm display_${vc}_${mode}`,
      label: user.name,
      nonInteraction: true
    })
    setAlarm({ vc: vc, mode: mode })
    openAlarm(true)
  }

  return (
    <Card className={classes.card}>
      <Box mx="5%" mt="3%" minHeight={744}>
        <Box mb={2}>
          <Typography color="secondary">{t('vc.cont_center')}</Typography>
        </Box>
        <Card className={classes.cardT}>
          <CardActionArea onClick={() => handleOpenVc()}>
            <Box mt={3} mb={3}>
              <Grid container direction="row" justifyContent="space-around" alignItems="center">
                <img
                  src={bypassFlag || offlineFlag ? orangeShield : greenShield}
                  height="60px"
                  alt="shield"
                />
                <Box>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={bypassFlag ? red : green} className={classes.avatar} />
                    <Typography color="secondary" variant={'body2'}>
                      {t('vc.ecomode')}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={offlineFlag ? red : green} className={classes.avatar} />
                    <Typography color="secondary" variant={'body2'}>
                      Volt+Sync
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={green} className={classes.avatar} />
                    <Typography color="secondary" variant={'body2'}>
                      {t('vc.alarms')}
                    </Typography>
                  </Stack>
                </Box>
                <Box display="flex" alignItems="center"></Box>
              </Grid>
            </Box>
          </CardActionArea>
        </Card>
        <Box mt={5} mb={5}>
          <Divider />
        </Box>
        <Box>
          <Typography color="secondary" variant={'body2'}>
            {t('vc.alarms')}
          </Typography>
        </Box>

        <Box mb={2}>
          {userVCList.map(
            (vc, index) =>
              !bypassList.includes(vc.vc_id) &&
              vc.bypass && (
                <Box mt={2} key={`alarm_${index}_${vc.vc_id}`}>
                  <Card className={classes.card}>
                    <CardActionArea
                      onClick={() =>
                        handleAlarmClk(
                          admin
                            ? vc
                            : {
                                name: returnAltLabel(vcs, vc),
                                vc_id: vc.vc_id,
                                'Uin-L1': vc['Uin-L1'],
                                'Uout-L1': vc['Uout-L1']
                              },
                          'bypass'
                        )
                      }
                    >
                      <Box mx={2} my={1}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <img src={warning} height="30px" alt="warning" />
                          <Stack>
                            <Typography color="secondary" variant={'body2'}>
                              {moment(vc.bypass_date).format('DD/MM/YY HH:mm')} (
                              {Number(
                                (new Date().getTime() - new Date(vc.bypass_date).getTime()) / 86400000
                              ).toFixed(0)}
                              {' j'})
                            </Typography>
                            <Typography color="primary" variant={'body2'}>
                              {admin ? vc.name : returnAltLabel(vcs, vc)}
                            </Typography>
                            <Typography color="secondary" variant={'body2'}>
                              {t('vc.bypass_noeco')}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>
                    </CardActionArea>
                  </Card>
                </Box>
              )
          )}
          {userVCList.map(
            (vc, index) =>
              !offlineList.includes(vc.vc_id) &&
              !isVCSOnline(vc.time) && (
                <Box mt={2} key={`alarm_offline_${index}_${vc.vc_id}`}>
                  <Card className={classes.card}>
                    <CardActionArea
                      onClick={() =>
                        handleAlarmClk(
                          admin ? vc : { name: returnAltLabel(vcs, vc), vc_id: vc.vc_id },
                          'offline'
                        )
                      }
                    >
                      <Box mx={2} my={1}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <img src={warning} height="30px" alt="warning" />
                          <Stack>
                            <Typography color="secondary" variant={'body2'}>
                              {moment(vc.time).format('DD/MM/YY HH:mm')} (
                              {Number(
                                (new Date().getTime() - new Date(vc.time).getTime()) / 86400000
                              ).toFixed(0)}
                              {' j'})
                            </Typography>
                            <Typography color="primary" variant={'body2'}>
                              {admin ? vc.name : returnAltLabel(vcs, vc)}
                            </Typography>
                            <Typography color="secondary" variant={'body2'}>
                              {t('vc.offline')}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>
                    </CardActionArea>
                  </Card>
                </Box>
              )
          )}
        </Box>
      </Box>
      <Stack direction="row" justifyContent="center">
        <Box mb={1}>
          <Button variant="outlined" onClick={() => openAssist(true)}>
            <Typography variant="body2" color="secondary">
              {t('vc.assist')}
            </Typography>
          </Button>
        </Box>
      </Stack>
    </Card>
  )
}
