import * as React from 'react'
import { Avatar, Box, Card, CardActionArea } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import greenShield from '../assets/shield.png'
import { Divider, Stack } from '@mui/material'
import green from '../assets/gSquare.png'
import red from '../assets/rSquare.png'
import { useTranslation } from 'react-i18next'
import orangeShield from '../assets/orangeShield.png'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'
import { AutoBypassAlarmTile } from '../alarms/AutoBypassAlarmTile'
import { AutoDisconnectedAlarmTile } from '../alarms/AutoDisconnectedAlarmTile'
import { LogAlarmTile } from '../alarms/LogAlarmTile'
import { formStyles } from '../styles/makeStyles'

export default function AdminSmartGuard({ openVc, openAlarm, vcs, mobile, setAlarm, openMuted }) {
  const classes = formStyles()
  const { t } = useTranslation()
  const user = useSelector(state => state.profile)
  const alarms = useSelector(state => state.alertLists)
  const bypassFlag = alarms.visibleList.bypass
  const offlineFlag = alarms.visibleList.offline
  const offlineList = alarms.hiddenList.offline

  const handleOpenVc = () => {
    ReactGA.event({
      category: 'selection',
      action: `centre de controle ouvert`,
      label: user.name,
      nonInteraction: true
    })
    openVc(true)
  }

  const handleAlarmClk = (vc, mode) => {
    ReactGA.event({
      category: 'selection',
      action: `alarm display_${vc}_${mode}`,
      label: user.name,
      nonInteraction: true
    })
    setAlarm({ vc: vc, mode: mode })
    openAlarm(true)
  }

  return (
    <Card className={classes.card}>
      <Box mx="2%" mt="3%" minHeight={744}>
        <Box mb={2} mt={mobile ? 2 : 0} ml={mobile ? 2 : 0}>
          <Typography color="secondary">{t('vc.cont_center')}</Typography>
        </Box>
        <Card className={classes.cardT}>
          <CardActionArea onClick={() => handleOpenVc()}>
            <Box mt={3} mb={3}>
              <Stack container direction="row" justifyContent="center" alignItems="center">
                <Box width={mobile ? 80 : 100}>
                  <img
                    src={bypassFlag || offlineFlag ? orangeShield : greenShield}
                    height={mobile ? '50px' : '60px'}
                    alt="shield"
                  />
                </Box>
                <Box>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={bypassFlag ? red : green} className={classes.dot_avatar} />
                    <Typography color="secondary" variant={'body2'}>
                      {t('vc.ecomode')}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={offlineFlag ? red : green} className={classes.dot_avatar} />
                    <Typography color="secondary" variant={'body2'}>
                      Volt+Sync
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={green} className={classes.dot_avatar} />
                    <Typography color="secondary" variant={'body2'}>
                      {t('vc.alarms')}
                    </Typography>
                  </Stack>
                </Box>
                <Box display="flex" alignItems="center"></Box>
              </Stack>
            </Box>
          </CardActionArea>
        </Card>
        <Box mt={5} mb={5}>
          <Divider />
        </Box>

        <Stack
          mb={2}
          mx={'5%'}
          direction={mobile ? 'column' : 'row'}
          spacing={2}
          justifyContent="space-around"
        >
          <Box mx={'1%'}>
            <AutoBypassAlarmTile
              vcs={vcs}
              admin={true}
              handleAlarmClk={handleAlarmClk}
              openMuted={openMuted}
            />
          </Box>
          <Box>
            <AutoDisconnectedAlarmTile
              vcs={offlineList}
              admin={true}
              handleAlarmClk={handleAlarmClk}
              openMuted={openMuted}
            />
          </Box>
          <Box>
            <LogAlarmTile
              vcs={offlineList}
              admin={true}
              handleAlarmClk={handleAlarmClk}
              openMuted={openMuted}
            />
          </Box>
        </Stack>
      </Box>
    </Card>
  )
}
