import Graph from './Graph'
import React from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

export function BypassGraph({ data }) {
  if (data.length) {
    return (
      <Graph
        measures={[
          {
            id: 'bypass',
            data: data
          }
        ]}
        format="%Hh"
        curve={'stepBefore'}
        yScale={'point'}
        tickValue={['Normal', 'Bypass']}
        min={'auto'}
        max={'auto'}
        legendOffset={90}
        colors={['#a6ce34']}
        unit={''}
      />
    )
  } else {
    return (
      <Box mt={-3}>
        <Typography color={'secondary'} align={'center'} variant={'body2'}>
          Pas de Bypass sur la période
        </Typography>
      </Box>
    )
  }
}
