import React, { useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import BoltRoundedIcon from '@mui/icons-material/BoltRounded'
import { MuiThemeProvider, useTheme } from '@material-ui/core/styles'
import { NavLink, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { createTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import voltplus from '../assets/vpluslogo.png'
import VcsDetails from './devices/vcsDetails'
import ParamReq from './Management/paramReq'
import AllClients from './Clients/AllClients'
import AdminStatsDashboard from './adminDash/AdminStatsDashboard'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import TopBar from '../appBar/AppBar'
import { styles } from '../styles/makeStyles'
import apis from '../api/api'
import MaintenanceJournal from './devices/MaintenanceJournal'

const themeList = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        color: '#707070',
        '&$selected': {
          backgroundColor: 'transparent',
          color: '#a6ce34'
        },
        '&$selected:hover': {
          backgroundColor: 'transparent'
        }
      }
    }
  }
})

function ResponsiveDrawer(props) {
  const { window } = props
  const theme = useTheme()
  const history = useHistory()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const location = useLocation()
  const classes = styles()
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handlerDrawerToggleWhenOpen = () => {
    if (mobileOpen) {
      handleDrawerToggle()
    }
  }
  const authCheck = () => {
    const token = localStorage.getItem('token')
    if (token) {
      apis.tokenCheck().then(r => {
        if (!(r.data.isLoggedIn && r.data.user.isAdmin)) {
          history.push('/')
        }
      })
    } else {
      history.push('/')
    }
  }

  useEffect(() => {
    authCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const drawer = (
    <div>
      <div className={classes.drawerHeader}>
        <Box>
          <img src={voltplus} alt="voltpluslog" height="40px" />
        </Box>
      </div>
      <Box mt={7}>
        <MuiThemeProvider theme={themeList}>
          <List>
            <ListItem
              button
              component={NavLink}
              key="dashadmin"
              to={'/dashadmin'}
              exact
              activeClassName="Mui-selected"
              onClick={handlerDrawerToggleWhenOpen}
            >
              <ListItemIcon>
                <DashboardRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              key="allclients"
              to={'/dashadmin/allclients'}
              exact
              activeClassName="Mui-selected"
              onClick={handlerDrawerToggleWhenOpen}
            >
              <ListItemIcon>
                <AccountCircleOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Clients" />
            </ListItem>

            <ListItem
              button
              component={NavLink}
              key="vcs"
              to={'/dashadmin/vcs'}
              activeClassName="Mui-selected"
              onClick={handlerDrawerToggleWhenOpen}
            >
              <ListItemIcon>
                <BoltRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Appareils" />
            </ListItem>
            {/*
            <ListItem
              button
              component={NavLink}
              key="usemgmnt"
              to={'/dashadmin/vcparams'}
              activeClassName="Mui-selected"
              onClick={handlerDrawerToggleWhenOpen}
            >
              <ListItemIcon>
                <ConstructionRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Gestion" />
            </ListItem>
            */}
            <ListItem button onClick={() => history.push('/dash')}>
              <ListItemIcon>
                <ArrowBackIosNewRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Retour" />
            </ListItem>
          </List>
        </MuiThemeProvider>
      </Box>
    </div>
  )

  const container = window !== undefined ? () => window().document.body : undefined
  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopBar variant={'dashadmin'}></TopBar>
      <nav className={classes.drawer} aria-label="main-menu">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer variant="permanent" open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <Switch key={location}>
        <Route path="/dashadmin" exact>
          <AdminStatsDashboard />
        </Route>
        <Route path="/dashadmin/vcs" exact>
          <VcsDetails />
        </Route>
        <Route path="/dashadmin/allclients" exact>
          <AllClients />
        </Route>
        <Route path="/dashadmin/vcparams" exact>
          <ParamReq />
        </Route>
        <Route path="/dashadmin/maintenance" exact>
          <MaintenanceJournal />
        </Route>
      </Switch>
    </div>
  )
}

export default ResponsiveDrawer
