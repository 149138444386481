import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { AppBar, Box, Button, Grid, IconButton, TextField, Toolbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Stack } from '@mui/material'
import apis from '../api/api'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  avatar: {
    width: 14,
    height: 14
  }
}))

export default function ParamDialog({ handleClose, open, param, vc_id }) {
  const classes = useStyles()

  const handleParamMod = p => {
    p.preventDefault()
    const preq = {
      vc_id: vc_id,
      cmds: [`CMD ${vc_id} 0:PARAM SET ${param.id} ${p.target[0].value}`]
    }
    handleClose()
    apis.addPreq(preq).then()
  }
  return (
    <Dialog fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      {param && (
        <>
          <AppBar className={classes.appBar} color="transparent">
            <Toolbar>
              <Typography variant="h6" className={classes.title} color="secondary">
                Modification du paramètre {param.id}
              </Typography>
              <IconButton color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon color="secondary" />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box mt={4} mb={3} mx={'5%'}>
            <Stack spacing={0.5}>
              <Typography color={'primary'}>Paramètre:</Typography>
              <Typography color={'secondary'}>{param.msg}</Typography>
            </Stack>
            <Box mt={2}>
              <Stack spacing={0.5}>
                <Typography color={'primary'}>Valeur Actuelle:</Typography>
                <Typography color={'secondary'}>{param.val}</Typography>
              </Stack>
            </Box>
            <Box mt={4} mb={2}>
              <form className={classes.form} onSubmit={e => handleParamMod(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="clientId"
                      label="Nouvelle Valeur"
                      name="cId"
                    />
                  </Grid>
                </Grid>
                <Box mt={5} mb={2}>
                  <Button type="submit" fullWidth variant="contained" color="primary">
                    <Typography>Enregistrer</Typography>
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </>
      )}
    </Dialog>
  )
}
