import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActionArea,
  Grid,
  IconButton,
  TextField,
  Toolbar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import apis from '../../api/api'
import { Divider, Stack } from '@mui/material'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  avatar: {
    width: 14,
    height: 14
  }
}))

export function IoTModal({ handleClose, open, client, reload }) {
  const classes = useStyles()

  const [newIoTMode, setNewIoTMode] = React.useState(false)
  const [linkMode, setLinkMode] = React.useState(false)

  const handleIoTSubmit = (iot, mode) => {
    iot.preventDefault()
    const sensor = {
      label: iot.target[0].value,
      value: iot.target[2].value,
      client_id: iot.target[4].value,
      type: iot.target[6].value,
      unit: iot.target[8].value
    }
    apis.registerIoT({ ...sensor, newSens: mode }).then(r => {
      if (r.status === 201) {
        handleClose()
        reload()
      }
    })
  }

  const handlUnLink = iot => {
    apis.unlinkIoT({ ...iot, client_id: client.client_id }).then(r => {
      if (r.status === 201) {
        handleClose()
        reload()
      }
    })
  }

  return (
    <Dialog fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <Typography variant="h6" className={classes.title} color="secondary">
            {newIoTMode
              ? 'Nouveau capteur IoT'
              : linkMode
              ? 'Lier Capteur IoT'
              : 'Editer capteurs IoT client'}
          </Typography>
          <IconButton color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon color="secondary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box mt={3} mx={'2%'} mb={3}>
        {newIoTMode ? (
          <Box mx={4} mt={2} mb={3}>
            {/* ---------------------NewSensMode -------------------------*/}
            <form className={classes.form} onSubmit={e => handleIoTSubmit(e, true)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="IoTLabel"
                    label="Nom capteur"
                    name="IoTLabel"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="iot_id"
                    label="ID du capteur"
                    name="iot_id"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="clientId"
                    label="Numéro de Client"
                    name="clientId"
                    autoComplete="clientId"
                    defaultValue={client.client_id}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="type"
                    label="Type (ex: Courant, Température ...)"
                    name="sense_type"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="type"
                    label="Unité (A, °C...)"
                    name="sense_type"
                  />
                </Grid>
              </Grid>
              <Box mt={5} mb={2}>
                <Button type="submit" fullWidth variant="contained" color="primary">
                  {'Enregistrer'}
                </Button>
              </Box>
            </form>
            <Box mt={2}>
              <Button variant="outlined" color="secondary" fullWidth onClick={() => setNewIoTMode(false)}>
                <Typography>Annuler</Typography>
              </Button>
            </Box>
          </Box>
        ) : linkMode ? (
          <Box mx={'2%'} mt={1}>
            {/* ---------------------Linkmode -------------------------*/}
            <Box mx={4} mt={2} mb={3}>
              <form className={classes.form} onSubmit={e => handleIoTSubmit(e, false)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="iotLabel"
                      label="Nom Capteur"
                      name="iotLabel"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="iotId"
                      label="ID du Capteur"
                      name="iotID"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="clientId"
                      label="Numéro de Client"
                      name="clientId"
                      autoComplete="clientId"
                      defaultValue={client.client_id}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="type"
                      label="Type (ex: Courant, Température ...)"
                      name="sense_type"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="type"
                      label="Unité (A, °C...)"
                      name="sense_type"
                    />
                  </Grid>
                </Grid>
                <Box mt={5} mb={2}>
                  <Button type="submit" fullWidth variant="contained" color="primary">
                    {'Enregistrer'}
                  </Button>
                </Box>
              </form>
              <Box mt={2}>
                <Button variant="outlined" color="secondary" fullWidth onClick={() => setLinkMode(false)}>
                  <Typography>Annuler</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box mx={'5%'} mt={1}>
            <Typography color="secondary">Capteurs IoT liés au Client </Typography>
            <Divider />
            {client.iot &&
              client.iot.map(iot => (
                <Box mt={3} mb={2} key={`iotdevice_${iot.label}`}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Card className={classes.card}>
                      <CardActionArea>
                        <Box m={2} minWidth={300}>
                          <Typography color="primary">{iot.label}</Typography>
                        </Box>
                      </CardActionArea>
                    </Card>
                    <Stack direction="row">
                      <Button color="secondary" onClick={() => handlUnLink(iot)}>
                        <Box>
                          <Typography style={{ color: '#FF5C5C' }} variant="body2">
                            délier
                          </Typography>
                        </Box>
                      </Button>
                      <Button color="secondary">
                        <Box mx={1}>
                          <Typography color="secondary" variant="body2">
                            modifier
                          </Typography>
                        </Box>
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              ))}
            <Box mt={6}>
              <Button variant="contained" color="primary" fullWidth onClick={() => setNewIoTMode(true)}>
                <Typography style={{ color: '#ffffff' }}>
                  Ajouter un capteur IoT au client (nouveau capteur)
                </Typography>
              </Button>
            </Box>
            <Box mt={2}>
              <Button variant="outlined" color="secondary" fullWidth onClick={() => setLinkMode(true)}>
                <Typography color="primary">Lier un capteur IoT existant</Typography>
              </Button>
            </Box>
            <Box mt={2}>
              <Button variant="outlined" color="secondary" fullWidth onClick={() => handleClose()}>
                <Typography>Fermer</Typography>
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  )
}
