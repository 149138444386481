import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { AppBar, Box, Button, Grid, IconButton, TextField, Toolbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import apis from '../api/api'
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  avatar: {
    width: 14,
    height: 14
  }
}))

export default function MaintenanceEventDialog({ handleClose, open, vc_id }) {
  const classes = useStyles()
  const [date, setDate] = React.useState(new Date())

  const handleSubmitEvent = p => {
    p.preventDefault()
    const maintenanceEntry = {
      vc_id: vc_id,
      timestamp: date.getTime(),
      msg: p.target[2].value
    }
    apis.addMaintenanceEvent(maintenanceEntry).then(r => {
      if (r.status === 201) {
        handleClose()
        window.location.reload()
      }
    })
  }
  return (
    <Dialog fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <>
        <AppBar className={classes.appBar} color="transparent">
          <Toolbar>
            <Typography variant="h6" className={classes.title} color="secondary">
              Ajouter un événement
            </Typography>
            <IconButton color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon color="secondary" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box mt={4} mb={3} mx={'5%'}>
          <Box mt={2}>
            <form className={classes.form} onSubmit={e => handleSubmitEvent(e)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                    <DesktopDateTimePicker
                      label="Date de l'événement"
                      inputFormat="DD/MM/YYYY HH:mm"
                      value={date}
                      onChange={d => setDate(d.$d)}
                      renderInput={params => <TextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    minRows={3}
                    variant="outlined"
                    required
                    fullWidth
                    id="eventmsg"
                    label="Message"
                    name="cId"
                  ></TextField>
                </Grid>
              </Grid>
              <Box mt={5} mb={2}>
                <Button type="submit" fullWidth variant="contained" color="primary">
                  <Typography>Ajouter</Typography>
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </>
    </Dialog>
  )
}
