import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Box, Card, Divider, IconButton } from '@material-ui/core'
import { Stack } from '@mui/material'
import warning from '../assets/warning.png'
import { useTranslation } from 'react-i18next'
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined'
import { formStyles } from '../styles/makeStyles'
import { useSelector } from 'react-redux'

export default function AlarmDisplay({ alarm, admin, hideCallback }) {
  const classes = formStyles()
  const { t } = useTranslation()
  const mobile = useSelector(state => state.mobile)

  function StdAlarm({ vc, mode }) {
    return (
      <Box mt={3} key={`${mode}_${vc}`}>
        <Card className={classes.card}>
          <Box mx={5} my={2}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-around">
              <Box ml={-1} mr={1} width={70}>
                <img src={warning} height="30px" alt="warning" />
              </Box>
              <Stack>
                <Box mb={0.5} width={350}>
                  <Typography color="primary">
                    {vc.vc_id} | {vc.name}
                  </Typography>
                </Box>
                <Typography color="secondary" variant={'body2'}>
                  {mode === 'message'
                    ? `Alarme Machine: ${vc.log.message.toString()} (code: ${vc.log.vcAlarmCode})`
                    : mode === 'offline'
                    ? t('vc.offline')
                    : t('vc.bypass_noeco')}
                </Typography>
                <Box mt={0.5}>
                  {mode ? (
                    mode === 'message' || mode === 'offline' ? (
                      ''
                    ) : (
                      <Typography color="secondary" variant={'body2'}>
                        Uin: {Number(vc['Uin-L1']).toFixed(2)} V | Uout: {Number(vc['Uout-L1']).toFixed(2)} V
                      </Typography>
                    )
                  ) : (
                    <Typography color="secondary" variant={'body2'}>
                      ''
                    </Typography>
                  )}
                </Box>
              </Stack>
              {admin && (
                <Box width={25}>
                  <IconButton onClick={() => hideCallback(vc, mode)}>
                    <NotificationsOffOutlinedIcon style={{ color: '#9a9a9a' }} fontSize={'medium'} />
                  </IconButton>
                </Box>
              )}
            </Stack>
          </Box>
        </Card>
      </Box>
    )
  }

  function MobileAlarm({ vc, mode }) {
    return (
      <Box mt={3} key={`${mode}_${vc}`}>
        <Card className={classes.card}>
          <Box mx={2} my={2}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <Stack justifyContent="center" alignItems="stretch" ml={-2} mt={2}>
                <img src={warning} height="30px" alt="warning" />
              </Stack>
              <Stack alignItems={'stretch'}>
                <Box mb={0.5} mt={2}>
                  <Typography color="primary">{vc.vc_id}</Typography>
                </Box>
                <Box>
                  <Divider />
                </Box>
                <Box mb={0.5}>
                  <Typography color="primary">{vc.name}</Typography>
                </Box>
                <Box mt={2}>
                  <Typography color="secondary" variant={'body2'}>
                    {mode === 'message'
                      ? `Alarme Machine: ${vc.log.message.toString()} (code: ${vc.log.vcAlarmCode})`
                      : mode === 'offline'
                      ? t('vc.offline')
                      : t('vc.bypass_noeco')}
                  </Typography>
                </Box>
                <Box mt={0.5}>
                  {mode ? (
                    mode === 'message' || mode === 'offline' ? (
                      ''
                    ) : (
                      <Typography color="secondary" variant={'body2'}>
                        Uin: {Number(vc['Uin-L1']).toFixed(2)} V | Uout: {Number(vc['Uout-L1']).toFixed(2)} V
                      </Typography>
                    )
                  ) : (
                    <Typography color="secondary" variant={'body2'}>
                      ''
                    </Typography>
                  )}
                </Box>
              </Stack>
              {admin && (
                <IconButton onClick={() => hideCallback(vc, mode)}>
                  <NotificationsOffOutlinedIcon style={{ color: '#9a9a9a' }} fontSize={'medium'} />
                </IconButton>
              )}
            </Stack>
          </Box>
        </Card>
      </Box>
    )
  }

  return alarm.map(function({ vc, mode }) {
    return mobile ? <MobileAlarm vc={vc} mode={mode} /> : <StdAlarm vc={vc} mode={mode} />
  })
}
