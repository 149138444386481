import { Box, Button, Card, CardActionArea } from '@material-ui/core'
import { Stack } from '@mui/material'
import warning from '../assets/warning.png'
import Typography from '@material-ui/core/Typography'
import moment from 'moment/moment'
import * as React from 'react'
import { formStyles } from '../styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { parseLogAlarm } from './alarmUtils'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { setAlertList } from '../store/userSlice'

export function LogAlarmTile({ vcs, admin, handleAlarmClk, openMuted }) {
  const classes = formStyles()
  const { t } = useTranslation()
  const userVCList = useSelector(state => state.vc_list)
  const dispatch = useDispatch()
  const [displayNumber, setDisplayNumber] = useState(4)
  const [sortedBypassList, setSortedList] = useState([])
  const [allDisplayed, setAllDisplayed] = useState(false)
  const [alarmCount, setAlarmCount] = useState(0)
  const hiddenList = useSelector(state => state.alertLists.hiddenList.message)

  const handleOpenHiddenList = () => {
    dispatch(setAlertList({ selectedType: 'message' }))
    openMuted(true)
  }

  useEffect(() => {
    const parseResult = parseLogAlarm(userVCList, displayNumber)
    setAlarmCount(parseResult.maxDisplay)
    setSortedList(parseResult.list)
    if (displayNumber >= parseResult.maxDisplay) setAllDisplayed(true)
  }, [displayNumber, hiddenList, userVCList])

  return (
    <>
      <Box>
        <Typography color="secondary" variant={'body2'}>
          {t('vc.log_alarm')} ({alarmCount})
        </Typography>
      </Box>
      {sortedBypassList.map((vc, index) => (
        <Box mt={2} key={`alarm_log_${index}_${vc.vc_id}`}>
          <Card className={classes.card}>
            <CardActionArea onClick={() => handleAlarmClk(vc, 'message')}>
              <Box mx={2} my={1}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <img src={warning} height="30px" alt="warning" />
                  <Stack>
                    <Typography color="secondary" variant={'body2'}>
                      {moment(vc.isoTime).format('DD/MM/YY HH:mm')} (
                      {Number((new Date().getTime() - new Date(vc.isoTime).getTime()) / 86400000).toFixed(0)}
                      {' j'})
                    </Typography>
                    <Typography color="primary" variant={'body2'}>
                      {admin ? vc.name : vcs[index].label}
                    </Typography>
                    <Typography color="secondary" variant={'body2'}>
                      {t('vc.new_alarm')} {vc.message}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </CardActionArea>
          </Card>
        </Box>
      ))}
      {!allDisplayed && (
        <Box mt={2}>
          <Stack direction="row" justifyContent="center">
            <Button variant="outlined" onClick={() => setDisplayNumber(displayNumber + 5)}>
              <Typography variant="body2" color="secondary">
                {t('div.show_more')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      )}
      <Stack direction="row" justifyContent="center">
        <Box mb={1} mt={1.5}>
          <Button onClick={handleOpenHiddenList}>
            <Typography variant={'body2'} color="secondary">
              {t('vc.muted_alarm')} ({hiddenList.length})
            </Typography>
          </Button>
        </Box>
      </Stack>
    </>
  )
}
