import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { Backdrop, Button, Card, Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useHistory, useLocation } from 'react-router-dom'
import apis from '../../api/api'
import { CircularProgress, Stack } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import MaintenanceEventDialog from '../../utilities/MaintenanceEventDialog'
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded'
import EditJournalEntryDialog from '../../dialogs/EditJournalEntryDialog'
import CustomChip from '../../graphView/CustomChip'

const useStyles = makeStyles({
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  avatar: {
    width: 12,
    height: 12
  }
})

export default function MaintenanceJournal() {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [vc_id] = React.useState(location.state ? location.state.vc : false)
  const [openEventDialog, setEventDialog] = React.useState(false)
  const [openEditModal, setEditModal] = React.useState(false)
  const [currentEvent, setCurrrentEvent] = React.useState()
  const [maintLogs, setMaintLogs] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [dateinterval, setDateInterval] = React.useState({
    start: new Date(location.state.latest).setDate(new Date(location.state.latest).getDate() - 15),
    end: new Date().getTime()
  })
  const authCheck = () => {
    const token = localStorage.getItem('token')
    if (token) {
      apis.tokenCheck().then(r => {
        if (!(r.data.isLoggedIn && r.data.user.isAdmin)) {
          history.push('/')
        }
      })
    } else {
      history.push('/')
    }
  }
  const handleModifyEvent = event => {
    setCurrrentEvent(event)
    setEditModal(true)
  }

  const setDates = (start, end) => {
    if (!(end > Date.now())) {
      setDateInterval({
        start: start.getTime(),
        end: end.getTime()
      })
    }
  }

  useEffect(() => {
    authCheck()
    const refresh = () => {
      apis.findMaintenanceEvent(vc_id, dateinterval).then(r => {
        if (r.data) {
          setMaintLogs(r.data)
        }
        setLoading(false)
      })
    }
    refresh()
    const interval = setInterval(() => {
      refresh()
    }, 60000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateinterval])

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
      <>
        <Box mt="9%" mx="5%" mb="5%" minWidth={1000}>
          <Box mt={2} mb={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography color={'primary'} variant={'h5'}>
                Journal de maintenance du VCS {vc_id} {location.state ? `| ${location.state.name}` : ''}
              </Typography>
              <Button
                variant={'outlined'}
                color={'secondary'}
                onClick={() => history.push('/dashadmin/vcs', { vc: { vc_id: vc_id } })}
              >
                <Typography color={'secondary'}>Retour Aperçu VCS</Typography>
              </Button>
            </Stack>
          </Box>
          <Divider></Divider>
          <Box mt={2}>
            <Button variant={'outlined'} color={'secondary'} onClick={() => setEventDialog(true)}>
              <Typography color={'secondary'}>Ajouter un évenement</Typography>
            </Button>
          </Box>
          <Box mt={4} mb={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography color={'secondary'}>Période:</Typography>
              <CustomChip
                label={'jours'}
                choices={[7, 15, 30]}
                setValue={setDates}
                value={dateinterval}
                mode="date"
              />
            </Stack>
          </Box>
          <Box mt={2}>
            {maintLogs.length > 0 &&
              maintLogs.map(event => (
                <Box mt={3} key={`maintLog_${event.timestamp}`}>
                  <Card className={classes.card}>
                    <Box m={2}>
                      <Stack justifyContent="space-between" spacing={1}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography variant={'subtitle1'} color="primary" style={{ fontWeight: 600 }}>
                            {moment(event.timestamp).format('DD/MM/YY HH:mm')}
                          </Typography>
                          <Button
                            color="secondary"
                            variant={'outlined'}
                            onClick={() => handleModifyEvent(event)}
                          >
                            <ModeEditOutlineRoundedIcon style={{ color: 'secondary' }} />
                            <Box ml={1.5}>
                              <Typography variant={'button'} color="secondary">
                                Modifier
                              </Typography>
                            </Box>
                          </Button>
                        </Stack>
                        <Box>
                          <Typography color="secondary">{event.msg}</Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Card>
                </Box>
              ))}
          </Box>
        </Box>
        <MaintenanceEventDialog
          open={openEventDialog}
          handleClose={() => setEventDialog(false)}
          vc_id={vc_id}
        />
        <EditJournalEntryDialog
          open={openEditModal}
          handleClose={() => setEditModal(false)}
          vc_id={vc_id}
          event={currentEvent}
        />
      </>
    )
  }
}
