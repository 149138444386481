import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import {
  AppBar,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import apis from '../../api/api'
import { Divider, Stack } from '@mui/material'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  avatar: {
    width: 14,
    height: 14
  }
}))

export function UsersModal({ handleClose, open, client, reload }) {
  const classes = useStyles()

  const [newUserMode, setNewUserMode] = React.useState(false)
  const [deleteUserMode, setDeletedUserMode] = React.useState(false)
  const [editUserMode, setEditedUserMode] = React.useState(false)
  const [mailLogs, setMailLogs] = React.useState(false)
  const [mailAlarms, setMailAlarms] = React.useState(false)

  const [selectedUser, setSelectedUser] = React.useState({})

  const handleDeleteUserSelect = user => {
    setSelectedUser(user)
    setDeletedUserMode(true)
  }

  const handleNewUserMode = () => {
    setSelectedUser({})
    setNewUserMode(true)
  }

  const handleEditUserSelect = user => {
    apis.getUser(user).then(r => {
      r.data[0] ? setSelectedUser(r.data[0]) : setSelectedUser(user)

      setEditedUserMode(true)
    })
  }

  const handleCloseModal = () => {
    setNewUserMode(false)
    setDeletedUserMode(false)
    setEditedUserMode(false)
    handleClose()
  }

  const handleUserSubmit = signUp => {
    signUp.preventDefault()
    const user = {
      email: signUp.target[0].value.toLowerCase(),
      password: signUp.target[2].value,
      clientId: signUp.target[4].value,
      adm_pass: signUp.target[6].value,
      language: signUp.target[8].value,
      mail_logs: mailLogs,
      mail_alarm: mailAlarms,
      _id: selectedUser._id ? selectedUser._id : false,
      old_email: selectedUser.email ? selectedUser.email : signUp.target[0].value
    }
    apis.upsertUser(user).then(r => {
      if (r.status === 201) {
        handleClose()
        reload()
      }
    })
  }

  const handleUnLink = submitted => {
    submitted.preventDefault()
    const user = {
      email: selectedUser,
      client_id: client.client_id,
      adm_pass: submitted.target[2].value
    }
    if (submitted.target[0].value === selectedUser) {
      apis.unlinkUser(user).then(r => {
        if (r.status === 200) {
          handleClose()
          reload()
        }
      })
    }
  }

  const NewUser = () => {
    return (
      <Box mx={4} mt={2} mb={3}>
        <form className={classes.form} onSubmit={e => handleUserSubmit(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                defaultValue={selectedUser.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="userpassword"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="clientId"
                label="Numéro de Client"
                name="clientId"
                defaultValue={client.client_id}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password admin"
                type="password"
                id="password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Langue</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="language"
                  defaultValue={selectedUser.language ? selectedUser.language : ''}
                >
                  <MenuItem value={'FR'}>FR</MenuItem>
                  <MenuItem value={'DE'}>DE</MenuItem>
                  <MenuItem value={'EN'}>EN</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <Box mt={2}>
                <Typography variant={'body2'} color={'primary'}>
                  Notifications Mail
                </Typography>
                <Stack mt={2} direction={'row'} spacing={2}>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Switch
                      color="primary"
                      inputProps={{ 'aria-label': 'Alarmes & Bypass Switch' }}
                      onChange={e => setMailAlarms(e.target.checked)}
                      defaultChecked={selectedUser.mail_alarms ? selectedUser.mail_alarms : false}
                    />
                    <Typography variant={'body2'} color={'secondary'}>
                      Alarmes Bypass & Connection
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Switch
                      color="primary"
                      inputProps={{ 'aria-label': 'Logs machine' }}
                      onChange={e => setMailLogs(e.target.checked)}
                      defaultChecked={selectedUser.mail_logs ? selectedUser.mail_logs : false}
                    />
                    <Typography variant={'body2'} color={'secondary'}>
                      Logs machine
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Box mt={5} mb={2}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              {'Enregistrer'}
            </Button>
          </Box>
        </form>
        <Box mt={2}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={() => (editUserMode ? setEditedUserMode(false) : setNewUserMode(false))}
          >
            <Typography>Annuler</Typography>
          </Button>
        </Box>
      </Box>
    )
  }

  const UserOverview = () => {
    return (
      <Box mx={'5%'} mt={1}>
        <Typography color="secondary">Utilisateurs liés au client</Typography>
        <Divider />
        {client.vcs &&
          client.users.map(user => (
            <Box mt={3} mb={2} key={`usercard_${user}`}>
              <Card className={classes.card}>
                <Box m={2} minWidth={300}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography color="primary">{user}</Typography>
                    <Stack direction="row">
                      <Button color="secondary" onClick={() => handleDeleteUserSelect(user)}>
                        <Box>
                          <Typography style={{ color: '#FF5C5C' }} variant="body2">
                            délier
                          </Typography>
                        </Box>
                      </Button>
                      <Button color="secondary" onClick={() => handleEditUserSelect(user)}>
                        <Box mx={1}>
                          <Typography color="secondary" variant="body2">
                            modifier
                          </Typography>
                        </Box>
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              </Card>
            </Box>
          ))}
        <Box mt={6}>
          <Button variant="contained" color="primary" fullWidth onClick={() => handleNewUserMode()}>
            <Typography style={{ color: '#ffffff' }}>Ajouter un utilisateur</Typography>
          </Button>
        </Box>
        <Box mt={2}>
          <Button variant="outlined" color="secondary" fullWidth onClick={() => handleClose()}>
            <Typography>Fermer</Typography>
          </Button>
        </Box>
      </Box>
    )
  }

  const UserDelete = () => {
    return (
      <Box mx={'5%'} mt={1}>
        <Box mb={3}>
          <Typography color="secondary">
            Insérer le mail de l'utilisateur ({selectedUser}) et le mot de passe admin pour confirmer la
            suppression se l'utilisateur.
          </Typography>
        </Box>
        <form className={classes.form} onSubmit={e => handleUnLink(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField variant="outlined" required fullWidth id="clientLabelwe" label="Email" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="adm_password"
                label="Password admin"
                type="password"
                id="adm_password"
              />
            </Grid>
          </Grid>
          <Box mt={5} mb={2}>
            <Button type="submit" fullWidth variant="outlined" color="secondary">
              <Typography style={{ color: '#FF5C5C' }}>{'Confirmer suppression'}</Typography>
            </Button>
          </Box>
          <Box>
            <Button Button variant="outlined" color="secondary" fullWidth>
              <Typography onClick={() => setDeletedUserMode(false)}>Annuler</Typography>
            </Button>
          </Box>
        </form>
      </Box>
    )
  }

  return (
    <Dialog fullWidth={true} open={open} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <Typography variant="h6" className={classes.title} color="secondary">
            {newUserMode
              ? 'Nouvel utilisateur'
              : deleteUserMode
              ? 'Délier utilisateur'
              : 'Editer utilisateur'}
          </Typography>
          <IconButton color="inherit" onClick={handleCloseModal} aria-label="close">
            <CloseIcon color="secondary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box mt={3} mx={'2%'} mb={3}>
        {newUserMode ? (
          <NewUser />
        ) : deleteUserMode ? (
          <UserDelete />
        ) : editUserMode ? (
          <NewUser />
        ) : (
          <UserOverview />
        )}
      </Box>
    </Dialog>
  )
}
