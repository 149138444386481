import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { Chip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'

export function LanguageChip(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const langLabel = useSelector(state => state.view_params.lang.name)

  const langs = [
    { name: 'FR', id: 'fr' },
    { name: 'DE', id: 'de' },
    { name: 'EN', id: 'en' }
  ]

  const handleClick = lang => {
    props.handleLngChg(lang)
    handleClose()
  }

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <span style={{ padding: '12px' }}>
      <Chip variant="outlined" color="secondary" label={langLabel} onClick={handleMenu} />
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
      >
        {langs.map(lang => (
          <MenuItem onClick={() => handleClick(lang)} key={lang.name}>
            <Typography color="secondary">{lang.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </span>
  )
}
