import * as React from 'react'
import { Avatar, Box, Button, Card } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Divider, Stack } from '@mui/material'
import { isVCSOnline } from '../utilities/Utils'
import green from '../assets/gSquare.png'
import red from '../assets/rSquare.png'
import moment from 'moment'
import { readAlarm, readBypass, readHwError, readModel } from '../alarms/VcsAlarms'
import { useHistory } from 'react-router-dom'
import { formStyles } from '../styles/makeStyles'

export default function AdminOverview({ vc }) {
  const online = vc.time ? isVCSOnline(vc.time) : 'nc'
  const log = vc.log ? vc.log : ''
  const model = vc.model ? vc.model : ''
  const history = useHistory()
  const classes = formStyles()
  return (
    <Card className={classes.card}>
      <Box mt={2} mx={1} minHeight={736}>
        <Card className={classes.cardT}>
          <Box mx="5%" my="2%">
            <Typography color="primary" style={{ fontWeight: 600 }}>
              {vc.name}
            </Typography>
            <Typography color="secondary">{vc.client}</Typography>
            <Box>
              <Box mt={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar src={online ? green : red} className={classes.dot_avatar} />
                  <Typography color="secondary" variant={'body2'}>
                    {isVCSOnline(vc.time) ? 'En ligne' : 'Hors ligne'}
                  </Typography>
                </Stack>
              </Box>
              <Box mt={3}>
                <Stack spacing={0.5}>
                  <Stack direction="row" spacing={1}>
                    <Typography color="secondary" variant={'body2'}>
                      Modèle :
                    </Typography>
                    <Typography color="secondary" variant={'body2'}>
                      {vc.model}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="primary" noWrap variant={'body2'}>
                      ID :
                    </Typography>
                    <Typography color="primary" noWrap variant={'body2'}>
                      {vc.vc_id}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="secondary" noWrap variant={'body2'}>
                      Last sync:
                    </Typography>
                    <Typography color="secondary" noWrap variant={'body2'}>
                      {vc.time ? moment(vc.time).format('DD/MM/YY HH:mm') : 'nc'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="secondary" variant={'body2'}>
                      Eco sect:
                    </Typography>
                    <Typography color="secondary" variant={'body2'}>
                      {vc.benchmark ? vc.benchmark : 'nc'}%
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="secondary" variant={'body2'}>
                      Perf. Vc
                    </Typography>
                    <Typography color="secondary" variant={'body2'}>
                      {vc.EecoTot ? Number((vc.EecoTot / vc.Etot) * 100 - vc.benchmark).toFixed(2) : 'nc'}%
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Box mt={4}>
                <Divider />
              </Box>
              <Box mt={1}>
                <Typography color="primary">Informations techniques</Typography>
                <Box mt={1}>
                  <Stack spacing={0.5} alignItems="left" justifyContent="space-between">
                    <Typography color="secondary" variant={'body2'}>
                      Secteur: {vc.sector ? vc.sector : 'na'}
                    </Typography>
                    <Typography color="secondary" variant={'body2'}>
                      Bypass: {vc.bypass ? vc.bypass : 'non'}
                    </Typography>
                    <Typography color="secondary" variant={'body2'}>
                      Jours de bypass: {vc.bypass_time ? Number(vc.bypass_time / 86400000).toFixed(1) : '\n0'}
                    </Typography>
                    <Typography color="secondary" variant={'body2'}>
                      Uptime:{' '}
                      {vc.bypass_time
                        ? Number((1 - vc.bypass_time / (new Date() - new Date(vc.installed))) * 100).toFixed(
                            1
                          )
                        : '100'}
                      %
                    </Typography>
                    <Typography color="secondary" variant={'body2'}>
                      Date d'installation : {vc.installed ? moment(vc.installed).format('DD/MM/YY') : 'nc'}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
              <Box mt={4}>
                <Divider />
              </Box>
              <Box mt={1}>
                <Typography color="primary">Variables du dernier log</Typography>
                <Box mt={1}>
                  <Stack spacing={0.5} alignItems="left" justifyContent="space-between">
                    <Typography color="secondary" variant={'body2'} align={'left'}>
                      Message: {log.message ? log.message : 'na'}
                    </Typography>
                    <Typography color="secondary" variant={'body2'} align={'left'}>
                      VC_state: {log.VC_state ? log.VC_state : 'na'}
                    </Typography>
                    <Typography color="secondary" variant={'body2'} align={'left'}>
                      hwInfoState: {log.hwInfoState ? log.hwInfoState : 'na'}
                    </Typography>
                    <Typography color="secondary" variant={'body2'} align={'left'}>
                      hwRelayState: {log.hwRelayState ? log.hwRelayState : 'na'}
                    </Typography>
                    <Typography color="secondary" variant={'body2'} align={'left'}>
                      hwFailurecode:{' '}
                      {log
                        ? `${readHwError(log.hwFailurecode, readModel(model))} (code: ${log.hwFailurecode})`
                        : 'na'}
                    </Typography>
                    <Typography color="secondary" variant={'body2'} align={'left'}>
                      vcAlarmCode:{' '}
                      {log
                        ? `${readAlarm(log.vcAlarmCode, readModel(model))} (code: ${log.vcAlarmCode})`
                        : 'na'}
                    </Typography>
                    <Typography color="secondary" variant={'body2'} align={'left'}>
                      vcBypassCode:{' '}
                      {log
                        ? `${readBypass(log.vcBypassCoee, readModel(model))} (code: ${log.vcBypassCoee})`
                        : 'na'}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
      <Box my={1}>
        <Stack direction="row" justifyContent="center">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => history.push('/dashadmin/vcs', { vc: vc })}
          >
            <Typography variant="body2">Plus de détails</Typography>
          </Button>
        </Stack>
      </Box>
    </Card>
  )
}
