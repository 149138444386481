import { Stack } from '@mui/material'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import React from 'react'

export function LatestmesDisplay({ vc }) {
  return (
    <Stack direction="row">
      <Box mt={1} mr={3}>
        <Box mt={1}>
          <Stack spacing={0.25} alignItems="left" justifyContent="space-between">
            <Stack direction="row" spacing={1}>
              <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                Uin-L1:
              </Typography>
              <Typography color="secondary" variant={'body2'} align={'left'}>
                {vc ? Number(vc['Uin-L1']).toFixed(2) : 'na'} V
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                Uin-L2:
              </Typography>
              <Typography color="secondary" variant={'body2'} align={'left'}>
                {vc ? Number(vc['Uin-L2']).toFixed(2) : 'na'} V
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                Uin-L3:
              </Typography>
              <Typography color="secondary" variant={'body2'} align={'left'}>
                {vc ? Number(vc['Uin-L3']).toFixed(2) : 'na'} V
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box mt={1} mr={3}>
        <Box mt={1}>
          <Stack spacing={0.25} alignItems="left" justifyContent="space-between">
            <Stack direction="row" spacing={1}>
              <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                Uout-L1:
              </Typography>
              <Typography color="secondary" variant={'body2'} align={'left'}>
                {vc ? Number(vc['Uout-L1']).toFixed(2) : 'na'} V
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                Uout-L2:
              </Typography>
              <Typography color="secondary" variant={'body2'} align={'left'}>
                {vc ? Number(vc['Uout-L2']).toFixed(2) : 'na'} V
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                Uout-L3:
              </Typography>
              <Typography color="secondary" variant={'body2'} align={'left'}>
                {vc ? Number(vc['Uout-L3']).toFixed(2) : 'na'} V
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box mt={1} mr={3}>
        <Box mt={1}>
          <Stack spacing={0.25} alignItems="left" justifyContent="space-between">
            <Stack direction="row" spacing={1}>
              <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                I1:
              </Typography>
              <Typography color="secondary" variant={'body2'} align={'left'}>
                {vc ? Number(vc['I1']).toFixed(2) : 'na'} A
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                I2:
              </Typography>
              <Typography color="secondary" variant={'body2'} align={'left'}>
                {vc ? Number(vc['I2']).toFixed(2) : 'na'} A
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                I3:
              </Typography>
              <Typography color="secondary" variant={'body2'} align={'left'}>
                {vc ? Number(vc['I3']).toFixed(2) : 'na'} A
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box mt={1} mr={3}>
        <Box mt={1}>
          <Stack spacing={0.25} alignItems="left" justifyContent="space-between">
            <Stack direction="row" spacing={1}>
              <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                Pa-L1:
              </Typography>
              <Typography color="secondary" variant={'body2'} align={'left'}>
                {vc ? Number(vc['Pa-L1']).toFixed(2) : 'na'} kWh
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                Pa-L2:
              </Typography>
              <Typography color="secondary" variant={'body2'} align={'left'}>
                {vc ? Number(vc['Pa-L2']).toFixed(2) : 'na'} kWh
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                Pa-L3:
              </Typography>
              <Typography color="secondary" variant={'body2'} align={'left'}>
                {vc ? Number(vc['Pa-L3']).toFixed(2) : 'na'} kWh
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
      {vc.Etot && (
        <>
          <Box mt={1} mr={3}>
            <Box mt={1}>
              <Stack spacing={0.25} alignItems="left" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                  <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                    Pr-L1:
                  </Typography>
                  <Typography color="secondary" variant={'body2'} align={'left'}>
                    {vc ? Number(vc['Pr-L1']).toFixed(2) : 'na'} kWh
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                    Pr-L2:
                  </Typography>
                  <Typography color="secondary" variant={'body2'} align={'left'}>
                    {vc ? Number(vc['Pr-L2']).toFixed(2) : 'na'} kWh
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                    Pr-L3:
                  </Typography>
                  <Typography color="secondary" variant={'body2'} align={'left'}>
                    {vc ? Number(vc['Pr-L3']).toFixed(2) : 'na'} kWh
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Box>
          <Box mt={1} mr={3}>
            <Box mt={1}>
              <Stack spacing={0.25} alignItems="left" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                  <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                    Etot:
                  </Typography>
                  <Typography color="secondary" variant={'body2'} align={'left'}>
                    {vc ? Number(vc['Etot']) : 'na'} kWh
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography color="secondary" variant={'body2'} align={'left'} style={{ fontWeight: 600 }}>
                    EecoTot:
                  </Typography>
                  <Typography color="secondary" variant={'body2'} align={'left'}>
                    {vc ? vc.EecoTot : 'na'} kWh
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </>
      )}
    </Stack>
  )
}
