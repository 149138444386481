import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { AppBar, Box, Button, IconButton, Toolbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AlarmDisplay from './AlarmDisplay'
import { alarmListUpdate } from './alarmUtils'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  avatar: {
    width: 14,
    height: 14
  }
}))

export default function AlarmDialog({ handleClose, open, openAssist, alarm, hiddenVcs, admin, user }) {
  const vc = alarm ? alarm.vc : ''
  const classes = useStyles()
  const { t } = useTranslation()

  const handleAlarmHide = () => {
    alarmListUpdate({ vc_id: vc.vc_id, mode: alarm.mode }, user, true)
    handleClose()
  }
  return (
    <Dialog fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <Typography variant="h6" className={classes.title} color="secondary">
            {t('vc.ap_alarm')}
          </Typography>
          <IconButton color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon color="secondary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box mt={5} mb={5} mx={'5%'}>
        <AlarmDisplay alarm={[alarm]} admin={admin} hideCallback={handleAlarmHide} />
      </Box>
      <Stack direction="row" justifyContent="center">
        <Box mt={3} mb={2}>
          <Button variant="outlined" onClick={() => openAssist()}>
            <Typography color="secondary">{t('vc.assist')}</Typography>
          </Button>
        </Box>
      </Stack>
    </Dialog>
  )
}
