import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { AppBar, Box, Button, Grid, IconButton, TextField, Toolbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import apis from '../../api/api'
import Avatar from '@material-ui/core/Avatar'
import { formStyles } from '../../styles/makeStyles'

export function CustomerModal({ handleClose, open, client, reload, delMode }) {
  const classes = formStyles()

  const [deleteMode, setDeleteMode] = React.useState(delMode)
  const [clientIcon, setClientIcon] = React.useState('')
  const [client_idErr, setClientErr] = React.useState({ state: false, msg: '' })
  const [nameErr, setNameErr] = React.useState({ state: false, msg: '' })
  const [addressErr, setAddressErr] = React.useState({ state: false, msg: '' })
  const [contactErr, setContactErr] = React.useState({ state: false, msg: '' })
  const [pswdErr, setpswdErr] = React.useState({ state: false, msg: '' })

  const defaultclient = client ? client : ''
  const updateMode = !!client

  const InputValidation = errorList => {
    setNameErr({ state: false, msg: '' })
    setClientErr({ state: false, msg: '' })
    setAddressErr({ state: false, msg: '' })
    setContactErr({ state: false, msg: '' })
    setpswdErr({ state: false, msg: '' })
    errorList.forEach(e => {
      // eslint-disable-next-line default-case
      switch (e.concern) {
        case 'name':
          setNameErr({ state: true, msg: e.message })
          break
        case 'client_id':
          setClientErr({ state: true, msg: e.message })
          break
        case 'address':
          setAddressErr({ state: true, msg: e.message })
          break
        case 'contact':
          setContactErr({ state: true, msg: e.message })
          break
        case 'pswd':
          setpswdErr({ state: true, msg: e.message })
          break
      }
    })
  }

  const handleClientSubmit = signUp => {
    signUp.preventDefault()
    const clientPayload = {
      name: signUp.target[1].value,
      id: signUp.target[3].value,
      address: signUp.target[5].value,
      contact: signUp.target[7].value,
      adm_pass: signUp.target[9].value
    }
    updateMode
      ? apis
          .updateClient({ ...clientPayload, _id: client._id, icon: clientIcon ? clientIcon : client.icon })
          .then(r => {
            if (r.status === 201) {
              handleClose()
              reload()
            } else if (r.data.error) {
              InputValidation(r.data.error)
            }
          })
      : apis.registerClient({ ...clientPayload, icon: clientIcon }).then(r => {
          if (r.status === 201) {
            handleClose()
            reload()
          } else if (r.data.error) {
            InputValidation(r.data.error)
          }
        })
  }

  const handleClientDelete = signUp => {
    signUp.preventDefault()
    const clientInput = {
      adm_pass: signUp.target[2].value,
      _id: client._id
    }
    if (client.name === signUp.target[0].value) {
      client.users.forEach(u => {
        apis.unlinkUser({ email: u, client_id: client.client_id, adm_pass: clientInput.adm_pass }).then()
      })
      apis.deleteClient(clientInput).then(r => {
        if (r.status === 200) {
          handleClose()
          reload()
        }
      })
    }
  }

  const handleSetIcon = e => {
    let reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = () => {
      setClientIcon(reader.result)
    }
  }

  const DeleteForm = () => {
    return (
      <>
        <Box mb={3}>
          <Typography color="secondary">
            Insérer le nom de client ({client.name}) et le mot de passe admin pour confirmer la suppression du
            client.
          </Typography>
        </Box>
        <form className={classes.form} onSubmit={e => handleClientDelete(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField variant="outlined" required fullWidth id="clientLabelwe" label="Nom client" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="adm_password"
                label="Password admin"
                type="password"
                id="adm_password"
              />
            </Grid>
          </Grid>
          <Box mt={5} mb={2}>
            <Button type="submit" fullWidth variant="outlined" color="secondary">
              <Typography style={{ color: '#FF5C5C' }}>{'Confirmer suppression'}</Typography>
            </Button>
          </Box>
        </form>
        <Box>
          <Button Button variant="outlined" color="secondary" fullWidth>
            <Typography onClick={() => setDeleteMode(false)}>Annuler</Typography>
          </Button>
        </Box>
      </>
    )
  }

  return (
    <Dialog fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <Typography variant="h6" className={classes.title} color="secondary">
            {updateMode ? (deleteMode ? 'Supprimer Client' : 'Edit client') : 'Nouveau Client'}
          </Typography>
          <IconButton color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon color="secondary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box mt={5} mx={'5%'}>
        <Box mx={4} mt={2} mb={5}>
          {deleteMode ? (
            <DeleteForm />
          ) : (
            <>
              <Box display="flex" justifyContent="center" alignItems="center" mt={-3}>
                <Avatar
                  src={client ? client.icon : clientIcon}
                  className={classes.avatar}
                  alt={'client Icon'}
                >
                  Client
                </Avatar>
              </Box>
              <form className={classes.form} onSubmit={e => handleClientSubmit(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                      <Button variant="contained" component="label">
                        <Grid item xs={12}>
                          <input type="file" hidden onChange={handleSetIcon} accept="image/*" />
                        </Grid>
                        Modifier
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="clientLabel"
                      label="Nom Client"
                      name="vcLabel"
                      error={nameErr.state}
                      helperText={nameErr.msg}
                      defaultValue={defaultclient.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="clientId"
                      label="Numéro de Client"
                      name="clientId"
                      autoComplete="clientId"
                      error={client_idErr.state}
                      helperText={client_idErr.msg}
                      defaultValue={defaultclient.client_id}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="address"
                      label="Adresse"
                      name="address"
                      error={addressErr.state}
                      helperText={addressErr.msg}
                      defaultValue={defaultclient.address}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="contact"
                      label="Personne de contact"
                      name="Personne de contact"
                      error={contactErr.state}
                      helperText={contactErr.msg}
                      defaultValue={defaultclient.contact_person}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="adm_password"
                      label="Password admin"
                      type="password"
                      id="adm_password"
                      error={pswdErr.state}
                      helperText={pswdErr.msg}
                    />
                  </Grid>
                </Grid>
                <Box mt={5} mb={updateMode ? 2 : 4}>
                  <Button type="submit" fullWidth variant="contained" color="primary">
                    <Typography>{updateMode ? 'Mettre à jour' : 'Enregistrer'}</Typography>
                  </Button>
                </Box>
              </form>
              {updateMode && (
                <Button Button variant="outlined" color="secondary" fullWidth>
                  <Typography onClick={() => setDeleteMode(true)}>Supprimer client</Typography>
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  )
}
