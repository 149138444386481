import * as React from 'react'
import {
  AppBar,
  Box,
  Button,
  Chip,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  useMediaQuery
} from '@material-ui/core'
import voltplus from '../assets/vpluslogo.png'
import UserMenu from '../auth/userMenu'
import { useHistory } from 'react-router-dom'
import VCSelector from '../utilities/VCSelector'
import { LanguageChip } from '../utilities/LanguageChip'
import Typography from '@material-ui/core/Typography'
import { styles } from '../styles/makeStyles'
import { useSelector } from 'react-redux'
import { theme } from '../index'
import { Stack } from '@mui/material'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'

export default function TopBar({ variant, vc, label, admin, handlechange, handleLngChg }) {
  const history = useHistory()
  const classes = styles()
  const user = useSelector(state => state.profile)
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const lightbar = variant === 'graph'

  return (
    <Box mb={2}>
      <AppBar position="fixed" className={classes.appBar} elevation={2} style={{ background: '#ffffff' }}>
        <Toolbar>
          <Grid
            container
            justifyContent={mobile ? (lightbar ? 'flex-start' : 'center') : 'space-between'}
            alignItems="center"
          >
            {mobile && lightbar && (
              <IconButton onClick={() => history.goBack()}>
                <ArrowBackIosNewRoundedIcon style={{ color: '#9a9a9a' }} fontSize={'small'} />
              </IconButton>
            )}
            <Hidden mdDown={true}>
              <Grid item>
                <Box ml={2} my={2.5}>
                  <Stack direction={'row'}>
                    <a href="https://voltplus.ch">
                      <img src={voltplus} alt="voltpluslog" height="50px" />
                    </a>
                  </Stack>
                </Box>
              </Grid>
            </Hidden>
            <Grid>
              <Stack direction="row" justifyContent="center" alignItems="center">
                {variant === 'dashadmin' && (
                  <Box mr={2}>
                    <Typography variant="h6" color="secondary">
                      {mobile ? 'dashAdmin' : 'Dashboard Admin'}
                    </Typography>
                  </Box>
                )}
                <UserMenu user={user} variant={variant} />
                {variant === 'choice' && (
                  <VCSelector
                    setValue={handlechange}
                    prop_vc={vc}
                    prop_label={label}
                    admin={admin}
                    mobile={mobile}
                  />
                )}
                {admin && (
                  <Box ml={1.5}>
                    <Button
                      onClick={() =>
                        history.push('/dashadmin', {
                          user: user
                        })
                      }
                    >
                      <Typography color="primary"> {mobile ? 'Admin' : 'Dashboard Admin'}</Typography>
                    </Button>
                  </Box>
                )}
                {!mobile && variant === 'choice' && (
                  <Box ml={2}>
                    <Chip
                      variant="outlined"
                      color="secondary"
                      label={'VoltApp'}
                      onClick={() => history.push('/widget')}
                    />
                  </Box>
                )}
                {variant === 'choice' && <LanguageChip handleLngChg={handleLngChg} />}
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box mt={-1} mb={mobile ? 14 : 14}></Box>
    </Box>
  )
}
