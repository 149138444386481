const lodash = require('lodash')

export const sumDataset = (rawData, name, params) => {
  const output = []
  let minlength = 0
  let smallestIndex = 0
  let max = 0
  let min = 100000
  const data = rawData.filter(set => Object.keys(set).length > 0)
  if (
    /(Tension|Spannung|Voltage|Coefficient Eco|Uin-L1|Uin-L2|Uin-L3|Uout-L1|Uout-L2|Uout-L3|Ceco1|Ceco2|Ceco3|Pa-L1|Pa-L2|Pa-L3|Pr-L1|Pr-L2|Pr-L3|I1|I2|I3|Etot|E-L1|E-L2|E-L3|Eeco-L1|Eeco-L2|Eeco-L3|EecoTot)$/i.test(
      name
    )
  ) {
    if ((name === 'Tension' || name === 'Spannung' || name === 'Voltage') && data.length < 4) {
      data[0].id = `${name} In`
      data[1].id = `${name} Out`
      data.forEach(dataSet => {
        if (dataSet.min < min) min = dataSet.min
        if (dataSet.max > max) max = dataSet.max
      })
    } else if (/(Tension|Spannung|Voltage)$/i.test(name) && data.length > 3) {
      const sets = ['Uin-L1', 'Uout-L1']
      min = 1000000
      const minlength = Math.min(
        ...data.map(function(o) {
          return o.data.length
        })
      )
      const index = data.findIndex(dataset => dataset.data.length === minlength)
      sets.forEach(set => {
        const avg = []
        data[index].data.forEach((mes, index) => {
          const sumArray = []
          data.forEach(data => {
            if (data.id === set && data.data[index]) {
              sumArray.push(parseFloat(data.data[index].y) * 100)
            }
          })
          const sum = Number(lodash.sum(sumArray) / (100 * (data.length / 2))).toFixed(2)
          avg.push({
            x: mes.x,
            y: sum
          })
          if (sum < min) min = sum
          if (sum > max) max = sum
        })
        output.push({
          dmin: data[0].dmin,
          dmax: data[0].dmax,
          min: min,
          max: max,
          data: avg
        })
      })
      output[0].id = `${name} in`
      output[1].id = `${name} out`
    } else {
      data[0].id = name
      max = data[0].max
      min = data[0].min
    }
    if (data.size < 4) {
      data.forEach(dataSet => {
        if (dataSet.min < min) min = dataSet.min
        if (dataSet.max > max) max = dataSet.max
      })
    }
  } else {
    const isCurrent = /(Courant|Current|Storm)$/i.test(name)
    if (isCurrent && data.length > 4) {
      data[0].id = `${name} L1`
      data[1].id = `${name} L2`
      data[2].id = `${name} L3`
      data.forEach(dataSet => {
        if (dataSet.min < min) min = dataSet.min
        if (dataSet.max > max) max = dataSet.max
      })
    }
    minlength = data[0].data.length
    data.forEach((dataset, index) => {
      if (parseFloat(dataset.min) < min) min = dataset.min
      if (dataset.length > minlength) {
        minlength = dataset.length
        smallestIndex = index
      }
    })
    const sets = isCurrent
      ? [...params[0], ...params[1], ...params[2]]
      : [...params[0], ...params[1], ...params[2], 'tot']
    min = 1000000
    sets.forEach(set => {
      const avg = []
      data[smallestIndex].data.forEach((mes, index) => {
        const sumArray = []
        data.forEach(data => {
          if ((data.id === set || set === 'tot') && data.data[index]) {
            sumArray.push(parseFloat(data.data[index].y) * 100)
          }
        })
        const sum = lodash.sum(sumArray) / 100
        if (sum < min) min = sum
        avg.push({
          x: mes.x,
          y: sum
        })
        if (sum > Number(max)) max = sum
      })
      output.push({
        dmin: data[0].dmin,
        dmax: data[0].dmax,
        min: min,
        max: max,
        data: avg
      })
    })
    output[0].id = `${name} L1`
    output[1].id = `${name} L2`
    output[2].id = `${name} L3`
    if (!isCurrent) {
      output[3].id = `${name} Tot`
    }
  }
  return { max: max, min: min, data: output[0] ? output : data }
}

export const parseBenchmark = data => {
  let max = 0
  let min = 100000
  data[0].id = 'Benchmark secteur'
  data[1].id = 'Mon Benchmark'

  data.forEach(dataSet => {
    if (dataSet.min < min) min = dataSet.min
    if (dataSet.max > max) max = dataSet.max
  })
  return { max: min, min: max, data: data }
}

export const sumCurrent = mes => {
  const EecoTot = []
  const Etot = []
  const pATot = []
  const uInTot = []
  const uOutTot = []

  mes.forEach(m => {
    EecoTot.push(m.EecoTot)
    Etot.push(m.Etot)
    pATot.push(m.pATot * 100)
    uInTot.push(m.uInTot * 100)
    uOutTot.push(m.uOutTot * 100)
  })
  const sum = {
    EecoTot: lodash.sum(EecoTot),
    Etot: lodash.sum(Etot),
    pATot: Number(lodash.sum(pATot) / 100).toFixed(1),
    uInTot: Number(lodash.sum(uInTot) / (100 * mes.length)).toFixed(1),
    uOutTot: Number(lodash.sum(uOutTot) / (100 * mes.length)).toFixed(1),
    sector: mes[0].sector,
    benchmark: mes[0].benchmark
  }
  sum.ecoPercent = Number(sum.EecoTot / sum.Etot).toFixed(3)
  return sum
}

export const setAvg = set => {
  return Number(lodash.sum(set.map(e => e.y * 100)) / (set.length * 100)).toFixed(2)
}

export const makeIdArray = vcs => {
  const ids = []
  if (vcs.length > 1) {
    vcs.forEach(vc => {
      ids.push(vc.value)
    })
  } else {
    ids.push(vcs[0].value)
  }
  return ids
}

export const formatDataSet = (data, interval) => {
  const graphData = []
  let lastBypass = null
  let bypassTime = 0
  const infferedData = [
    { timestamp: interval.start, value: data[0].value ? 'Bypass' : 'Normal' },
    ...data,
    { timestamp: interval.end, value: data.pop().value ? 'Bypass' : 'Normal' }
  ]

  infferedData.forEach((d, index) => {
    if (d.value) {
      lastBypass = index === 0 ? new Date(interval.start) : new Date(d.timestamp)
    } else if (lastBypass) {
      bypassTime += new Date(d.timestamp) - lastBypass
      lastBypass = null
    }
    graphData.push({ x: new Date(d.timestamp), y: d.value ? 'Bypass' : 'Normal' })
  })

  return data.length
    ? {
        data: graphData,
        time: Number(bypassTime / (1000 * 60 * 60)).toFixed(2)
      }
    : { data: [], bypassTime: 0 }
}

export const returnTranslatedMode = mode => {
  switch (mode) {
    case 'kW':
      return 'vc.pwr'
    case 'A':
      return 'vc.cur'
    case 'V':
      return 'vc.ten'
    default:
      return mode
  }
}

export const kStr = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
}
export const isVCSOnline = time => {
  return Number((Date.now() - new Date(time).getTime()) / 1000 / 60).toFixed(0) < 20
}

export const parseHmm = rawNumber => {
  const number = Number(rawNumber).toFixed(0)
  return `${number.slice(0, -2)}h ${number.slice(-2)}m`
}

export const returnAltLabel = (vcs, vc) => {
  const altLabel = vcs.find(e => parseInt(e.value) === vc.vc_id).label
  return altLabel ? altLabel : ''
}
